import React, { useState } from 'react'
import ContactDetails from './Utility/ContactDetails'
import {url} from '../Utility/Utility.js'
const Contact = () => {

    const [userDetails, setUserDetails] = useState({
        'name': '', 'email': '', 'phone': '', 'address': '', 'message': '', 'phone': ''
    })
    const [msg, setMsg] = useState('')
    const handleChange = (e) => {
        const { name, value } = e.target
        setUserDetails({
            ...userDetails,
            [name]: value
        })
    }

    const Add_Contact = (e) => {
        e.preventDefault()
        var formdata = new FormData();
        formdata.append("type", "contact_Add");
        formdata.append("name", userDetails.name);
        formdata.append("email", userDetails.email);
        formdata.append("phone", userDetails.phone);
        formdata.append("address", userDetails.address);
        formdata.append("message", userDetails.message);
        formdata.append("subject", userDetails.subject);
        formdata.append("created_at", new Date());

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        fetch(url + "/support.php", requestOptions)
            .then(response => response.json())
            .then(result => {
                setMsg(result.message)
                setUserDetails({
                    ...userDetails,
                    'name': '', 'email': '', 'phone': '', 'address': '', 'message': '', 'subject': ''
                })
            })
            .catch(error => console.log('error', error));
    }

  return (
    <>
            <section id="contact" className="contact mt-5">
                <div className="container" data-aos="fade-up">

                    <div className="section-title mt-3">
                        <h2>Contact Us</h2>
                    </div>

                    <div className="row mb-5" style={{ display: "flex", justifyContent: "center" }}>
                        <div className="col-lg-7 mt-5 mt-lg-0 d-flex align-items-center ">
                            <form onSubmit={(e)=>Add_Contact(e)} className="php-email-form" id='formField'>
                                <div className="row">
                                    <div className="form-group col-md-6">
                                        <label for="name">Your Name</label>
                                        <input type="text" name="name" value={userDetails.name} onChange={handleChange} className="form-control" id="name" required />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label for="name">Your Email</label>
                                        <input type="email" className="form-control" value={userDetails.email} onChange={handleChange} name="email" id="email" />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="form-group col-md-6">
                                        <label for="name">Mobile Number</label>
                                        <input type="text" name="phone" value={userDetails.phone} onChange={handleChange} className="form-control" id="name" required />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label for="name">Address</label>
                                        <input type="text" className="form-control" value={userDetails.address} onChange={handleChange} name="address" id="email" required />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label for="name">Subject</label>
                                    <input type="text" className="form-control" value={userDetails.subject} onChange={handleChange} name="subject" id="subject" />
                                </div>
                                <div className="form-group">
                                    <label for="name">Message</label>
                                    <textarea className="form-control" value={userDetails.message} onChange={handleChange} name="message" rows="10" required></textarea>
                                </div>
                                {
                                    msg !== '' ?
                                    <div className='text-center text-success font-weight-bold'>
                                        <h6>{msg}</h6>
                                    </div>
                                    : ''
                                }
                                <div className="text-center"><button type="submit">Send Message</button></div>
                            </form>
                        </div>

                    </div>

                    <ContactDetails />

                </div>
            </section>
    </>
  )
}

export default Contact