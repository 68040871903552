import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { getAllCookiesData, toastifyError, toastifySuccess, url } from '../../../Utility/Utility'
import Select from 'react-select';
import MessageList from './MessageList';
const MessageToRetailer = ({ path }) => {
    const [retailerList, setRetailerList] = useState([])
    const [page, setPage] = useState(false)
    const [loading, setLoading] = useState(false)
    const [errMsg, setErrMsg] = useState('')
    const [msgVal, setMsgVal] = useState({
        'msg': '',
        'msg_from': '',
        'msg_to': '',
        'msg_to_text': '',
        'msg_date': ''
    })

    useEffect(() => {
        getList()
    }, [])


    const getList = async () => {
        try {
            const userId = getAllCookiesData()?.id
            var formdata = new FormData();
            formdata.append("role", 5);
            formdata.append("assign_id", userId === '107' ? 1 : userId);
            const res = await axios.post(url + '/user/getUser.php', formdata);
            if (res?.data?.status) {
                setRetailerList(res?.data?.data)
            }
        } catch (error) { console.log(error) }
    }

    const reset = () => {
        setMsgVal({
            ...msgVal,
            'msg': '',
            'msg_from': '',
            'msg_to': '',
            'msg_to_text': '',
            'msg_date': ''
        })
        setSelected({
            optionSelected: null
        })
    }

    const changeArr = (retailerList) => {
        const result = retailerList?.map((sponsor) =>
            ({ value: sponsor.id, label: sponsor.name + " || " + sponsor.company_name })
        )
        return result
    }

    const [Selected, setSelected] = useState({
        optionSelected: null
    })

    const handleChange = (e) => {
        setSelected({
            optionSelected: e
        });
        var id = []
        var name = []
        e.map((item, i) => {
            id.push(item.value);
            name.push(item.label)
        })
        setMsgVal({
            ...msgVal,
            ['msg_to']: id.toString(), ['msg_to_text']: name.toString(), 
        })
    }

    const sendMessage = async () => {
        setLoading(true)
        try {
            var formdata = new FormData();
            formdata.append("type", "add-message");
            formdata.append("msg", msgVal.msg);
            formdata.append("msg_from", getAllCookiesData()?.id);
            formdata.append("msg_to", msgVal.msg_to);
            formdata.append("msg_to_text", msgVal.msg_to_text);
            formdata.append("msg_date", new Date());
            const res = await axios.post(url + "/user/message.php", formdata);
            if (res.data.status) {
                toastifySuccess(res.data.message)
                setErrMsg(res.data.message)
                reset()
                setLoading(false)
            } else { toastifyError(res.data.message); setErrMsg(res.data.message); setLoading(false) }
        } catch (error) { console.log(error) }
    }

    return (
        <>
            <div class="card">
                <div class="card-body">
                    {
                        !page ?
                            <>
                                <div class="row">
                                    <div className="col-12 d-flex" style={{ justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #ddd' }}>
                                        <h4 class="card-title">{path.substring(1)}</h4>
                                        <h4><button className='btn btn-sm btn-primary' onClick={() => setPage(true)}>Recent Message</button></h4>
                                    </div>
                                    <div className="col-12">
                                        <div className="row mt-3">
                                            <div className="col-12 col-sm-12 mt-2">
                                                <Select
                                                    isClearable
                                                    closeMenuOnSelect={false}
                                                    isMulti
                                                    options={changeArr(retailerList)}
                                                    onChange={handleChange}
                                                    value={Selected.optionSelected}
                                                />
                                            </div>
                                            <div className="col-12 mt-3">
                                                <textarea
                                                    value={msgVal.msg}
                                                    cols="30"
                                                    rows="10"
                                                    onChange={(e) => setMsgVal({ ...msgVal, "msg": e.target.value })}
                                                    placeholder='Write Your Message Here ...'
                                                    className='form-control'></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 pt-3">
                                        {
                                            errMsg ?
                                                <div className={`alert ${errMsg === "Message Send Succesfully !!" ? "alert-success" : "alert-danger"} text-center`} role="alert">
                                                    <span className='font-weight-bold'>{errMsg}</span>
                                                </div>
                                                :
                                                <></>
                                        }
                                        {
                                            loading ?
                                                <div className="d-flex justify-content-center">
                                                    <div className="spinner-border text-success" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                </div>
                                                :
                                                <></>
                                        }
                                        <div className="row py-3 px-0 mt-3 mx-0 justify-content-center" style={{ background: '#F5F7FF' }}>
                                            <div className="col-lg-2 col-md-2 col-sm-3 col-4">
                                                <input type="submit" value={'SEND'} onClick={sendMessage} className='form-control form-control-sm bg-primary text-white' />
                                            </div>
                                            <div className="col-lg-2 col-md-2 col-sm-3 col-4">
                                                <input type="button" value='RESET' onClick={()=> {reset(); setErrMsg('')}} className='form-control form-control-sm bg-warning text-white' />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </>
                            :
                            <>
                                <MessageList {...{ setPage }} />
                            </>
                    }


                </div>
            </div>




        </>
    )
}

export default MessageToRetailer

