import axios from 'axios';
import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import { Link, useNavigate } from 'react-router-dom';
import { currentDateMonthYear, currentMonthYear, DateMonthYear, getDateTime, toastifyError, toastifySuccess, url } from '../../Utility/Utility'
import ConfirmModal from '../Utility/ConfirmModal';
import { timeAgo } from '../Utility/Date';

const DeleteUserList = ({ path }) => {
    const [data, setData] = useState([])
    const [filter, setFilterData] = useState([]);
    const [id, setId] = useState()
    const [deleteModal, setDeleteModal] = useState(false)
    const [permanentDeleteModal, setPermanentDeleteModal] = useState(false)
    useEffect(() => {
        getList();
    }, [])

    const getList = async () => {
        try {
            const res = await axios.get(url + "/user/delete-user-list.php")
            if (res?.data?.status) {
                setData(res?.data?.data)
                setFilterData(res?.data?.data)
            } else {
                setData()
            }
        } catch (error) {
            console.log(error)
        }
    }


    const activeUser = async () => {
        var FormData = require('form-data');
        var data = new FormData();
        data.append('id', id);
        data.append('is_active', 1);
        data.append('deleted_at', null);
        try {
            const res = await axios.post(url + "/user/updateUser.php", data)
            if (res?.data?.status) {
                getList()
                setDeleteModal(false)
                toastifySuccess("User Activated Successfully !!")
            }
        } catch (error) {
            toastifyError("Something Wen't Wrong !!")
        }
    }

    const deleteUserPermanent = async () => {
        var FormData = require('form-data');
        var data = new FormData();
        data.append('id', id);
        try {
            const res = await axios.post(url + "/user/user-delete-permanent.php", data)
            if (res?.data?.status) {
                getList()
                toastifySuccess("User Deleted Successfully !!")
                setPermanentDeleteModal(false)
            }
        } catch (error) {
            toastifyError("Something Wen't Wrong !!")
        }
    }

    const columns = [
        {
            name: 'S.No',
            selector: (row, index) => index + 1,
            sortable: true
        },
        // {
        //     name: 'Assignee Role',
        //     selector: (row) => <>{row.assigneeRole === "2" ? <span class="badge py-1" style={{ background: "#93BE52" }}>National Distributor</span> : row.assigneeRole === "3" ? <span class="badge py-1" style={{ background: "#FFB64D" }}>Super Distributor</span> : row.assigneeRole === "4" ? <span class="badge py-1" style={{ background: "#ab7967" }}>Distributor</span> : row.assigneeRole === "5" ? <span class="badge py-1" style={{ background: "#39ADB5" }}>Retailer</span> : ''}</>
        // },
        {
            name: 'Assignee',
            selector: (row) => row.assignIn
        },
        {
            name: 'Username',
            selector: (row) => row.username
        },
        {
            name: 'Company Name',
            selector: (row) => row.company_name
        },
        {
            name: 'Email',
            selector: (row) => row.email
        },
        {
            name: 'Mobile',
            selector: (row) => row.mobile,
            sortable: true
        },
        {
            name: 'Created At',
            selector: (row) => getDateTime(row.created_at),
            sortable: true
        },
        {
            name: 'Deleted At',
            selector: (row) => timeAgo(new Date(row.deleted_at)),
            sortable: true
        },
        {
            name: 'role',
            selector: (row) => <>{row.role === "2" ? <span class="badge py-1" style={{ background: "#93BE52" }}>National Distributor</span> : row.role === "3" ? <span class="badge py-1" style={{ background: "#FFB64D" }}>Super Distributor</span> : row.role === "4" ? <span class="badge py-1" style={{ background: "#ab7967" }}>Distributor</span> : row.role === "5" ? <span class="badge py-1" style={{ background: "#39ADB5" }}>Retailer</span> : ''}</>,
            sortable: true
        },
        {
            name: <p className='text-end'>Action</p>,
            cell: row => <>
                <Link to={'#'} onClick={() => { setDeleteModal(true); setId(row.id) }} className="btn btn-sm btn-primary text-white ml-2 p-2 mr-2" >Active Now</Link>
                <Link to={'#'} onClick={() => { setPermanentDeleteModal(true); setId(row.id) }} className="btn btn-sm btn-danger text-white ml-2 p-2 mr-2" ><i className='fa fa-trash'></i></Link>
                </>


        }
    ]

    const [searchVal, setSearchVal] = useState({
        'serachText': '',
        'dateRange': '',
        'date_from': '',
        'date_to': '',
    })

    const handleSearch = e => {
        if (searchVal.serachText !== '') {
            const result = data.filter((item) => {
                return item.username !== "" ? item.username.toLowerCase().includes(searchVal.serachText.toLowerCase()) : "" || item.company_name !== "" ? item.company_name.toLowerCase().includes(searchVal.serachText.toLowerCase()) : "" || item.assignIn !== "" ? item.assignIn.toLowerCase().includes(searchVal.serachText.toLowerCase()) : "";
            });
            setFilterData(result)
        } else if (searchVal.dateRange === 'today') {
            const result = data.filter((item) => {
                return DateMonthYear(item.created_at).toLowerCase().includes(currentDateMonthYear().toLowerCase())
            });
            setFilterData(result)
        } else if (searchVal.dateRange === 'currentMonth') {
            const result = data.filter((item) => {
                return DateMonthYear(item.created_at).toLowerCase().includes(currentMonthYear().toLowerCase())
            });
            setFilterData(result)
        } else if (searchVal.date_from !== '' && searchVal.date_to !== '') {
            const result = data.filter((item) => {
                return DateMonthYear(item.created_at).toLowerCase() >= DateMonthYear(searchVal.date_from).toLowerCase() && DateMonthYear(item.created_at).toLowerCase() <= DateMonthYear(searchVal.date_to).toLowerCase()
            });
            setFilterData(result)
        } else {
            setFilterData(data)
        }
    }
    const resetSearch = () => {
        setFilterData(data)
        setSearchVal({
            ...searchVal,
            'serachText': '',
            'dateRange': '',
            'date_from': '',
            'date_to': '',
        })
    }

    return (
        <>
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">{path.substring(1)}</h4>
                    <div class="row">
                        <div className="col-12 pt-3" style={{ borderTop: '1px solid #ddd' }}>
                            <div className="row">
                                <div className="col-12 col-sm-6 col-lg-6 mt-3">
                                    <input type="text" value={searchVal.serachText} onChange={(e) => setSearchVal({ ...searchVal, 'serachText': e.target.value })} className='form-control form-control-sm' placeholder='Search by assign in or username or company name' />
                                </div>

                                {
                                    searchVal.dateRange === 'customeRange' ?
                                        <>
                                            <div className="col-12 col-sm-6 col-lg-2">
                                                <span style={{ fontSize: '13px' }} >From Date</span>
                                                <input type="date" className='form-control form-control-sm' onChange={(e) => setSearchVal({ ...searchVal, "date_from": e.target.value })} />
                                            </div>

                                            <div className="col-12 col-sm-6 col-lg-2">
                                                <span style={{ fontSize: '13px' }} > To Date</span>
                                                <input type="date" className='form-control form-control-sm' onChange={(e) => setSearchVal({ ...searchVal, "date_to": e.target.value })} />
                                            </div>
                                        </>
                                        :
                                        <></>
                                }
                            </div>
                            <div className="row py-3 px-0 mt-3 mx-0 justify-content-center" style={{ background: '#F5F7FF' }}>
                                <div className="col-lg-2 col-md-2 col-sm-3 col-4">
                                    <input type="button" value={'Search'} onClick={(e) => handleSearch()} className='form-control form-control-sm bg-primary text-white' />
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-3 col-4">
                                    <input type="button" value='reset' onClick={() => { resetSearch() }} className='form-control form-control-sm bg-warning text-white' />
                                </div>
                               
                            </div>
                        </div>
                        <div className="col-12">
                            <DataTable
                                dense
                                columns={columns}
                                data={filter}
                                pagination
                                highlightOnHover
                            />
                        </div>
                    </div>
                </div>
            </div>
            {
                deleteModal ?
                    <ConfirmModal func={activeUser} message="Are you sure ? you want to active this user?" setDeleteModal={setDeleteModal} />
                    :
                    <></>
            }
            {
                permanentDeleteModal ?
                    <ConfirmModal func={deleteUserPermanent} message="Are you sure ? Do you really want to delete this user ? This process can`t be undone?" setDeleteModal={setPermanentDeleteModal} />
                    :
                    <></>
            }
        </>
    )
}

export default DeleteUserList