import React, {createContext, useState } from 'react'
import { url } from '../Utility/Utility'
import axios from 'axios'

export const CommonContextData = createContext()
const CommonContext = ({ children }) => {
    const [utilityObj, setUtilityObj] = useState({})
    const getDataUtility = async () => {
        try {
            const res = await axios.post(url + "/utility.php", { "type": "get-data-utility" })
            if (res.data.status) setUtilityObj(res?.data?.data); else setUtilityObj()
        } catch (error) { console.log(error) }
    }
    return (
        <CommonContextData.Provider value={{getDataUtility,utilityObj}}>
            {children}
        </CommonContextData.Provider>
    )
}

export default CommonContext