import React from 'react'
import Add from '../Common/Main/Add'
import List from '../Common/Main/List'

const Retailer = ({ path }) => {
    return (
        <>
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">{path.substring(1)}</h4>
                    <div class="row">
                        {path === '/retailer' ? <List role="5" {...{path}}/> : <Add role="5" assignee="4" redirect="/retailer"/>}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Retailer