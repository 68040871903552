import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { toastifyError, toastifySuccess, url } from '../../../Utility/Utility'
import { Link, NavLink } from 'react-router-dom'
import Loader from '../../Utility/Loader'
import { timeAgo } from '../../Utility/Date'
import { CommonContextData } from '../../../Context/CommonContext'

const UtilityPage = ({ path }) => {
    const { getDataUtility, utilityObj } = useContext(CommonContextData);
    const [loading, setLoading] = useState(false)
    const [values, setValues] = useState({
        "type": "update-social-media-url",
        "title": "",
        "url": "",
        "qrcode": ""
    })
    useEffect(() => {
        getDataUtility()
    }, [])

    const handleChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value })
        if (e.target.name == "qrcode") {
            setValues({ ...values, "qrcode": e.target.files[0] })
        }
    }
    const handleUpload = async () => {
        try {
            if (values.title == "") {
                toastifyError("Select Social Media !!")
                return false
            }
            if (values.url == "") {
                toastifyError("Select Social Media URL !!")
                return false
            }
            const res = await axios.post(url + "/utility.php", values)
            if (res?.data?.status) {
                toastifySuccess(res.data.message)
                getDataUtility()
                setValues({ ...values, "title": "", "url": "", })
            } else toastifyError(res.data.message)
        } catch (error) {
            console.log(error)
        }
    }

    const uploadQRCode = async () => {
        if (values.qrcode == '') {
            toastifyError("Please Select QR Code !!")
            return false
        }
        setLoading(true)
        var formdata = new FormData();
        formdata.append("type", 'update-qrcode');
        formdata.append("qrcode", values.qrcode);
        formdata.append("date", new Date());
        const res = await axios.post(url + "/user/message.php", formdata)
        if (res?.data?.status) {
            toastifySuccess(res?.data?.message)
            getDataUtility()
            setLoading(false)
            setValues({ ...values, "qrcode": "" })
        }
    }

    const deletedLink = async (title) => {
        const value = {
            "type" : "remove-social-media",
            "title": title
        }
        const res = await axios.post(url + "/utility.php", value)
        if (res?.data?.status) {
            getDataUtility()
        }
    }

    return (
        <>
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">{path.substring(1)}</h4>
                    <div className="row mt-3 pt-3" style={{ borderTop: '1px solid #ccc' }}>
                        <div className="col-md-12 col-12">
                            <div className="row">
                                <div className="col-12">
                                    <ul class="nav nav-pills mb-3" style={{ border: 'none' }} id="pills-tab" role="tablist">
                                        <li class="nav-item">
                                            <a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#SocialMediaURL" role="tab" aria-controls="SocialMediaURL" aria-selected="true">Socali Media</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">QR Code</a>
                                        </li>
                                    </ul>

                                    <div class="tab-content mt-0 pt-0" id="pills-tabContent">

                                        <div class="tab-pane fade show active" id="SocialMediaURL" role="tabpanel" aria-labelledby="SocialMediaURL-tab">

                                            <div className="row pt-2 pb-3 px-0 mx-0" style={{ background: '#F5F7FF' }}>
                                                <div className="col-12 col-md-4 mt-2">
                                                    <select name='title' value={values.title} onChange={handleChange} className='form-control'>
                                                        <option value="">Select Social Media Type</option>
                                                        <option value="facebook">Facebook</option>
                                                        <option value="instagram">Instagram</option>
                                                        <option value="twitter">Twitter</option>
                                                        <option value="youtube">Youtube</option>
                                                        <option value="website">Website</option>
                                                        <option value="watsapp">Watsapp</option>
                                                    </select>
                                                </div>
                                                <div className="col-12 col-md-4 mt-2">
                                                    <input type="url" value={values.url} name='url' onChange={handleChange} className='form-control form-control-sm' placeholder='URL OR Number' />
                                                </div>
                                                <div className="col-12 col-md-4 mt-2">
                                                    <input type="button" onClick={handleUpload} value={'Upload'} className='form-control form-control-sm bg-primary text-white' />
                                                </div>
                                            </div>

                                            <div className="row mt-3">
                                                {
                                                    utilityObj?.facebook ?
                                                        <div className="col-12 col-md-4">
                                                            <p className='py-1 px-2' style={{ background: '#d4a276' }}> <i className='fab fa-facebook'></i> {utilityObj?.facebook.substring(0, 45)} ...
                                                                <div style={{ float: "right" }}>
                                                                    <Link to={utilityObj?.facebook} target='_blank' >
                                                                        <i class="fa fa-arrow-right" aria-hidden="true"></i>
                                                                    </Link>
                                                                    <i class="fa fa-times ml-3 text-danger" aria-hidden="true" onClick={() => deletedLink("facebook")}></i>
                                                                </div>
                                                            </p>
                                                        </div>
                                                        :
                                                        <></>
                                                }
                                                {
                                                    utilityObj?.instagram ?
                                                        <div className="col-12 col-md-4">
                                                            <p className='py-1 px-2' style={{ background: '#d4a276' }}> <i className='fab fa-instagram'></i> {utilityObj?.instagram.substring(0, 45)} ...
                                                                <div style={{ float: "right" }}>
                                                                    <Link to={utilityObj?.instagram} target='_blank' >
                                                                        <i class="fa fa-arrow-right" aria-hidden="true"></i>
                                                                    </Link>
                                                                    <i class="fa fa-times ml-3 text-danger" aria-hidden="true" onClick={() => deletedLink("instagram")}></i>
                                                                </div>
                                                            </p>
                                                        </div>
                                                        :
                                                        <></>
                                                }

                                                {
                                                    utilityObj?.twitter ?
                                                        <div className="col-12 col-md-4">
                                                            <p className='py-1 px-2' style={{ background: '#d4a276' }}> <i className='fab fa-twitter'></i> {utilityObj?.twitter.substring(0, 45)} ...
                                                                <div style={{ float: "right" }}>
                                                                    <Link to={utilityObj?.twitter} target='_blank' >
                                                                        <i class="fa fa-arrow-right" aria-hidden="true"></i>
                                                                    </Link>
                                                                    <i class="fa fa-times ml-3 text-danger" aria-hidden="true" onClick={() => deletedLink("twitter")}></i>
                                                                </div>
                                                            </p>
                                                        </div>
                                                        :
                                                        <></>
                                                }
                                                {
                                                    utilityObj?.watsapp ?
                                                        <div className="col-12 col-md-4">
                                                           <p className='py-1 px-2' style={{ background: '#d4a276' }}> <i className='fab fa-whatsapp'></i> {utilityObj?.watsapp.substring(0, 45)} ...
                                                                <div style={{ float: "right" }}>
                                                                    <Link to={utilityObj?.watsapp} target='_blank' >
                                                                        <i class="fa fa-arrow-right" aria-hidden="true"></i>
                                                                    </Link>
                                                                    <i class="fa fa-times ml-3 text-danger" aria-hidden="true" onClick={() => deletedLink("watsapp")}></i>
                                                                </div>
                                                            </p>
                                                        </div>
                                                        :
                                                        <></>
                                                }
                                                {
                                                    utilityObj?.website ?
                                                        <div className="col-12 col-md-4">
                                                             <p className='py-1 px-2' style={{ background: '#d4a276' }}> <i className='fa fa-globe'></i> {utilityObj?.website.substring(0, 45)} ...
                                                                <div style={{ float: "right" }}>
                                                                    <Link to={utilityObj?.website} target='_blank' >
                                                                        <i class="fa fa-arrow-right" aria-hidden="true"></i>
                                                                    </Link>
                                                                    <i class="fa fa-times ml-3 text-danger" aria-hidden="true" onClick={() => deletedLink("website")}></i>
                                                                </div>
                                                            </p>
                                                        </div>
                                                        :
                                                        <></>
                                                }
                                                {
                                                    utilityObj?.youtube ?
                                                        <div className="col-12 col-md-4">
                                                            <p className='py-1 px-2' style={{ background: '#d4a276' }}> <i className='fab fa-youtube'></i> {utilityObj?.youtube.substring(0, 45)} ...
                                                                <div style={{ float: "right" }}>
                                                                    <Link to={utilityObj?.youtube} target='_blank' >
                                                                        <i class="fa fa-arrow-right" aria-hidden="true"></i>
                                                                    </Link>
                                                                    <i class="fa fa-times ml-3 text-danger" aria-hidden="true" onClick={() => deletedLink("youtube")}></i>
                                                                </div>
                                                            </p>
                                                        </div>
                                                        :
                                                        <></>
                                                }
                                            </div>


                                        </div>

                                        <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                            <div className="row py-3 px-0 mx-0" style={{ background: '#F5F7FF' }}>
                                                <div className="col-12 col-md-4">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <p><strong>Note</strong>  :File name must be qrcode.jpg</p>
                                                        </div>
                                                        <div className="col-12 mt-2">
                                                            <input type="file" name='qrcode' onChange={handleChange} className='form-control form-control-sm' placeholder='URL OR Number' />
                                                        </div>
                                                        <div className="col-12 mt-4">
                                                            <input type="button" value={'Upload'} onClick={uploadQRCode} className='form-control form-control-sm bg-primary text-white' />
                                                        </div>
                                                        <div className="col-12 mt-4 text-center">
                                                            <Link to={`https://api.moonfox.in/img/${utilityObj.qrcode}`} target='_blank'> https://api.moonfox.in/img/{utilityObj.qrcode} </Link>
                                                        </div>
                                                        <div className="col-12 mt-3 text-center">
                                                            <p>{"Last Update : " + timeAgo(utilityObj.last_update_qrcode)}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-4">
                                                    {
                                                        loading ?
                                                            <>
                                                                <Loader />
                                                            </>
                                                            :
                                                            <>
                                                                <div className="qr-img-box">
                                                                    <img src={`https://api.moonfox.in/img/${utilityObj.qrcode}`} className='img-fluid' alt="" />
                                                                </div>
                                                            </>
                                                    }
                                                </div>
                                                <div className="col-12 col-md-4">
                                                    <div className="qr-img-box text-center">
                                                        <img src={`https://api.moonfox.in/img/qrcode.jpg`} className='img-fluid' alt="" />
                                                        <Link to={`https://api.moonfox.in/img/qrcode.jpg`} target='_blank'> https://api.moonfox.in/img/qrcode.jpg </Link>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default UtilityPage