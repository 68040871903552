import React from 'react'
import { useEffect } from 'react'
import { DecryptedID, url } from '../../Utility/Utility'
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { useState } from 'react';
import { timeAgo } from '../Utility/Date';
import Loader from '../Utility/Loader';

const ContactMesage = ({ path }) => {
    const useQuery = () => new URLSearchParams(useLocation().search);
    let id = useQuery().get('id');
    const [singalContact, setSingalContact] = useState({})
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (id !== null) getData(DecryptedID(id))
    }, [id])
    const getData = async (id) => {
        try {
            var formdata = new FormData();
            formdata.append("type", "view-singal-contact");
            formdata.append("id", id);
            const res = await axios.post(url + "/support.php", formdata);
            if (res.data.status) { setSingalContact(res.data.data) }
            setLoading(false)
        } catch (error) { console.log(error); setLoading(false) }
    }
    return (
        <>
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">{path.substring(1)}</h4>
                    {
                        loading ?
                            <Loader />
                            :
                            <>
                                <div className="row mt-3 pt-3 px-2" style={{ borderTop: '1px solid #ccc' }}>
                                    <div className="col-12 py-2" style={{ background: '#ddd' }}>
                                        <span className='text-capitalize font-weight-bold'>message date : </span> <span>{timeAgo(singalContact.created_at)}</span>
                                    </div>
                                    <div className="col-12 py-2 mt-2" style={{ background: '#ddd' }}>
                                        <span className='text-capitalize font-weight-bold'>message subject : </span> <span> {singalContact.message}</span>
                                    </div>
                                    <div className="col-12 mt-2 mb-2 py-2" style={{ background: '#ddd' }}>
                                        <span className='text-capitalize font-weight-bold'>message : </span> <span> {singalContact.message}</span>
                                    </div>
                                    <div className="col-12 col-lg-4 py-2" style={{ background: '#ddd' }}>
                                        <span className='text-capitalize font-weight-bold'>name : </span> <span> {singalContact.name}</span>
                                    </div>
                                    <div className="col-12 col-lg-4 py-2 text-center" >
                                        <span className='text-capitalize font-weight-bold'>phone : </span> <span> {singalContact.phone}</span>
                                    </div>
                                    <div className="col-12 col-lg-4 py-2" style={{ background: '#ddd' }}>
                                        <span className='text-capitalize font-weight-bold'>email : </span> <span>{singalContact.email}</span>
                                    </div>
                                </div>
                            </>
                    }
                </div>
            </div>
        </>
    )
}

export default ContactMesage