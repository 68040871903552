import React, { useState, useEffect } from 'react'
import { Routes, Route } from 'react-router-dom';
import Cookies from 'js-cookie';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MoonFoxHome from './components/front/MoonFoxHome';
import Front from './Front';
import Policy from './components/front/Policy';
import Contact from './components/front/Contact';
import Auth from './Auth';
import Dashboard from './components/DashboardPannel/Pages/Dashboard';
import Login from './components/DashboardPannel/Pages/Login';
import Admin from './components/DashboardPannel/Pages/Admin';
import NationalDis from './components/DashboardPannel/Pages/NationalDis';
import SuperDis from './components/DashboardPannel/Pages/SuperDis';
import Distributor from './components/DashboardPannel/Pages/Distributor';
import Retailer from './components/DashboardPannel/Pages/Retailer';
import History from './components/DashboardPannel/Pages/History';
import SalesList from './components/DashboardPannel/Pages/Sales/SalesList';
import DeviceList from './components/DashboardPannel/Pages/DeviceList';
import ChangePassword from './components/DashboardPannel/Pages/ChangePassword';
import DeleteUserList from './components/DashboardPannel/Pages/DeleteUserList';
import WiP from './components/DashboardPannel/Utility/WiP';
import Profile from './components/DashboardPannel/Pages/Profile';
import DeleteDeviceList from './components/DashboardPannel/Pages/DeleteDeviceList';
import MessageToRetailer from './components/DashboardPannel/Pages/Message/MessageToRetailer';
import ContactMesage from './components/DashboardPannel/Pages/ContactMesage';
import OurApp from './components/front/OurApp';
import UtilityPage from './components/DashboardPannel/Pages/UtilityPage/UtilityPage';
function App() {
  const [role, setRole] = useState('')
  useEffect(() => {
    if (Cookies.get('role')) {
      setRole(Cookies.get('role'))
    }
  }, [])

  return (
    <>
      <Routes>
        {/* ----Front Route---- */}
        <Route path='/' element={<Front cmp = {MoonFoxHome} />} />
        <Route path='/contact' element={<Front cmp = {Contact} />} />
        <Route path='/policy' element={<Front cmp = {Policy} />} />
        <Route path='/our-app' element={<Front cmp = {OurApp} />} />
        {/* <Route path='/auth' componets={<Auth/>} /> */}

        {/* ---Protected Route--- */}
        <Route path='/login' element={<Auth cmp = {Login} path={'/login'}/>} />
        <Route exact path='/dashboard' element={<Auth cmp={Dashboard} role={role} path={'/dashboard'}/>} />
        <Route exact path='/admin' element={<Auth cmp={Admin} role={role} path={'/admin'}/>} />
        <Route exact path='/national-distributor' element={<Auth cmp={NationalDis} role={role} path={'/national-distributor'}/>} />
        <Route exact path='/add-national-distributor/:id' element={<Auth cmp={NationalDis} role={role} path={'/add-national-distributor'}/>} />
        <Route exact path='/super-distributor' element={<Auth cmp={SuperDis} role={role} path={'/super-distributor'}/>} />
        <Route exact path='/add-super-distributor/:id' element={<Auth cmp={SuperDis} role={role} path={'/add-super-distributor'}/>} />
        <Route exact path='/distributor' element={<Auth cmp={Distributor} role={role} path={'/distributor'}/>} />
        <Route exact path='/add-distributor/:id' element={<Auth cmp={Distributor} role={role} path={'/add-distributor'}/>} />
        <Route exact path='/retailer' element={<Auth cmp={Retailer} role={role} path={'/retailer'}/>} />
        <Route exact path='/add-retailer/:id' element={<Auth cmp={Retailer} role={role} path={'/add-retailer'}/>} />
        <Route exact path='/history/:id/:role' element={<Auth cmp={History} role={role} path={'/history'}/>} />
        <Route exact path='/device' element={<Auth cmp={DeviceList} role={role} path={'/device'}/>} />
        <Route exact path='/sales' element={<Auth cmp={SalesList} role={role} path={'/sales'}/>} />
        <Route exact path='/add-sales' element={<Auth cmp={WiP} role={role} path={'/add-sales'}/>} />
        <Route exact path='/change-password' element={<Auth cmp={ChangePassword} role={role} path={'/change-password'}/>} />
        <Route exact path='/my-profile' element={<Auth cmp={Profile} role={role} path={'/my profile'}/>} />
        <Route exact path='/deleted-user-list' element={<Auth cmp={DeleteUserList} role={role} path={'/deleted-user-list'}/>} />
        <Route exact path='/deleted-device-list' element={<Auth cmp={DeleteDeviceList} role={role} path={'/deleted-device-list'}/>} />
        <Route exact path='/message-to-retailer' element={<Auth cmp={MessageToRetailer} role={role} path={'/message-to-retailer'}/>} />
        <Route exact path='/contact-message' element={<Auth cmp={ContactMesage} role={role} path={'/contact-message'}/>} />
        <Route exact path='/utility' element={<Auth cmp={UtilityPage} role={role} path={'/utility'}/>} />
      </Routes>
      <ToastContainer />
    </>
  );
}

export default App;
