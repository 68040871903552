import React from 'react'
import { policyData } from './Utility/Utility'

const Policy = () => {
    return (
        <section class="sec-policy">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="sec-title text-center pt-3 pb-4">
                            <h3 className='mt-5' style={{ color: 'black' }}>
                                <b>PRIVACY POLICY </b>
                            </h3>
                            <p style={{ color: 'black', display: 'inline-block',borderBottom: '1px solid red', paddingBottom: '10px' }}>THE FOLLOWING DEFINITIONS SHALL APPLY FOR THE SCOPE OF THESE TERMS:</p>
                        </div>
                        < p >
                        Moonfox is dedicated to safeguarding your privacy. This Privacy Policy outlines how Moonfox collects, uses, information, and provides details on how you can manage your information preferences. It covers data practices associated with our website (www.moonfox.in) and Moonfox services ("Services"), including the choices available regarding the collection and use of your information.

                        </p >
                        <p>
                            <h4 className='text-dark'>
                                
                                This Privacy Policy applies to:

                            </h4>
                            <b>Website Publisher Customers ("Publishers"):</b> This section explains how Moonfox may collect, use,  information related to your company and the websites or apps that use Moonfox Services.<br />
<b>Website Merchant Customers ("Merchants"):</b> Similar to Publishers, this section details how Moonfox handles information related to your business and the websites or apps that incorporate Moonfox Services.<br />
<b>Individual Users:</b> This section describes how Moonfox collects, uses, and information when you visit our website or use any website or app that uses Moonfox Services.

<ul>
    <li>
        Publishers, Merchants, and other clients might also have their privacy policies, which may differ from Moonfox’s. We encourage you to review these policies to understand their data collection and usage practices and to learn about the choices they offer concerning your data.
    </li>
</ul>

                            <li>
                            Additionally, our Services may include links to third-party websites or content that are not under our control. We recommend reviewing the privacy policies of any third-party sites before sharing your information.

                            </li>
                                
                            <h4> Why do we Require the following PERMISSIONS in our App?</h4>
                            <li>
                            <b> INTERNET</b> - We use this permission solely to enable internet access within the app. No data is collected or shared. </li>
                            <ul><li>
                            <b>LOCATION</b> - This permission is necessary for the parent device to access the child’s location, ensuring their safety. Rest assured, we do not collect or share this location data with anyone. </li></ul>
                            <li>
                            <b>CAMERA</b> - We need this permission to take pictures using the rear camera, which may be used in emergencies. This data is never collected or shared.
                            </li>
                            <li>
                            <b>DISPLAY OVER OTHER APPS</b> - This permission allows the parent to control and manage the apps on their child’s device by overlaying important alerts or controls.
                            </li>
                            <ul><li>
                              <b>FOREGROUND SERVICE </b>- We require this permission to inform users that our service is running in the background, ensuring consistent functionality. No data is collected or shared.</li></ul>
                              <li>
                              <b>QUERY ALL PACKAGES </b>- We use this permission to detect whether a specific app is installed on the device. This information is not collected or shared. </li>
                              <ul><li>
                              <b>PACKAGE USAGE STATS</b> - This permission allows parents to view their child’s app usage data, helping them monitor screen time and app interactions. No data is collected or shared. </li></ul>
                              <ul><li>
                              <b>REQUEST IGNORE BATTERY OPTIMIZATIONS</b> - This permission ensures that our app’s features run smoothly without being interrupted by battery optimization settings, maintaining continuous and reliable service.</li></ul>


                            

                        </p>
                        <p>
                            <h4>
                                
                                Information Handling and Utilization

                            </h4>
                            <h4>1. Information Collection and Use
                            </h4>
                            Moonfox collects data through various methods, including log files, pixel tags, cookies, and similar technologies. The types of data we collect may include:
                            <br />
                            <li>
                            <b>Browser Information:</b> This includes data such as URLs, browser type, and click-through data.</li>
                          
                            
                           
                            <li>
                            <b>Device Information:</b> Details about the device, including screen dimensions, brand, and model.
                            </li>
                             <li>
                                <b>Activity Data:</b> Information about your activities on our website and Services.</li>

                           

                        </p>
                       
                      
                         <h4>The information we collect is used to:</h4>
                        <ul>
                        <li>Host, operate, maintain, and secure our Services</li>
                      
                        <li>
                        Improve our Services and ensure compliance with Moonfox’s policies and terms.

                        </li>
                        <p>We may also receive additional data from third parties, which we may combine with our own data to enhance our services.</p>
                        <p>
                            
                    
                            Moonfox does not collect personally identifiable information, and we do not engage in activities requiring parental notice or consent under the Children’s Online Privacy Protection Act (COPPA). If you believe that Moonfox has inadvertently collected information from a child under 13 that requires parental consent under COPPA, please contact us using the information below to request the deletion of such information.

                        </p>
                      
                        <p>
                            <h4>
                                
                                2. Information Sharing
                            </h4>
                            <p>We share information only in the following circumstances per this Privacy Policy:</p>


                        <li>
                            <b>With Your Consent: </b> We may  your contact and billing information if you explicitly authorize us to do so.
                        </li>
                        
                        <li>
                        <b>With Affiliates:</b> We may share information with our affiliates, which include entities that control, are controlled by, or are under common control with Moonfox.

                        </li>
                        <li>
                            <b>With Service Providers:</b> We may  information to third-party service providers ("Service Providers") that perform services on our behalf, such as advertising, analytics, data management, web hosting, and web development. We ensure that our Service Providers adhere to privacy and security practices that are consistent with ours. Service Providers are given only the information necessary to perform their services and are prohibited from using it for any purpose other than as specified by Moonfox.
                        </li>
                        <li>
                        <b>Corporate Transactions:</b> In the event of a merger, sale, transfer of assets (including in bankruptcy), or other significant corporate changes, we may  information as part of such transactions.

                        </li>
                        <li>
                        <b>Legal and Safety Reasons:</b> We may  information if we believe it is necessary to protect Moonfox or our users, enforce our terms, or comply with legal obligations. This includes responding to governmental requests, legal processes, or other legal requirements.

                        </li>
                        <li>
                        <b>Aggregate Information:</b> We may share aggregated and anonymized data, that does not personally identify individuals, to develop and improve our Services, and for other purposes described above.

                        </li>
                        <p>
                        Additionally, Moonfox may be required to information in response to lawful requests by public authorities, including for national security or law enforcement purposes.

                        </p>
                        </p></ul>
                        <p>
                            <h4>
                              
                                3. Interest-Based Advertising and Opting Out

                            </h4>
                            
                            Moonfox adheres to industry standards for interest-based advertising, including the Digital Advertising Alliance (DAA) Self-Regulatory Principles in the U.S., the European Digital Advertising Alliance (EDAA) Principles in the EU, and the IAB Europe Online Behavioral Advertising (OBA) Framework. <br />
                            Our Ad Exchange utilizes cookies, web beacons, pixels, tags, and similar technologies to enable targeted advertising. These technologies help publishers offer and advertisers display ads that are more relevant to you based on:

 


                        
                        <li><b>Location Data:</b> Information about your location.
                        </li>
                        <li>
                        <b>Web Viewing Data:</b> Data collected from your browsing activities across non-affiliated sites over time.

                        </li>
                        <li>
                        <b>Application Use Data:</b> Information about your usage of applications across non-affiliated apps over time.

                        </li>
                       <p>
                        This process is known as "interest-based advertising." It aims to deliver ads that match your interests based on the data collected.</p>

                      
                        <p>
                        <b>Third-Party Data Collection</b> Certain third parties may also collect data on the Moonfox website and combine it with information from other websites over time to serve interest-based ads.

                        </p>
                        <p>
                        <b>Opting Out</b> You have the option to opt out of interest-based advertising. You can manage your preferences or opt out by adjusting your browser settings or using industry tools such as the DAA's Consumer Choice Page or the EDAA’s Your Online Choices. For detailed instructions on how to manage your advertising preferences, please visit the relevant sites.

                        </p></p>
                        <p>
                            <h4>
                                
                                4. Opting Out of Cookie-Based Advertising
                            </h4>
                            If you wish to learn more about interest-based advertising or opt out of it through Moonfox’s Ad Exchange, you can do so based on your location:
                          
                           
                            <ul><li>
                                <b>European Union Residents:</b> You can opt out of interest-based advertising from companies participating in the European Digital Advertising Alliance (EDAA) by visiting www.youronlinechoices.com. </li></ul>
                            <ul><li><b>Other Users:</b> Residents outside the EU can opt out of interest-based advertising from companies participating in the Digital Advertising Alliance (DAA) by visiting www.aboutads.info/choices. 
                            </li></ul>
                            <ul><li>
                                <b>Important Note:</b> To ensure that your opt-out choice is effective, your browser must be set to accept third-party cookies, including the Moonfox opt-out cookie. Some browsers block third-party cookies by default, so you may need to adjust your browser settings to allow these cookies before opting out.</li></ul>





                        </p>
                        <p>
                            <h4>
                                
                                5. Opting Out of Non-Cookie-Based Services (Where Applicable)

                            </h4>
                            <ul><li>
                            To help identify your browser and possible connections between different browsers and devices, Moonfox or our partners may use local storage or browser cache. This method allows us to deliver interest-based advertising without relying on third-party cookies. However, we use local storage only for application processing and not for tracking purposes.


           
                        
                        <h4>To Opt Out of Local Storage and Browser Cache Use:</h4>
                        <li>
                        <b>Clear Local Storage and Cache:</b> Use your browser’s tools to clear local storage and browser cache regularly
                        </li>
                        <li>
                        <b>Enable "Do Not Track":</b> Turn on the "Do Not Track" setting in your browser. This setting instructs websites to avoid tracking your browsing activity.
                        </li></li></ul>
                        <p>By following these steps, Moonfox will not use local storage or browser cache on your browser to identify or sync different browsers and devices.
                        </p><p>
                        <b>Opting Out of Third-Party Cookies:</b> If you also wish to opt out of interest-based advertising through third-party cookies used by Moonfox’s Ad Exchange, please refer to the instructions provided above.

                        </p></p>
                        <p>
                            <h4>
                            6. Opting Out of Mobile Application Data Collection

                            </h4>
                          
                            To opt out of Moonfox’s collection, use, and transfer of data for interest-based advertising on mobile apps, you can take the following actions:

                        <ul><li>
                            <b>For Users in the United States:</b> Download the DAA’s AppChoices application from the Android or iOS app store on your mobile device. This app allows you to manage your advertising preferences and opt out of interest-based advertising.
                        </li>
                        <li>
                            <b>For Users Outside the United States:</b> If the AppChoices app is not available in your region, you can use the built-in settings on your mobile device to limit interest-based advertising:
                        </li>
                        <li><b>iOS Users:</b> Go to "Settings"  "Privacy"  "Advertising" and enable "Limit Ad Tracking."
                        </li>
                        <li><b>Android Users:</b> Go to "Settings"  "Google"  "Ads" and enable "Opt out of interest-based ads."</li></ul>
                        <p>By following these steps, you can manage and limit the collection of data for interest-based advertising by Moonfox.
                        </p>
                        </p>
                        <p>
                            <h4>
                              
                                7. Opting Out of Location Data Collection

                            </h4>
                         
                            You can opt out of the collection, use, and sharing of your precise location data by adjusting the location services settings on your mobile device. To manage these settings:
                            <ul><li>
                            Go to your device’s <b>Settings.</b>

                            </li>
                            <li>
                            Select <b>Location</b> or <b>Location Services.</b>

                            </li>
                            <li>
                            Choose the relevant app and adjust its location permissions as needed.

                            </li></ul>
                            <p>
                            For more information on managing location settings, please refer to your device’s support documentation.

                            </p>
                            <h4>
                            8. Effect of Opting Out
                            </h4>
                            <p>
                            If you opt out of our data collection practices, please note the following:

                            </p>
                            <ul><li>
                            <b>Device and Browser Changes:</b> If you switch to a different device or browser, or clear your cookies, you will need to renew your opt-out choice.

                            </li>
                            <li>
                            <b>Advertising:</b> Even if you opt out, you may still receive interest-based advertising from other companies. Third-party advertisers and ad networks participating in the Moonfox Ad Exchange may use their cookies and technologies for ad display and tracking. We do not control or take responsibility for the practices of these third parties. For details on their data practices, please review their privacy policies
                            </li>
                            <li>
                            <b>Data Collection:</b> Opting out will not affect our ability to collect data for other purposes. You will still see ads from the Moonfox Ad Exchange on sites using our services, but these ads will not be targeted based on your data.

                            </li></ul>
                            <h4>9. Reviewing and Updating Your Information
                            </h4>
                            <p>
                            Moonfox is committed to maintaining the accuracy, completeness, and reliability of the information we collect. To review, correct, update, or change your contact or billing information submitted through our website, or to request account deactivation or removal from our direct marketing efforts, please follow these steps:

                            </p>
                            <ul><li><b>Contact Us:</b> Email us at moon@moonfox.in with your request.
                            </li>
                            <li>
                            <b>Information Management:</b> Specify the information you wish to review or update or indicate your request for account deactivation or removal from marketing communications.

                            </li></ul>
                            <p>
                            We will take reasonable steps to process your request in a timely manner. For further assistance or if you have any questions, please do not hesitate to contact us.

                            </p>

                            <h4>
                            10. International Information Transfers
                            </h4>
                            <p>
                            Please note that the information we collect, including contact and billing details, may be transferred to and stored on servers or databases located outside your state, province, country, or other jurisdiction. Privacy laws in these locations may not offer the same level of protection as those in your region.

                            </p>
                            <p>
                            If you are accessing Moonfox Services from outside the United States, your information will be processed and stored in the United States. By agreeing to this Privacy Policy or using Moonfox Services, you consent to this international transfer and processing of your information.

                            </p>
                            <h4>
                            11. Security

                            </h4>
                            <p>
                            We take reasonable measures to protect the information we collect from unauthorized access, destruction, use, modification, or disclosure. While we strive to implement and maintain security procedures, no system is completely secure, and we cannot guarantee absolute protection.

                            </p>
                            <h4>
                            12. Policy Updates

                            </h4>
                            <p>
                            We may update this Privacy Policy periodically. Any changes will be posted on the Moonfox website, and the revised Privacy Policy will include the effective date of the changes. Your continued use of our services following the posting of any updates signifies your acceptance of the revised policy.

                            </p>
                            <h4>
                            13. Contact Us

                            </h4>
                            <p>
                            For any questions or concerns about this Privacy Policy, please contact us at moon@moonfox.in.

                            </p>

                        </p>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default Policy