export const policyData = [
    {
      title: "Access to Managed Services is granted for the particular License Term.",
      path: "one",
      description:
        <>
          <p>Should a Product of MoonFox App be provided to the Customer pursuant to a license which, when fully paid, is to be perpetual, the following shall apply:</p>
          <h5>Scope of the Standard Terms and Conditions of Business</h5>
          <p>The standard terms and conditions of business set out below (hereinafter referred to as "Terms") shall apply to any and all agreements between MoonFox App, and its Customers concerning the delivery of its Products and/or Services, in particular, the provision of Software and its Updates/Upgrades, Managed Services and the rendering of related Services.</p>
          <p>These Terms shall also apply to any and all future agreements between MoonFox App and the Customer without any further reference to them. In addition to these Terms, the EULA shall be applicable to any use of Products and in case of Services; the respective SLA shall be applicable in addition to these Terms. In case of conflict between these Terms and the EULA and/or the respective SLA, the provisions of these Terms shall prevail unless a specific term in a EULA or SLA is more favorable to MoonFox App.</p>
          <p>These Terms shall prevail over any proposed terms and conditions of the Customer, even if the Customer has communicated its terms and conditions to MoonFox App unless MoonFox App has agreed to customer's terms and conditions in www.MoonFoxstoreindia.com. These Terms also apply in cases where MoonFox App provides its Products and/or Services to the Customer without any reservation and/or with knowledge of the contradicting or differing terms of the Customer.</p>
          <p>MoonFox App reserves the right to change, update, amend or modify these Terms at any time. Such changes will be transmitted to the Customer in www.MoonFoxstoreindia.com (email will be sufficient). The Customer may object to the changes. If the Customer does not object to the changes or any part thereof, within four (4) weeks after receipt of a notification (hereinafter referred to as "Objection Period"), the changes shall be deemed accepted unequivocally by the Customer. If the Customer objects to the notified changes within the Objection Period, the agreement will continue on the present Terms or MoonFox App may, in its discretion terminate any or all licenses or other agreements with the Customer. Objections, notifications and other correspondence from the Customer to MoonFox App shall be sent to the address at the end of these Terms.</p>
          <h5>Why do we Require following PERMISSIONS in our App</h5>
          <p><strong className="text-success">INTERNET -</strong> We use INTERNET permission to use the internet only.</p>
          <p><strong className="text-success">LOCATION -</strong> We need this permission for Parent device to get their Child's location for safety needs. We don't collect or share this information with anyone</p>
          <p><strong className="text-success">ACCESSIBILITY SERVICE -</strong> This app requires Accessibility Service to detect the web browser url and to detect current mobile app run by the Child.</p>
          <p><strong className="text-success">CAMERA -</strong> We require this permission to take picture from rear camera. We don’t collect or share this data.</p>
          <p><strong className="text-success">DISPLAY OVER OTHER APPS -</strong> We need this permission so that Parent can control child’s device apps.</p>
          <p><strong className="text-success">FOREGROUND_SERVICE -</strong> We require this permission to show users that our service is running in background. We don’t collect or share this data.</p>
          <p><strong className="text-success">STORAGE -</strong> We need this permission to access camera picture which we click when child is in danger. We don’t share or collect this data.</p>
          <p><strong className="text-success">QUERY_ALL_PACKAGES -</strong> We need this permission to detect whether the App is installed or not. We don’t share or collect this data.</p>
          <p><strong className="text-success">PACKAGE_USAGE_STATS -</strong> This permission is required for Parent to see their Child’s App Usage Data. We don’t share or collect this data.</p>
          <p><strong className="text-success">REQUEST_IGNORE_BATTERY_OPTIMIZATIONS -</strong> We need this permission so that our app features run smoothly without being interrupted by battery.</p>
        </>,
    },
    {
      title: "Definitions",
      path: "two",
      description:
        <>
          <p><strong class="text-success">Access -</strong> means access to a Service by use of a browser and with the aid of a Login.</p>
          <p><strong class="text-success">Automatic Renewal -</strong> means renewal of a License at the end of a License Term without added notice. MoonFox .</p>
          <p><strong class="text-success">Apps Freeware -</strong> means any and all Products and Services that are provided to the Customer free of charge.</p>
          <p><strong class="text-success">MoonFox App Shareware -</strong> means any and all Products and Services that are provided to the Customer free of charge for trial purposes for a limited or unlimited License Term.</p>
          <p><strong class="text-success">Computer -</strong> means any device that is able to process data with the assistance of a programmable calculation specification.</p>
          <p><strong class="text-success">Consumer -</strong> means a Consumer and/or Entrepreneur.</p>
          <p><strong class="text-success">Entrepreneur -</strong> means any natural person, partnership, corporation, Limited Liability Company or other legal business entity that uses the Products or Services of MoonFox App in their or its business and is not using the Products or Services for personal or family reasons.</p>
          <p><strong class="text-success">EULA -</strong> means the End User License Agreement of the respective MoonFox App entity that must be accepted by the Customer before any use of the Products. I (users) agree all the terms and conditions with MoonFox apps. MoonFox  provides lot of various different applications, Apps purpose is for safety and entertainment purpose only. If any misuse will happened through any apps of MoonFox  then i agree on that i will be only responsible. MoonFox  team will not be responsible directly or indirectly. I am also responsible for my data storage and lost of data, MoonFox  team are not responsible for that. MoonFox ’s IOS application has limited features only as provided by the Apple guidelines and IOS limitations in software architecture. Customer or end users having not any rights to claim for same features in both IOS and Android Operating system. MoonFox ’s Android and IOS having different features totally depends on mobile operating systems. It stipulates the Customer's usage rights for the Products under the relevant agreement and is available for download on www.MoonFoxstoreindia.com.</p>
          <p><strong class="text-success">Fee -</strong> means the price or the License fee to be paid for a Product or Service of MoonFox App.</p>
          <p><strong class="text-success">License -</strong> means the Customer's right to use the Products or Services subject to these Terms and in accordance with the EULA or SLA in effect as at the date of execution of the relevant agreement. The License shall detail the nature and scope of the Customer's right to use the Products and/or Services.</p>
          <p style={{ marginTop: '0px' }}>For those MoonFox App Products for which an Update Service is provided, the License shall also detail the Customer's right to receive ongoing Updates/Upgrades during the License Term for the provided Software. License Term - means the time period for which MoonFox App has granted Customer a License to a Product or Service and, if applicable to receive Updates and Upgrades. The License Term commences as soon as the Customer has received a Software Key or Login.</p>
          <p><strong class="text-success">Login -</strong> means entering of identifier information into a Managed Service by a Customer in order to access that Managed Service. By sending a Login to a Customer, MoonFox App grants a License to the Customer to the Managed Service to which the Login applies.</p>
          <p><strong class="text-success">Malware -</strong> means any software and any other dataset that causes damaging or undesirable functions in Computer or in a computer system of a user.</p>
          <p><strong class="text-success">Managed Service -</strong> means a software application managed by MoonFox App and deployed to Customer over the Internet with the aid of a Login.</p>
          <p><strong class="text-success">Multiple Use -</strong> means the simultaneous storage, simultaneous retention and any other contemporaneous use of MoonFox App Products on several Computers. Such use is only allowed pursuant to Licenses for Multiple Use for some MoonFox App products.</p>
          <p><strong class="text-success">One-Time-Code (OTC) -</strong> means a sequence of numbers, letters and/or other symbols that must be entered into the Computer for some Software of MoonFox App during initial installation. In such cases, MoonFox App will provide Customer with an OTC. Upon entering a valid OTC, the Customer shall receive the Software Key, and the License Term for that Software shall commence.</p>
          <p><strong class="text-success">Product -</strong> means MoonFox App Software including any applicable Update Service and user handbooks or other goods provided by MoonFox App.</p>
          <p><strong class="text-success">Reseller -</strong> means a Customer to whom Products or Services are provided expressly for the purpose of resale.</p>
          <p><strong class="text-success">Service -</strong> means Managed Service or other related services provided by MoonFox App.</p>
          <p><strong class="text-success">SLA -</strong> means the Service Level Agreement of the respective MoonFox App entity that must be accepted by the Customer before any use of the Services. It defines the Customer's rights of use for the respective Services under the relevant agreement and is available for download on www.MoonFoxstoreindia.com.</p>
          <p><strong class="text-success">Software -</strong> means MoonFox App computer programs in object code format, including any and all Updates and Upgrades thereof that MoonFox App makes available and for which MoonFox App has granted a License to a Customer.</p>
          <p><strong class="text-success">Software Key -</strong> means an encrypted code that identifies the Software provided to Customer by MoonFox App and is necessary for installation and access to Updates/Upgrades. The Software Key is an integral component of the Software. By sending a Software Key, MoonFox App grants the Customer the License to the Software to which the Software Key applies.</p>
          <p><strong class="text-success">Support -</strong> means a Service which MoonFox App offers to Customers seeking help desk support for Products or Services.</p>
          <p><strong class="text-success">Updates and Upgrades -</strong> mean the updating of the Software. Classification of the updating as an Update or Upgrade is at MoonFox App sole discretion.</p>
          <p><strong class="text-success">Update Service -</strong> means the ongoing updating of Software through Updates and/or Upgrades and providing such Updates and Upgrades to the Customer by MoonFox App. Some Software requires the Update Service for functional use. MoonFox App may at its sole discretion render and designate services under its Update Service as an Update or Upgrade.</p>
          <p>Offer and Acceptance of the Agreement The presentation of MoonFox App Products and Services on the internet and in prospectuses, catalogues and brochures is for informational purposes only and shall not provide an express or implied warranty nor any binding offer from MoonFox App but, rather, shall constitute an opportunity for the Customer to learn information and to prepare to make a binding contractual offer in the form of an order.</p>
          <p>A legally binding agreement between MoonFox App and the Customer shall be concluded only if the Customer places an order and MoonFox App accepts such order by way of a written order confirmation (email is sufficient) or by providing one or more Products and/or Services to the Customer, (e.g. per download or by provision of a Login).</p>
          <p>Any and all orders accepted by MoonFox App are subject to these Terms and the applicable EULA and/or applicable SLA.</p>
          <p>Should the Customer be entitled to withdraw from or terminate an agreement based on any applicable statutory withdrawal right and should the Customer exercise this right within the relevant statutory period, the relevant EULA and/or SLA shall terminate at the same time. In case of such withdrawal or termination, MoonFox App will refund the Customer any fees on a pro-rata basis that have already been paid for the respective Product or Service according to any applicable statutory provisions.</p>
          <p>Any deviations from or modifications to these Terms must be expressly agreed to between the parties in www.MoonFoxstoreindia.com.</p>
          <p>Offer and Acceptance of the Agreement The presentation of MoonFox App Products and Services on the internet and in prospectuses, catalogues and brochures is for informational purposes only and shall not provide an express or implied warranty nor any binding offer from MoonFox App but, rather, shall constitute an opportunity for the Customer to learn information and to prepare to make a binding contractual offer in the form of an order.</p>
          <p>A legally binding agreement between MoonFox App and the Customer shall be concluded only if the Customer places an order and MoonFox App accepts such order by way of a written order confirmation (email is sufficient) or by providing one or more Products and/or Services to the Customer, (e.g. per download or by provision of a Login).</p>
          <p>Any and all orders accepted by MoonFox App are subject to these Terms and the applicable EULA and/or applicable SLA.</p>
          <p>Should the Customer be entitled to withdraw from or terminate an agreement based on any applicable statutory withdrawal right and should the Customer exercise this right within the relevant statutory period, the relevant EULA and/or SLA shall terminate at the same time.</p>
          <p>In case of such withdrawal or termination, MoonFox App will refund the Customer any fees on a pro-rata basis that have already been paid for the respective Product or Service according to any applicable statutory provisions.</p>
          <p>Any deviations from or modifications to these Terms must be expressly agreed to between the parties in www.MoonFoxstoreindia.com.</p>
        </>,
    },
    {
      title: "Subject matter of Agreements",
      path: "three",
      description:
        <>
          <p>The EULAs and SLAs that are concluded subject to these Terms shall define the Customer's rights to use MoonFox App Products and/or Services in accordance with these Terms and the applicable EULA and/or SLA. Upon payment of the agreed Fee, the Customer shall be granted such use rights.</p>
          <h5>For Software for which an Update Service is available, the License shall include:</h5>
          <p>A non-exclusive, non-transferable (with the exception of clause 5. below) right to use the Software without added charge for the License Term, and A right to access the Update Service during the License Term as paid for.</p>
          <p>For Managed Services and Software for which no Update Service is provided, the License shall include a non-exclusive, non-transferable (with the exception of clause 5. below) right to use the Managed Services or the Software during the License Term as paid for.</p>
          <p>Title in and to the Products and Services, and any and all other rights, including, but not limited to the rights of publication, reproduction, processing, translation and other exploitation rights, shall be retained by MoonFox App.</p>
          <p>After an agreement has been withdrawn, rescinded or terminated, the Customer's License for the Products or Services shall automatically terminate and Customer shall have no further right of Access to or use of the Product or Services. The Customer shall receive no further Updates/Upgrades from MoonFox App.</p>
          <p>The Customer shall dispose of all Software, in particular, the original data media, any backup copies and the Software data files stored on its Computer system. MoonFox App may demand a written confirmation by the Customer declaring that all Software has been deleted in full.</p>
          <p>Software has a specific product lifecycle that governs the operating systems with which the Software is compatible and how long the Software will be supported by MoonFox App. Software is possibly not compatible with operating systems that are not specified in the product lifecycle or once the support period has expired. Therefore, the product lifecycle constitutes therefore an integral part of the relevant agreement and is published on www.MoonFoxstoreindia.com.</p>
          <h5>Provisions for the use of Software and Managed Service:</h5>
          <p>In using the Software and/or Managed Service, the Customer shall at all times comply with these Terms and Conditions as well as the terms of the EULA and/or SLA applicable at the time of execution of this agreement. In particular, the Software must not be reproduced, transferred or decompiled (i.e. recompilation into the source code), unless expressly permitted by the EULA or permitted according to law. If the Customer wishes to reverse engineer, decompile or disassemble the Software (hereinafter referred to as "De-compilation") for the purpose of achieving interoperability with other computer programs if allowed by law, the Customer shall contact MoonFox App before De-compilation of the Software and request the provision of the information necessary for the achievement of such interoperability. If MoonFox App supplies such information concerning interoperability without undue delay, the Customer shall not be entitled to decompile the Software. Software for which no special Licenses for Multiple Use are available according to the relevant product information is restricted to use on one Computer by a Customer. For this purpose, the Customer may use any available Computer that meets the system requirements and for which the License was granted. Should the Customer change the Computer, the Software must be deleted from the Computer on which the Software was previously installed before the reinstallation of the Software on a different Computer. For Software whose product information provides special Licenses for Multiple Use, Multiple Use shall be permissible only if, and to the extent that, the Customer purchased and was granted the relevant type or number of Licenses by MoonFox App.</p>
          <p>Unless explicitly granted in the applicable License, Customer shall not have the right to access Managed Services by simultaneous or multiple use of the same Login. MoonFox App reserves all rights to bring an action to prohibit or stop any unauthorized use of Products and/or Services by the Customer, including but not limited to, claims for injunctive relief and damages. Unauthorized use by the Customer may lead to criminal prosecution under the relevant laws. Reference is also made to the supplementary terms and conditions of use under the applicable EULA and/or the SLA. Special provisions for Resellers If Products and/or Services are provided to a Reseller, the Reseller may transfer the License to a third party. In the event of a resale, the serial numbers issued by MoonFox App (e.g. License numbers, OTCs or Logins) shall be stated in the invoice.</p>
          <p>Special provisions for Resellers If Products and/or Services are provided to a Reseller, the Reseller may transfer the License to a third party. In the event of a resale, the serial numbers issued by MoonFox App (e.g. License numbers, OTCs or Logins) shall be stated in the invoice.</p>
          <p>The Reseller shall not grant to its Customers any rights in the Products and/or Services that are different from, or greater than, those permitted pursuant to these Terms and Conditions, a EULA and/or an SLA. The Reseller shall not reproduce the Products and/or Services in whole or in part, including for purposes of data backup. The parties acknowledge that damages resulting from a breach of this clause by Reseller are difficult to determine and/or quantify. Therefore, for each breach of the foregoing provisions of this clause 5, and without prejudice to MoonFox App right to seek other remedies at law or in equity the Reseller shall pay to MoonFox App liquidated damages in the amount of USD 20,000. Additional claims by MoonFox App resulting from the breach of these provisions remain unaffected. Such liquidated damages will be set off against any other damage awards to MoonFox App resulting from the breach.</p>
          <p>The Reseller shall not reproduce the Products and/or Services in whole or in part, including for purposes of data backup. The parties acknowledge that damages resulting from a breach of this clause by Reseller are difficult to determine and/or quantify. Therefore, for each breach of the foregoing provisions of this clause 5, and without prejudice to MoonFox App right to seek other remedies at law or in equity the Reseller shall pay to MoonFox App liquidated damages in the amount of USD 20,000. Additional claims by MoonFox App resulting from the breach of these provisions remain unaffected. Such liquidated damages will be set off against any other damage awards to MoonFox App resulting from the breach.</p>
          <p>MoonFox App terms &amp; Conditions will be applicable for all the apps offered by MoonFox .</p>
        </>,
    },
    {
      title: "Delivery",
      path: "four",
      description:
        <>
          <p>MoonFox App delivery obligations shall be governed only by
            (i) MoonFox App order confirmation, (ii) these Terms, (iii) the EULA and/or the SLA (as applicable) unless explicitly agreed otherwise between the parties in www.MoonFoxstoreindia.com. MoonFox App may render partial deliveries where a partial delivery is reasonable for the Customer and does not significantly impair the Customer's interests. Deviations of the delivered Products and/or Services from documentation shall be permitted provided that they are reasonable and that the performance of the Products or Services is not materially, adversely affected. MoonFox App reserves the right to make technical changes to the Products and/or Services which enhance their operability and performance or which are necessary for the operability of the Products and/or Services.</p>
          <p>Deviations of the delivered Products and/or Services from documentation shall be permitted provided that they are reasonable and that the performance of the Products or Services is not materially, adversely affected. MoonFox App reserves the right to make technical changes to the Products and/or Services which enhance their operability and performance or which are necessary for the operability of the Products and/or Services. Should Products and/or Services licensed by Customer be replaced by new Products and/or Services in MoonFox App product line, and such new Products and/or Services perform as well or better than the Products and/or Services ordered by Customer, then MoonFox App has Deviations of the delivered Products and/or Services from documentation shall be permitted provided that they are reasonable and that the performance of the Products or Services is not materially, adversely affected. MoonFox App reserves the right to make technical changes to the Products and/or Services which enhance their operability and performance or which are necessary for the operability of the Products and/or Services.</p>
  
          <p>Should Products and/or Services licensed by Customer be replaced by new Products and/or Services in MoonFox App product line, and such new Products and/or Services perform as well or better than the Products and/or Services ordered by Customer, then MoonFox App has the right to deliver such new Products and/or Services instead of the ordered Products Should Products and/or Services licensed by Customer be replaced by new Products and/or Services in MoonFox App product line, and such new Products and/or Services perform as well or better than the Products and/or Services ordered by Customer, then MoonFox App has Deviations of the delivered Products and/or Services from documentation shall be permitted provided that they are reasonable and that the performance of the Products or Services is not materially, adversely affected. MoonFox App reserves the right to make technical changes to the Products and/or Services which enhance their operability and performance or which are necessary for the operability of the Products and/or Services.</p>
          <p>Should Products and/or Services licensed by Customer be replaced by new Products and/or Services in MoonFox App product line, and such new Products and/or Services perform as well or better than the Products and/or Services ordered by Customer, then MoonFox App has the right to deliver such new Products and/or Services instead of the ordered Products and/or Services. Should Products and/or Services of MoonFox App be shipped at the Customer's request, the risk for damage and loss shall pass to the Customer upon release for shipment by MoonFox App. In the event of receipt of Software by download, risk shall pass to the Customer as soon as Customer has received the necessary Software Key.</p>
          <p>Should MoonFox App performance be delayed beyond the date confirmed by MoonFox App, claims for late performance may only be asserted following the expiry of a reasonable grace period of no less than three weeks set by the Customer. Should MoonFox App be in default of its delivery obligations or should delivery become impossible for MoonFox App, MoonFox App shall only be liable pursuant to clauses 10 and 11 below. If timely delivery is prevented by circumstances that are not just temporary and that do not fall within MoonFox App control (in particular, in the event of strike, lock-out, unavailability of materials, force majeure, transport hindrances, plant closures or unavailability of internet connection), MoonFox App or the Customer may, upon ten (10) days written notice, rescind this agreement without giving rise to any obligation to the other party.</p>
          <p>Should Products and/or Services of MoonFox App be shipped at the Customer's request, the risk for damage and loss shall pass to the Customer upon release for shipment by MoonFox App. In the event of receipt of Software by download, risk shall pass to the Customer as soon as Customer has received the necessary Software Key.</p>
          <p>Should MoonFox App performance be delayed beyond the date confirmed by MoonFox App, claims for late performance may only be asserted following the expiry of a reasonable grace period of no less than three weeks set by the Customer. Should MoonFox App be in default of its delivery obligations or should delivery become impossible for MoonFox App, MoonFox App shall only be liable pursuant to clauses 10 and 11 below. If timely delivery is prevented by circumstances that are not just temporary and that do not fall within MoonFox App control (in particular, in the event of strike, lock-out, unavailability of materials, force majeure, transport hindrances, plant closures or unavailability of internet connection), MoonFox App or the Customer may, upon ten (10) days written notice, rescind this agreement without giving rise to any obligation to the other party.</p>
        </>,
    },
    {
      title: "Automatic renewal",
      path: "five",
      description:
        <>
          <p>Remuneration and payment terms Unless expressly agreed otherwise, MoonFox App Fees are net free place of shipment, and all shipping costs, in particular, packaging, transport costs and transport insurance, as well as applicable statutory value added tax - if any - shall be paid by the Customer. Payments shall be due immediately without any deductions. Bills of exchange and checks shall be accepted by MoonFox App only pursuant to a special agreement between the parties and only if these methods of payment do not give rise to any costs and fees for MoonFox App. If the Customer defaults in payments, MoonFox App may demand agreed upon or statutory default interest for all past due sums without further notice.</p>
          <p>Payments shall be due immediately without any deductions. Bills of exchange and checks shall be accepted by MoonFox App only pursuant to a special agreement between the parties and only if these methods of payment do not give rise to any costs and fees for MoonFox App.</p>
          <p>If the Customer defaults in payments, MoonFox App may demand agreed upon or statutory default interest for all past due sums without further notice.</p>
          <p>Payments shall be due immediately without any deductions.</p>
          <p>Bills of exchange and checks shall be accepted by MoonFox App only pursuant to a special agreement between the parties and only if these methods of payment do not give rise to any costs and fees for MoonFox App.</p>
          <p>If the Customer defaults in payments, MoonFox App may demand agreed upon or statutory default interest for all past due sums without further notice. Unless expressly agreed otherwise, MoonFox App Fees are net free place of shipment, and all shipping costs, in particular, packaging, transport costs and transport insurance, as well as applicable statutory value added tax - if any - shall be paid by the Customer. Payments shall be due immediately without any deductions. Bills of exchange and checks shall be accepted by MoonFox App only pursuant to a special agreement between the parties and only if these methods of payment do not give rise to any costs and fees for MoonFox App. If the Customer defaults in payments, MoonFox App may demand agreed upon or statutory Payments shall be due immediately without any deductions.</p>
          <p>Bills of exchange and checks shall be accepted by MoonFox App only pursuant to a special agreement between the parties and only if these methods of payment do not give rise to any costs and fees for MoonFox App.</p>
          <p>If the Customer defaults in payments, MoonFox App may demand agreed upon or statutory Unless expressly agreed otherwise, MoonFox App Fees are net free place of shipment, and all If the Customer defaults in payments, MoonFox App may demand agreed upon or statutory Payments shall be due immediately without any deductions. Bills of exchange and checks shall be accepted by MoonFox App only pursuant to a special agreement between the parties and only if these methods of payment do not give rise to any costs and fees for MoonFox App.</p>
          <p>If the Customer defaults in payments, MoonFox App may demand agreed upon or statutory Unless expressly agreed otherwise, MoonFox App Fees are net free place of shipment, and all If the Customer defaults in payments, MoonFox App may demand agreed upon or statutory If the Customer defaults in payments, MoonFox App may demand agreed upon or statutory Unless expressly agreed otherwise, MoonFox App Fees are net free place of shipment, and all If the Customer defaults in payments, MoonFox App may demand agreed upon or statutory Payments shall be due immediately without any deductions. Bills of exchange and checks shall be accepted by MoonFox App only pursuant to a special agreement between the parties and only if these methods of payment do not give rise to any costs and fees for MoonFox App.</p>
          <p>If the Customer defaults in payments, MoonFox App may demand agreed upon or statutory Unless expressly agreed otherwise, MoonFox App Fees are net free place of shipment, and all If the Customer defaults in payments, MoonFox App may demand agreed upon or statutory Payments shall be due immediately without any deductions. Bills of exchange and checks shall be accepted by MoonFox App only pursuant to a special agreement between the parties and only if these methods of payment do not give rise to any costs and fees for MoonFox App.</p>
          <p>If the Customer defaults in payments, MoonFox App may demand agreed upon or statutory Unless expressly agreed otherwise, MoonFox App Fees are net free place of shipment, and all shipping costs, in particular, packaging, transport costs and transport insurance, as well as applicable statutory value added tax - if any - shall be paid by the Customer.</p>
          <p>Payments shall be due immediately without any deductions. Bills of exchange and checks shall be accepted by MoonFox App only pursuant to a special agreement between the parties and only if these methods of payment do not give rise to any costs and fees for MoonFox App.</p>
          <p>If the Customer defaults in payments, MoonFox App may demand agreed upon or statutory default interest for all past due sums without further notice.</p>
          <p>IF the Customer defaults in payment, then MoonFox App may withhold all deliveries to the Customer, including Updates and Upgrades. Customer should carefully note the consequences of a failure to install Updates/Upgrades, in particular, that the protective function of the Software may be impaired.</p>
          <p>The Customer may only set off against claims of MoonFox App if the Customer's particular claims are uncontested or confirmed in a final and binding judgment and only insofar as its counterclaims are based on the same legal relationship between the parties. Customer should carefully note the consequences of a failure to install Updates/Upgrades, in particular, that the protective function of the Software may be impaired. The Customer may only set off against claims of MoonFox App if the Customer's particular claims are uncontested or confirmed in a final and binding judgment and only insofar as its counterclaims are based on the same legal relationship between the parties.</p>
          <p>The Customer may only set off against claims of MoonFox App if the Customer's particular claims are uncontested or confirmed in a final and binding judgment and only insofar as its counterclaims are based on the same legal relationship between the parties.</p>
          <p>Customer should carefully note the consequences of a failure to install Updates/Upgrades, in particular, that the protective function of the Software may be impaired.</p>
          <p>The Customer may only set off against claims of MoonFox App if the Customer's particular claims are uncontested or confirmed in a final and binding judgment and only insofar as its counterclaims are based on the same legal relationship between the parties.</p>
          <p>The Customer may only set off against claims of MoonFox App if the Customer's particular claims are uncontested or confirmed in a final and binding judgment and only insofar as its counterclaims are based on the same legal relationship between the parties.</p>
          <p>Customer should carefully note the consequences of a failure to install Updates/Upgrades, in particular, that the protective function of the Software may be impaired.</p>
          <p>The Customer may only set off against claims of MoonFox App if the Customer's particular claims are uncontested or confirmed in a final and binding judgment and only insofar as its counterclaims are based on the same legal relationship between the parties.</p>
        </>,
    },
    {
      title: "Customer's duties",
      path: "six",
      description:
        <>
          <p>Proper use of the Software requires receipt of a Software Key from MoonFox App or one of its authorized Resellers and Customer's installation of all Updates/Upgrades for the respective Software by Customer. If the Customer has not installed the current Updates/Upgrades of MoonFox App, the Software's protection against computer viruses and other Malware may be materially impaired. Installation of the Software and its Updates/Upgrades as well as regular downloading of the full and current Updates/Upgrades shall be the Customer's sole responsibility.</p>
          <p>The Customer shall familiarize itself with the key functions of the Software or Service. The Customer shall bear the entire risk as to whether the Software or Service meets the individual requirements and needs of the Customer. The setup of a functional hardware and software environment for the Software or Service shall be the sole responsibility of the Customer. The same shall apply to regular data backup within the Customer's EDP system.</p>
          <p>The Customer shall comply with MoonFox App advice and instructions concerning installation of the Software, the updating thereof by Updates/Upgrades and its operation. The Customer shall regularly consult MoonFox App website (www.MoonFoxstoreindia.com) to learn MoonFox App current advice and shall take this into account while operating the Software.</p>
          <p>The Customer shall install the Software at its own cost.</p>
          <p>This shall also be the case if certain Updates/Upgrades and releases require a new installation of the Software during the License Term. The Customer shall promptly notify MoonFox App of any alleged or potential defects in the The Customer shall promptly notify MoonFox App of any alleged or potential defects in the Products and/or Services provided. The Customer shall notify MoonFox App without undue delay of any changes to its email address or contact information in order for MoonFox App to be able to send the Customer security-relevant information for the use of the Software or Services. MoonFox App shall not be liable for any damages resulting from Customer's failure to provide such notification. The Customer shall promptly notify MoonFox App of any alleged or potential defects in the Products and/or Services provided. The Customer shall notify MoonFox App without undue delay of any changes to its email address or contact information in order for MoonFox App to The Customer shall promptly notify MoonFox App of any alleged or potential defects in the Products and/or Services provided. The Customer shall notify MoonFox App without undue delay of any changes to its email address or contact information in order for MoonFox App to be able to send the Customer security-relevant information for the use of the Software or Services. MoonFox App shall not be liable for any damages resulting from Customer's failure to provide such notification.</p>
          <p>The Customer shall promptly notify MoonFox App of any alleged or potential defects in the Products and/or Services provided.</p>
          <p>The Customer shall notify MoonFox App without undue delay of any changes to its email address or contact information in order for MoonFox App to be able to send the Customer security-relevant information for the use of the Software or Services.</p>
          <p>MoonFox App shall not be liable for any damages resulting from Customer's failure to provide such notification.</p>
  
        </>,
    },
    {
      title: "Warranty and Limitations",
      path: "seven",
      description:
        <>
          <p>Unless expressly agreed otherwise, the Products provided by MoonFox App as well as MoonFox App Services shall reasonably conform to all material product information and specifications provided by MoonFox App, including that in the user handbooks. MoonFox App does not warrant that the Products and Services under the agreement will be fit for any particular purpose. The Customer is advised and understands that, based on the current state of the art, technology program errors cannot be excluded from a Software Product or Service with complete certainty and that it is not possible to develop Software or Services that detect The Customer is advised and understands that, based on the current state of the art, technology program errors cannot be excluded from a Software Product or Service with complete certainty and that it is not possible to develop Software or Services that detect The Customer is advised and understands that, based on the current state of the art, technology program errors cannot be excluded from a Software Product or Service with complete certainty and that it is not possible to develop Software or Services that detect any and all existing viruses or other Malware. FAILURE TO INSTALL UPDATES OR UPGRADES AS PROVIDED WILL VOID ANY WARRANTIES HERE UNDER.</p>
          <p>FAILURE TO INSTALL UPDATES OR UPGRADES AS PROVIDED WILL VOID ANY WARRANTIES HERE UNDER. Customer agrees and warrants that the Products shall not be used in high risk pursuits that require error-free, permanent operation of systems and in which the failure of the Products may result in harm to an individual’s life, body or health or in substantial damage to property or the environment (such as high risk activities and high availability activities, including but not limited to the operation of nuclear facilities, weapon systems, aviation navigation or communication systems, air traffic control, life support systems and equipment, machine and production processes of pharmaceuticals and food production).</p>
          <p>MoonFox App does not warrant or guarantee that the Products are fit for use in these, or other, high risk areas. If the Customer is an Entrepreneur and MoonFox App has provided the Customer with the Product pursuant to a perpetual, fully paid license, then defects in the provided Products, shall be rectified by MoonFox App, as follows, provided the Customer has notified MoonFox App of the respective defect promptly Notified errors shall be rectified by MoonFox App choice If the Customer is an Entrepreneur and MoonFox App has provided the Customer with the Product pursuant to a perpetual, fully paid license, then defects in the provided Products, shall be rectified by MoonFox App, as follows, provided the Customer has notified MoonFox App of the respective defect promptly Notified errors shall be rectified by MoonFox App choice through correction of the error ("repair") or provision of an error-free Product ("substitute If the Customer is an Entrepreneur and MoonFox App has provided the Customer with the Product pursuant to a perpetual, fully paid license, then defects in the provided Products, shall be rectified by MoonFox App, as follows, provided the Customer has notified MoonFox App of the respective defect promptly Notified errors shall be rectified by MoonFox App choice through correction of the error ("repair") or provision of an error-free Product ("substitute delivery").</p>
          <p>Costs for correction shall be borne by MoonFox App.</p>
          <p>If the Customer is an Entrepreneur and MoonFox App has provided the Customer with the Product pursuant to a perpetual, fully paid license, then defects in the provided Products, shall be rectified by MoonFox App, as follows, provided the Customer has notified MoonFox App of the respective defect promptly Notified errors shall be rectified by MoonFox App choice through correction of the error ("repair") or provision of an error-free Product ("substitute delivery"). Costs for correction shall be borne by MoonFox App.</p>
          <p>If the defects cannot be corrected within a reasonable period or if repair and substitute delivery fail for other reasons, then the Customer may, at its choice, reduce the Fee paid to MoonFox App pro rata or - if the defects are not immaterial - terminate the agreement. Any damage claims of a Customer or claims for expenses incurred that arise from such defects shall be governed exclusively by clause 11. If MoonFox App has provided a Customer with the Product pursuant to a fully paid license for a specific term, for one year after commencement of the License Term defects in the Product shall be rectified by MoonFox App within a reasonable period after notification of the defect.</p>
          <p>The rectification of defects shall be effected, at MoonFox App choice, by way of repair or substitute delivery, free of charge. If the defects cannot be corrected within a reasonable period or if repair and substitute delivery fail for other reasons, then the Customer may, at its choice, reduce the Fee paid to MoonFox App pro rata or - if the defects are not immaterial - terminate the agreement. Any damage claims of a Customer or claims for expenses incurred that arise from such defects shall be governed exclusively by clause 11.</p>
          <p>If MoonFox App has provided a Customer with the Product pursuant to a fully paid license for a specific term, for one year after commencement of the License Term defects in the Product shall be rectified by MoonFox App within a reasonable period after notification of the defect.</p>
          <p>The rectification of defects shall be effected, at MoonFox App choice, by way of repair or substitute delivery, free of charge.</p>
          <p>If the defects cannot be corrected within a reasonable period or if repair and substitute delivery fail for other reasons, then the Customer may, at its choice, reduce the Fee paid to MoonFox App pro rata or - if the defects are not immaterial - terminate the agreement.</p>
          <p>Any damage claims of a Customer or claims for expenses incurred that arise from such defects shall be governed exclusively by clause 11.</p>
          <p>f MoonFox App has provided a Customer with the Product pursuant to a fully paid license for a specific term, for one year after commencement of the License Term defects in the Product shall be rectified by MoonFox App within a reasonable period after notification of the defect.</p>
          <p>The rectification of defects shall be effected, at MoonFox App choice, by way of repair or substitute delivery, free of charge.</p>
          <p>The Customer is only entitled to terminate the agreement due to the failure to grant use in accordance with the agreement if MoonFox App has been given sufficient opportunity to rectify the defect and such attempt has failed.</p>
          <p>A repair or substitute delivery shall be viewed to have failed only if MoonFox App has been given sufficient opportunity to affect a repair or substitution without achieving the desired result or if the repair or substitution was unjustifiably refused by MoonFox App. If rectification of a defect in the form of repair or subsequent delivery is only possible for MoonFox App at unreasonable expenses, MoonFox App may refuse to rectify the defect and may afford the Customer to its right to rescind or terminate the agreement.</p>
          <p>No warranty is provided for MoonFox App Freeware or other Products and/or Services provided by MoonFox App free of charge, except for the operability of the Computer Program hereinafter.</p>
          <p>The same shall apply for MoonFox App Shareware, as long as there is no agreement with the Customer for the unconditional provision of a Software Key or Login No warranty is provided for MoonFox App Freeware or other Products and/or Services provided by MoonFox App free of charge, except for the operability of the Computer Program hereinafter. The same shall apply for MoonFox App Shareware, as long as there is no agreement with the Customer for the unconditional provision of a Software Key or Login No warranty is provided for MoonFox App Freeware or other Products and/or Services provided by MoonFox App free of charge, except for the operability of the Computer Program hereinafter.</p>
          <p>The same shall apply for MoonFox App Shareware, as long as there is no agreement with the Customer for the unconditional provision of a Software Key or Login against payment. In no event shall any Customer ever be entitled to access, or demand access to the source code for the Software and/or Managed Services. The Customer shall notify MoonFox App without undue delay if a third party asserts claims against the Customer based on infringement of intellectual property rights by the Product and/or Managed Services.</p>
          <p>Should there be any such notification, whether in written documents or correspondence or in other form, the Customer shall provide these to MoonFox App without undue delay.</p>
          <p>MoonFox App liability for Customer losses due to such infringement is limited to the cost of the In no event shall any Customer ever be entitled to access, or demand access to the source code for the Software and/or Managed Services.</p>
          <p>The Customer shall notify MoonFox App without undue delay if a third party asserts claims against the Customer based on infringement of intellectual property rights by the Product and/or Managed Services.</p>
          <p>Should there be any such notification, whether in written documents or correspondence or in other form, the Customer shall provide these to MoonFox App without undue delay.</p>
          <p>MoonFox App liability for Customer losses due to such infringement is limited to the cost of the In no event shall any Customer ever be entitled to access, or demand access to the source code for the Software and/or Managed Services.</p>
          <p>The Customer shall notify MoonFox App without undue delay if a third party asserts claims against the Customer based on infringement of intellectual property rights by the Product and/or Managed Services.</p>
          <p>Should there be any such notification, whether in written documents or correspondence or in other form, the Customer shall provide these to MoonFox App without undue delay. MoonFox App liability for Customer losses due to such infringement is limited to the cost of the software or services provided by MoonFox App.</p>
          <p>If the Customer is an Entrepreneur and the product of MoonFox App is the subject of a perpetual license, the Customer's warranty claims for defects of the product lapse after one year from the commencement of the License Term.</p>
        </>,
    },
    {
      title: "Limitation of Liability for damages",
      path: "eight",
      description:
        <>
          <p>MoonFox App shall only be liable for any of the Customer's damages resulting from grossly negligent or intentional behavior of MoonFox App, and shall be limited to any amounts paid to MoonFox App by Customer during the twelve months immediately preceding the Customer's claim.</p>
          <p>If the Products or Services are provided by MoonFox App for a limited period of time, MoonFox App liabilities for defects expire at the time of the termination of the agreement.</p>
          <p>The foregoing limitations of liability also apply to all MoonFox App representatives, including but not limited to its directors, legal representatives, employees and other vicarious agents.</p>
          <p>Retention of title, transfer by way of security MoonFox App shall retain title in such Product(s) and goods as parts of Services until full payment.</p>
          <p>Should the Customer fail to pay, then MoonFox App may demand return of the Product(s). The Terms providing that the Customer acquires no title, including but not limited to the title stipulated in clause 4 above, shall remain unaffected by this clause.</p>
          <p>Should the Customer sell the Products and Services provided by MoonFox App before the Customer has itself paid the Fee to MoonFox App, the Customer and MoonFox App agree that the receivables from the resale shall be assigned to MoonFox App.</p>
          <p>Should the Products and Services be provided together with other items, the assignment of the receivables shall be limited to the value of MoonFox App Products and Services? MoonFox App may disclose the assignment or demand that the Customer notifies the debtor of such assignment.</p>
          <p>The Customer shall formally assign the receivables if still necessary at any time at MoonFox App request.</p>
          <p>Should the Customer sell the Products and Services provided by MoonFox App before the Customer has itself paid the Fee to MoonFox App, the Customer and MoonFox App agree that the receivables from the resale shall be assigned to MoonFox App.</p>
          <p>Should the Products and Services be provided together with other items, the assignment of the receivables shall be limited to the value of MoonFox App Products and Services? MoonFox App may disclose the assignment or demand that the Customer notifies the debtor of such assignment.</p>
          <p>The Customer shall formally assign the receivables if still necessary at any time at MoonFox App request.</p>
          <p>Should the Customer sell the Products and Services provided by MoonFox App before the Customer has itself paid the Fee to MoonFox App, the Customer and MoonFox App agree that the receivables from the resale shall be assigned to MoonFox App.</p>
          <p>Should the Products and Services be provided together with other items, the assignment of the receivables shall be limited to the value of MoonFox App Products and Services? MoonFox App may disclose the assignment or demand that the Customer notifies the debtor of such assignment.</p>
          <p>The Customer shall formally assign the receivables if still necessary at any time at MoonFox App request.</p>
          <p>Should the Customer sell the Products and Services provided by MoonFox App before the Customer has itself paid the Fee to MoonFox App, the Customer and MoonFox App agree that the receivables from the resale shall be assigned to MoonFox App.</p>
          <p>Should the Products and Services be provided together with other items, the assignment of the receivables shall be Should the Customer sell the Products and Services provided by MoonFox App before the Customer has itself paid the Fee to MoonFox App, the Customer and MoonFox App agree that the receivables from the resale shall be assigned to MoonFox App.</p>
          <p>Should the Products and Services be provided together with other items, the assignment of the receivables shall be limited to the value of MoonFox App Products and Services? MoonFox App may disclose the assignment or demand that the Customer notifies the debtor of such assignment.</p>
          <p>The Customer shall formally assign the receivables if still necessary at any time at MoonFox App request.</p>
          <p>Should the Customer sell the Products and Services provided by MoonFox App before the Customer has itself paid the Fee to MoonFox App, the Customer and MoonFox App agree that the receivables from the resale shall be assigned to MoonFox App.</p>
          <p>Should the Products and Services be provided together with other items, the assignment of the receivables shall be limited to the value of MoonFox App Products and Services? MoonFox App may disclose the assignment or demand that the Customer notifies the debtor of such assignment.</p>
          <p>The Customer shall formally assign the receivables if still necessary at any time at MoonFox App request.</p>
          <p>Should the Customer sell the Products and Services provided by MoonFox App before the Customer has itself paid the Fee to MoonFox App, the Customer and MoonFox App agree that the receivables from the resale shall be assigned to MoonFox App.</p>
          <p>Should the Products and Services be provided together with other items, the assignment of the receivables shall be limited to the value of MoonFox App Products and Services? MoonFox App may disclose the assignment or demand that the Customer notifies the debtor of such assignment.</p>
          <p>The Customer shall formally assign the receivables if still necessary at any time at MoonFox App request.</p>
          <p>Should the Customer sell the Products and Services provided by MoonFox App before the Customer has itself paid the Fee to MoonFox App, the Customer and MoonFox App agree that the receivables from the resale shall be assigned to MoonFox App.</p>
          <p>Should the Products and Services be provided together with other items, the assignment of the receivables shall be limited to the value of MoonFox App Products and Services? MoonFox App may disclose the assignment or demand that the Customer notifies the debtor of such assignment.</p>
          <p>The Customer shall formally assign the receivables if still necessary at any time at MoonFox App request.</p>
          <p>Should the Customer sell the Products and Services provided by MoonFox App before the Customer has itself paid the Fee to MoonFox App, the Customer and MoonFox App agree that the receivables from the resale shall be assigned to MoonFox App.</p>
          <p>Should the Products and Services be provided together with other items, the assignment of the receivables shall be limited to the value of MoonFox App Products and Services? MoonFox App may disclose the assignment or demand that the Customer notifies the debtor of such assignment.</p>
          <p>The Customer shall formally assign the receivables if still necessary at any time at MoonFox App request.</p>
          <p>Should the Customer sell the Products and Services provided by MoonFox App before the Customer has itself paid the Fee to MoonFox App, the Customer and MoonFox App agree that the receivables from the resale shall be assigned to MoonFox App.</p>
          <p>Should the Products and Services be provided together with other items, the assignment of the receivables shall be limited to the value of MoonFox App Products and Services? MoonFox App may disclose the assignment or demand that the Customer notifies the debtor of such assignment.</p>
          <p>The Customer shall formally assign the receivables if still necessary at any time at MoonFox App request.</p>
  
          <p>Should the Customer sell the Products and Services provided by MoonFox App before the Customer has itself paid the Fee to MoonFox App, the Customer and MoonFox App agree that the receivables from the resale shall be assigned to MoonFox App.</p>
          <p>Should the Products and Services be provided together with other items, the assignment of the receivables shall be limited to the value of MoonFox App Products and Services? MoonFox App may disclose the assignment or demand that the Customer notifies the debtor of such assignment.</p>
          <p>The Customer shall formally assign the receivables if still necessary at any time at MoonFox App request.</p>
          <p>MoonFox App shall retain title in such Product(s) and goods as parts of Services until full payment.</p>
          <p>Should the Customer fail to pay, then MoonFox App may demand return of the Product(s).</p>
          <p>The Terms providing that the Customer acquires no title, including but not limited to the title stipulated in clause 4 above, shall remain unaffected by this clause.</p>
          <p>Should the Customer sell the Products and Services provided by MoonFox App before the Customer has itself paid the Fee to MoonFox App, the Customer and MoonFox App agree that the receivables from the resale shall be assigned to MoonFox App.</p>
          <p>Should the Products and Services be provided together with other items, the assignment of the receivables shall be limited to the value of MoonFox App Products and Services? MoonFox App may disclose the assignment or demand that the Customer notifies the debtor of such assignment.</p>
          <p>The Customer shall formally assign the receivables if still necessary at any time at MoonFox App request.</p>
          <p>Demand for return of Product by MoonFox App shall not constitute grounds for rescission or termination of the agreement unless MoonFox App expressly advises the Customer thereof.</p>
          <p>The Customer's license to use the Products and Services shall lapse upon demand for the return of the Product by MoonFox App. Any and all backup copies made by the Customer for its own use must be returned to MoonFox App or deleted by the Customer.</p>
        </>,
    },
    {
      title: "Miscellaneous",
      path: "nine",
      description:
        <>
          <p>Should any provision of these Terms be or become invalid, ineffective or unenforceable, the remaining provisions of these Terms shall be valid and enforceable.</p>
          <p>Any necessary official permits for transports; purchase and use of the Products and Services in the destination country are the Customer's responsibility and shall be paid for by Customer.</p>
        </>,
    },
  ]