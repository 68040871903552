import React from 'react'
const ConfirmModal = (props) => {
    const { func, message, setDeleteModal } = props
    return (
        <>
            <div className="c-modal">
                <div className="c-modal-box" role="document">
                    <div className="c-modal-body text-center py-4">
                        <i class="fa fa-question-circle tcr" aria-hidden="true" style={{ fontSize: "40px" }}></i>
                        <h5 className="modal-title mt-2">{message}</h5>
                        <div className="btn-box mt-3">
                            <button type="button" onClick={func} className="btn btn-sm text-white" style={{ background: "#ef233c" }} >Yes</button>
                            <button type="button" className="btn btn-sm btn-secondary ml-2 "  onClick={() => setDeleteModal(false)}> No</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ConfirmModal;
