import Cookies from 'js-cookie';
import React, { useEffect } from 'react'
import './components/DashboardPannel/Style/css/style.css'
import './components/DashboardPannel/Style/css/custom.css'
import './components/DashboardPannel/Style/css/feather.css'
import './components/DashboardPannel/Style/css/themify-icons.css'
import './components/DashboardPannel/Style/css/vendor.bundle.base.css'
import { useNavigate } from 'react-router-dom';
import Header from './components/DashboardPannel/Common/Header';
import Sidebar from './components/DashboardPannel/Common/Sidebar';
import { getAllCookiesData } from './components/Utility/Utility';
const Auth = ({ cmp, role, path }) => {

    const Cmp = cmp
    const navigate = useNavigate()
    useEffect(() => {
        if (!getAllCookiesData()?.role) {
            navigate('/login')
        }
    }, [])

    return (
        <>
            <div class="container-scroller">
                {
                    getAllCookiesData()?.role ?
                        <Header />
                        :
                        <></>
                }
                <div class="container-fluid page-body-wrapper">
                    {
                        getAllCookiesData()?.role ?
                            <Sidebar path={path} />
                            :
                            <></>
                    }
                    <div class="main-panel">
                        <div class="content-wrapper p-3">
                            <Cmp {...{ role, path }} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default Auth