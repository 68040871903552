import axios from 'axios'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { toastifyError, toastifySuccess, url } from '../../Utility/Utility'

const AssigneDeleteModal = ({ setAssignModal, list, id ,getList}) => {
    const [assigned, setAssigned] = useState('')
    const transfer = async () => {
        if(assigned.length <=0){
            toastifyError("Select Assignee")
            return false
        }
        const data = {
            "type" : "assign-user-befor-delete",
            "assigned" : assigned,
            "id" : id
        }
        const res = await axios.post(url+"/user/assign-delete-user.php", data);
        if(res?.data?.status){
            getList()
            toastifySuccess(res?.data?.message)
            setAssignModal(false)
        }
    }
    return (
        <>
            <div className="c-modal">
                <div className="c-modal-box">
                    <div className="c-modal-body text-center py-4">
                        <i class="fa fa-question-circle tcr" aria-hidden="true" style={{ fontSize: "40px" }}></i>
                        <h5 className="modal-title mt-2" style={{ textTransform: "capitalize" }}>transfer the data to another handler</h5>
                        <div className="row justify-content-center">
                            <div className="col-9 mt-3">
                                <select name="assigned"  value={assigned} className="form-control" onChange={(e)=>setAssigned(e.target.value)}>
                                    <option value="">Select</option>
                                    {
                                        list?.map((val, index) => 
                                        {
                                            if(id != val.id){
                                                return <option value={val.id} key={index} >{val.username}</option>
                                            }
                                        }
                                            
                                        )
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="btn-box mt-4">
                            <Link to={`#`}>
                                <button type="button" className="btn btn-sm text-white" style={{ background: "#ef233c" }} onClick={()=>transfer()}>Transfer</button>
                            </Link>
                            <button type="button" className="btn btn-sm btn-secondary ml-2 " onClick={() => setAssignModal(false)}> No</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AssigneDeleteModal