import React from 'react'
import ReactLoading from "react-loading";
import styled from "tachyons-components";
const Loader = () => {
  return (
    <>
        <Section>
            <Article>
                <ReactLoading type="bars" color="#bd0001" />
            </Article>
        </Section>
    </>
  )
}

export default Loader

export const Section = styled('div')`
flex flex-wrap content-center justify-center w-100 h-100`;
export const Article = styled('div')`
w-25 ma2 h4 items-center justify-center flex flex-column flex-wrap`;