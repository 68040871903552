import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import Pagination from "react-custom-pagination";
import { getAllCookiesData, url } from '../../Utility/Utility';
import axios from 'axios';
import Loader from '../Utility/Loader';
const History = ({ path }) => {
    const { id, role } = useParams()
    const [historyList, setHistoryList] = useState([]);
    const [retailerHistoryList, setRetailerHistoryList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [userDrpList, setUserDrpList] = useState([])
    const [filter, setFilter] = useState([])
    useEffect(() => {
        if (role === "5") {
            getRetailerHistory()
        } else {
            getKeysHistory()
        }

    }, [id])

    const getKeysHistory = () => {
        axios.post(url + "/token/keys.php", { "id": id, "type": "keys-transfer-history" })
            .then(function (response) {
                setLoading(true)
                if (response?.data?.data) {
                    setHistoryList(response?.data?.data)
                    setFilter(response?.data?.data)
                }
                setLoading(false)
            }).catch(function (error) {
                setLoading(false)
            });
    }

    const getRetailerHistory = () => {
        axios.post(url + "/v1/device.php", { "RetailerID": id, "type": "get-device-info" })
            .then(function (response) {
                setLoading(true)
                if (response?.data?.data) {
                    setRetailerHistoryList(response?.data?.data)
                }
                setLoading(false)
            }).catch(function (error) {
                setLoading(false)
            });
    }

    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(12);
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = filter.slice(indexOfFirstPost, indexOfLastPost);
    const paginate = (number) => {
        setCurrentPage(number);
    };
    useEffect(() => {
        if (currentPosts.length > 0) {
            var dataID = document.getElementById("pagination-box")
            dataID.children[0].style.gap = "6px"
        }
    }, [currentPosts])

    useEffect(() => {
        getUserDrp()
    }, [])

    function getRoleForUserDrp() {
        if(role == 1){
            return 2
        }else if(getAllCookiesData()?.role == 1){
            return 2
        }else if(getAllCookiesData()?.role == 7){
            return 1
        }else{
            return role
        }
    }

    const getUserDrp = async () => {
        try {
            const userId = id
            var formdata = new FormData();
            formdata.append("role", getRoleForUserDrp());
            formdata.append("assign_id", userId === '107' ? 1 : userId);
            const res = await axios.post(url + '/user/getUser.php', formdata);
            if (res?.data?.status) setUserDrpList(res?.data.data)
        } catch (error) {console.log(error)}
    }

    const [searchVal, setSearchVal] = useState('')
    const handleSearch = (val) => {
        setSearchVal(val)
        if (val !== '') {
            const result = historyList.filter((item) => {
                return item.assignToName.toLowerCase().includes(val.toLowerCase())
            });
            setFilter(result)
        } else {
            setFilter(historyList)
        }
    }

    return (
        <>
            <div class="card">
                <div class="card-body">
                    <div className="row pb-2" style={{ borderBottom: '1px solid #ddd' }}>
                        <div className="col-12 col-md-8 d-flex" >
                            <h4 class="card-title">{path.substring(1)}</h4>
                        </div>
                        <div className="col-11 col-md-3">
                            <select value={searchVal} onChange={(e) => handleSearch(e.target.value)} className='form-control'>
                                <option value="">Search</option>
                                {
                                    userDrpList.map((data, i) => (
                                        <option value={data.name}>{data.username}</option>
                                    ))
                                }

                            </select>
                        </div>
                        {
                            searchVal !== '' ?
                                <div className="col-1 col-md-1 pt-2 px-0">
                                    <i className='fa fa-times' onClick={()=>{handleSearch('')}} style={{ fontSize: '20px', color: 'red', cursor: 'pointer' }} />
                                </div>
                                :
                                <></>
                        }
                    </div>
                    {
                        loading ?
                            <Loader />
                            :
                            <>
                                <div className="row p-3">
                                    {
                                        role === "5"
                                            ?
                                            retailerHistoryList.length > 0 ? retailerHistoryList?.map((data, i) =>
                                                <div className="col-lg-3">
                                                    <div className="card text-white bg-primary mb-3" key={i}>
                                                        <div className="card-header">{data.Date}</div>
                                                        <div className="card-body">
                                                            <p className="card-text">IMEI NO : {data.IMEINO}</p>
                                                            <p className="card-text">Fullname : {data.FullName}</p>
                                                            <p className="card-text">Email : {data.Email}</p>
                                                            <p className="card-text">SerialNo : {data.SerialNo}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                                :
                                                <>
                                                    <h5 className='ml-3'>No Transaction Yet</h5>
                                                </>
                                            :

                                            currentPosts.length > 0 ? currentPosts?.map((data, i) =>
                                                <div className="col-lg-3">
                                                    <div className="card text-white bg-primary mb-3" key={i}>
                                                        <div className="card-header">{data.created_date}</div>
                                                        <div className="card-body">
                                                            <p className="card-text">Keys : {data.keys_count}</p>
                                                            <p className="card-text">Keys Transfer By : {data.assignByName}</p>
                                                            <p className="card-text">Keys Transfer To : {data.assignToName}</p>
                                                        </div>
                                                    </div>
                                                </div>

                                            )

                                                :
                                                <>
                                                    <h5 className='ml-3'>No Transaction Yet</h5>
                                                </>


                                    }
                                </div>
                                {
                                    role !== "5"
                                        ?
                                        <div className="row pb-4 justify-content-end pr-3">
                                            <div className="col-12 col-sm-12 col-md-3 text-right " id='pagination-box' style={{ fontSize: '10px' }}>
                                                <Pagination
                                                    totalPosts={filter.length}
                                                    postsPerPage={postsPerPage}
                                                    paginate={paginate}
                                                    view={3}
                                                    showLast={true}
                                                    showFirst={true}
                                                    showIndex={true}
                                                    bgColor="#777"
                                                    color="white"
                                                    boxRadius="10%"
                                                    selectColor="#bd0001"
                                                    boxHeight="30px"
                                                    boxWidth="30px"
                                                    indexbgColor="#bd0001"
                                                    justify="right"
                                                />
                                            </div>
                                        </div>

                                        :
                                        <></>
                                }

                            </>

                    }
                </div>
            </div>
        </>
    )
}

export default History