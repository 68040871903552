import axios from 'axios';
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { Link, useNavigate } from 'react-router-dom';
import { deleteUser, toastifySuccess, url, toastifyError, RevertButtonPermission, currentMonthYear, DateMonthYear, currentDateMonthYear, encryptLocalStorageData, getAllCookiesData, encryptCookiesData, currentYearMonthDate, calenderWise, AuthRole, GetRoleText } from '../../../Utility/Utility';
import RevertKey from '../../Pages/Keys/RevertKey';
import AssigneDeleteModal from '../../Utility/AssigneDeleteModal';
import ConfirmModal from '../../Utility/ConfirmModal';
import { timeAgo } from '../../Utility/Date';
import { handleDownloadExcel } from '../../Utility/Export';
const List = ({ role, path }) => {
    const [list, setList] = useState([])
    const [filterData, setFilterData] = useState([])
    const [assigneeDrpList, setAssigneeDrpList] = useState([])
    const [id, setId] = useState()
    const [assignModal, setAssignModal] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [revertModal, setRevertModal] = useState(false)
    const [loginConfirmModal, setLoginConfirmModal] = useState(false)
    const authRole = getAllCookiesData()?.role;
    const navigate = useNavigate()
    const [loginDetails, setLoginDetails] = useState({
        'email': '',
        'password': ''
    })
    const [revetDetails, setRevertDetails] = useState({
        'id': '',
        'role': '',
        'keyBalance': ''
    })
    const [searchVal, setSearchVal] = useState({
        'serachText': '',
        'assignee': '',
        'keysRange': '',
        'dateRange': '',
        'date_from': '',
        'date_to': '',
    })
    useEffect(() => {
        getList()
        getAssigneeList()
    }, [])

    const getList = async () => {
        try {
            const userId = getAllCookiesData()?.id
            var formdata = new FormData();
            formdata.append("role", role);
            formdata.append("assign_id", userId === '107' ? 1 : userId);
            const res = await axios.post(url + '/user/getUser.php', formdata);
            if (res?.data?.status) {
                setList(res?.data?.data)
                setFilterData(res?.data?.data)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getAssigneeList = async () => {
        try {
            const userId = getAllCookiesData()?.id
            var formdata = new FormData();
            formdata.append("role", CheckAssignRole(role));
            formdata.append("assign_id", userId === '107' ? 1 : userId);
            const res = await axios.post(url + '/user/getUser.php', formdata);
            if (res?.data?.status) {
                setAssigneeDrpList(res?.data?.data)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const CheckAssignIn = (role) => {
        if (role === '1') {
            return 'Super Admin'
        }
        if (role === '2') {
            return 'Admin'
        }
        if (role === '3') {
            return 'National Distributor'
        }
        if (role === '4') {
            return 'Super Distributor'
        }
        if (role === '5') {
            return 'Distributor'
        }
    }

    const CheckAssignRole = (role) => {
        if (role === '1') {
            return '7'
        }
        if (role === '2') {
            return '1'
        }
        if (role === '3') {
            return '3'
        }
        if (role === '4') {
            return '3'
        }
        if (role === '5') {
            return '4'
        }
    }

    const columns = [
        {
            name: 'S.No',
            selector: (row, index) => <>
                <div className="spinner-grow spinner-grow-sm mr-2" style={{ background: row.is_login === '1' ? "#48AD01" : "red", height: '15px', width: '15px' }} role="status">
                    <span className="visually-hidden" />
                </div>
                <span>({index + 1})</span>
            </>
            ,
            sortable: true
        },
        {
            name: CheckAssignIn(role),
            selector: (row) => row.assignIn
        },
        {
            name: 'UserName',
            selector: (row) => <>{row.username}</>
        },
        {
            name: 'Name',
            selector: (row) => row.name
        },
        {
            name: 'Company Name',
            selector: (row) => row.company_name,
            sortable: true
        },
        {
            name: 'Mobile No',
            selector: (row) => row.mobile,
            sortable: true
        },
        {
            name: 'Key Balance ',
            selector: (row) => <> <span className='badge badge-primary'> <i className='fa fa-key'></i> {row.keyBalance}</span> </>,
            sortable: true
        },
        {
            name: 'Created At ',
            selector: (row) => timeAgo(row.created_at),
            sortable: true
        },
        {
            name: <p className='text-end'>Action</p>,
            cell: row =>
                <>
                    <Link to={`/history/${row.id}/${row.role}`} className="btn btn-primary text-white ml-2 p-2 mr-2">
                        <i class="fa fa-history" aria-hidden="true"></i>
                    </Link>

                    <Link to='' data-toggle="modal" data-target="#ConfirmModal" onClick={() => { setId(row.id); setDeleteModal(true) }} className="btn btn-danger text-white ml-2 p-2"><i class="ti-trash"></i></Link>

                    <li className="nav-item nav-profile list-three-dots" style={{ listStyleType: "none" }}>
                        <a className="nav-link dropdown-toggle" href="#" data-toggle="dropdown" id="profileDropdown">
                            <span className='d-inline-block pt-1'><i class="fa fa-ellipsis-v" aria-hidden="true" style={{ fontSize: '20px' }}></i></span>
                        </a>
                        <div className="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="profileDropdown">
                            {
                                role === "5" ?
                                    <></>
                                    :
                                    <Link className="dropdown-item" to="#" onClick={() => { setLoginConfirmModal(true); setLoginDetails({ ...loginDetails, "email": row.email, "password": row.password }) }}>
                                        Login
                                    </Link>

                            }

                            {
                                role === "1" ? <></> :
                                    <Link className="dropdown-item mt-2" to={`/add-${path.substring(1)}/${row.id}`}>
                                        Edit
                                    </Link>
                            }
                            {
                                authRole === "1" || authRole === "7" ?
                                    RevertButtonPermission() === row.role ?
                                        <Link className="dropdown-item mt-2" onClick={() => { setRevertModal(true); setRevertDetails({ ...revetDetails, "id": row.id, "role": row.role, "keyBalance": row.keyBalance }) }}>Revert Keys</Link>
                                        :
                                        <></>
                                    :
                                    <></>
                            }

                        </div>
                    </li>

                </>

        }
    ]

    const deleteData = () => {
        deleteUser(id)
            .then((data) => {
                if (data.status) {
                    toastifySuccess("Deleted Succesfully !!")
                    setDeleteModal(false)
                    getList()
                }
                if (data?.message === "You Assign  !!") {
                    setAssignModal(true)
                }
            });
    }
    const login = async (e) => {
        e.preventDefault()
        if (!localStorage.getItem("my-account")) {
            encryptLocalStorageData("my-account", getAllCookiesData()?.id, 'LoginAuthID')
        }
        try {
            var formdata = new FormData();
            formdata.append("email", loginDetails.email);
            formdata.append("password", loginDetails.password);

            const data = formdata;
            const result = await axios.post(url + "/auth/login.php", data)
            if (result?.data?.status) {
                toastifySuccess(result.data.msg)
                const data = {
                    "id": result.data.data.id,
                    'role': result.data.data.role,
                    'name': result.data.data.name,
                    'assigned_by': result.data.data.assigned_by,
                }
                encryptCookiesData("moonfox-secret", data, "MoonfoxCookiesKeys")
                navigate('/dashboard')
                window.location.reload()
            } else {
                toastifyError(result?.msg)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleSearch = e => {
        if (searchVal.serachText !== '') {
            const result = list.filter((item) => {
                return item.username.toLowerCase().includes(searchVal.serachText.toLowerCase()) || item.company_name.toLowerCase().includes(searchVal.serachText.toLowerCase());
            });
            setFilterData(result)
        }
        else if (searchVal.assignee !== '') {
            const result = list.filter((item) => {
                return item.assigned_by === searchVal.assignee
            });
            setFilterData(result)
        }
        else if (searchVal.dateRange === 'today') {
            const result = list.filter((item) => {
                return DateMonthYear(item.created_at).toLowerCase().includes(currentDateMonthYear().toLowerCase())
            });
            setFilterData(result)
        }
        else if (searchVal.dateRange === 'yesterday') {
            var prev_date = new Date();
            prev_date.setDate(prev_date.getDate() - 1);
            const result = list.filter((item) => {
                return DateMonthYear(item.created_at).toLowerCase().includes(DateMonthYear(prev_date).toLowerCase())
            });
            setFilterData(result)
        }
        else if (searchVal.dateRange === 'currentMonth') {
            const result = list.filter((item) => {
                return DateMonthYear(item.created_at).toLowerCase().includes(currentMonthYear().toLowerCase())
            });
            setFilterData(result)
        }
        else if (searchVal.keysRange !== '') {
            const result = list.filter((item) => {
                let listKeys = parseInt(item.keyBalance)
                let drpVal = parseInt(searchVal.keysRange)

                if (searchVal.keysRange === '0') {
                    return listKeys === drpVal
                } else if (searchVal.keysRange === '1to10') {
                    return listKeys >= 1 && listKeys <= 10
                } else if (searchVal.keysRange === '11to20') {
                    return listKeys >= 11 && listKeys <= 20
                } else if (searchVal.keysRange === '21to50') {
                    return listKeys >= 21 && listKeys <= 50
                } else if (searchVal.keysRange === '51to100') {
                    return listKeys >= 51 && listKeys <= 100
                } else if (searchVal.keysRange === '100') {
                    return listKeys > 100
                }
            });
            setFilterData(result)
        }
        else if (searchVal.date_from !== '' && searchVal.date_to !== '') {
            const result = list.filter((item) => {
                return DateMonthYear(item.created_at).toLowerCase() >= DateMonthYear(searchVal.date_from).toLowerCase() && DateMonthYear(item.created_at).toLowerCase() <= DateMonthYear(searchVal.date_to).toLowerCase()
            });
            setFilterData(result)
        } else if (searchVal.date_from !== '') {
            const result = list.filter((item) => {
                return DateMonthYear(item.created_at).toLowerCase().includes(DateMonthYear(searchVal.date_from).toLowerCase())
            });
            setFilterData(result)
        } else {
            setFilterData(list)
        }
    }
    const resetSearch = () => {
        setFilterData(list)
        setSearchVal({
            ...searchVal,
            'serachText': '',
            'assignee': '',
            'keysRange': '',
            'dateRange': '',
            'date_from': '',
            'date_to': '',
        })
    }

    const downloadExcel = () => {
        handleDownloadExcel(filterData, GetRoleText(role), GetRoleText(role))
    };

    return (
        <>
            <div className="col-12 pt-3" style={{ borderTop: '1px solid #ddd' }}>
                <div className="row">
                    <div className="col-12 col-sm-6 col-lg-3 mt-3">
                        <input type="text" value={searchVal.serachText} onChange={(e) => setSearchVal({ ...searchVal, 'serachText': e.target.value })} className='form-control form-control-sm' placeholder='Search by username or company name' />
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3 mt-3">
                        <select value={searchVal.assignee} onChange={(e) => setSearchVal({ ...searchVal, 'assignee': e.target.value })} className='form-control'>
                            <option value="">Select {CheckAssignIn(role)}</option>
                            {
                                assigneeDrpList?.map((val, i) => {
                                    return <>
                                        <option value={val.id}>{val.name} || {val.company_name}</option>
                                    </>
                                })
                            }
                        </select>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3 mt-3">
                        <select value={searchVal.dateRange} onChange={(e) => setSearchVal({ ...searchVal, 'dateRange': e.target.value })} className='form-control'>
                            <option value="">Select Date</option>
                            <option value="today">Today</option>
                            <option value="yesterday">Yesterday</option>
                            <option value="currentMonth">Current Month</option>
                            <option value="customeRange">Custome Date Range</option>
                        </select>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3 mt-3">
                        <select value={searchVal.keysRange} onChange={(e) => setSearchVal({ ...searchVal, 'keysRange': e.target.value })} className='form-control'>
                            <option value="">Select Keys Range</option>
                            <option value="0">{"0"}</option>
                            <option value="1to10">{"1 to 10"}</option>
                            <option value="11to20">{"11 to 20"}</option>
                            <option value="21to50">{"21 to 50"}</option>
                            <option value="51to100">{"51 to 100"}</option>
                            <option value="100">{"Greater than 100"}</option>
                        </select>
                    </div>

                    {
                        searchVal.dateRange === 'customeRange' ?
                            <>
                                <div className="col-12 col-sm-6 col-lg-3 mt-2">
                                    <span style={{ fontSize: '13px' }} >From Date</span>
                                    <input
                                        type="date"
                                        min={"2023-03-01"}
                                        max={currentYearMonthDate()}
                                        className='form-control form-control-sm'
                                        onChange={(e) => setSearchVal({ ...searchVal, "date_from": e.target.value })}
                                    />
                                </div>

                                <div className="col-12 col-sm-6 col-lg-3 mt-2">
                                    <span style={{ fontSize: '13px' }} > To Date</span>
                                    <input
                                        type="date"
                                        min={searchVal.date_from}
                                        max={calenderWise()}
                                        className='form-control form-control-sm'
                                        onChange={(e) => setSearchVal({ ...searchVal, "date_to": e.target.value })}
                                    />
                                </div>
                            </>
                            :
                            <></>
                    }
                </div>
                <div className="row py-3 px-0 mt-3 mx-0 justify-content-center" style={{ background: '#F5F7FF' }}>
                    <div className="col-lg-2 col-md-2 col-sm-3 col-4">
                        <input type="button" value={'Search'} onClick={(e) => handleSearch()} className='form-control form-control-sm bg-primary text-white' />
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-3 col-4">
                        <input type="button" value='Reset' onClick={() => { resetSearch() }} className='form-control form-control-sm bg-warning text-white' />
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-3 col-4">
                        <input type="button" value={'Export to Excel'} onClick={downloadExcel} className='form-control form-control-sm bg-primary text-white' />
                    </div>
                </div>
            </div>
            <div className="col-12">
                <DataTable
                    dense
                    columns={columns}
                    data={filterData}
                    pagination
                />
            </div>
            {
                deleteModal ?
                    <ConfirmModal func={deleteData} setDeleteModal={setDeleteModal} message={"Do you want to delete it ?"} />
                    :
                    <></>
            }

            {
                assignModal ?
                    <AssigneDeleteModal {...{ setAssignModal, id, list }} getList={getList} />
                    :
                    <></>
            }

            {
                loginConfirmModal ?
                    <ConfirmModal func={login} message="Are you sure ? you want to Login this user?" setDeleteModal={setLoginConfirmModal} />
                    :
                    <></>
            }

            {
                revertModal ?
                    <RevertKey setModalOpen={setRevertModal} revetDetails={revetDetails} getList={getList} />
                    :
                    <></>
            }
        </>
    )
}

export default List