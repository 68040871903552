import React from 'react'
import logo from '../../Image/logo.png'
import { Link } from 'react-router-dom'
import SocialIcon from '../Utility/SocialIcon'

const Footer = () => {
  const date = new Date()
  return (
    <>
      <footer id="footer">
        <div className="container">
          <div className="row pt-4">
            <div className="col-12 col-md-6 col-lg-3">
              <img src={logo} alt="logo" width={'50px'} style={{ borderRadius: "50%" }} className="img-fluid" />
              <span className='text-white ml-2'>MOONFOX</span>
              <p className='mt-3' style={{ color: '#bbb' }}>
                Our finance locker with multiple features provide best solutions to retailers
              </p>
              <div className="social-icon">
                <SocialIcon />
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3 quick-link">
              <h4 className='text-white'>Quick Link</h4>
              <div>
                <Link to={'/'}>HOME</Link>
                {/* <Link to={'/about'}>ABOUT</Link> */}
                <Link to={'/contact'}>CONTACT</Link>
                <Link to={'/policy'}>PRIVACY POLICY</Link>
                <Link to={'/our-app'}>OUR APPS</Link>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3 footer-contact-info">
              <h4 className='text-white'>Our Products</h4>

            </div>
            <div className="col-12 col-md-6 col-lg-3 footer-contact-info">
              <h4 className='text-white'>Contact Info</h4>
              <div>
                <span><i className='fa fa-envelope'></i> &nbsp; moonfox@moonfox.in</span>
                <span><i className='fa fa-phone'></i>&nbsp; +91 8104333322</span>
              </div>
            </div>

            <div className="col-12 pt-5 pb-4 text-white text-center">
              &copy; {date.getFullYear()} Copyright: <strong><span>YAMOTO TPSA TECHNOLOGY PRIVATE LIMITED || Developed By <Link to={'https://arustu.com'} target='_blank'>Arustu Technology</Link> </span></strong>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer