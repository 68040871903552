import React from 'react'
import { useState } from 'react';
import { AuthRole, currentDateMonthYear, currentMonthYear, DateMonthYear, getAllCookiesData, MonthYear, url } from '../../Utility/Utility';
import peapleImg from '../Img/people.svg'
import AddKeys from './Keys/AddKeys';
import { useEffect } from 'react';
import DashboardBox from '../Utility/DashboardBox';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Loader from '../Utility/Loader';
import img from '../../Image/productimg1.png'
import rightHand from '../../Image/right-hand.png'
const Dashboard = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate()
  const [notAssign, setNotAssign] = useState([])
  const [callModal, setCallModal] = useState('')
  const [modalOpen, setModalOpen] = useState(false)

  const [userCount, setUserCount] = useState({
    "totalDeviceCount": '',
    "todayDeviceCount": '',
    "thisMonthDeviceCount": '',
    "totalRetailer": '',
    "todayRetailer": '',
    "thisMonthRetailer": '',
    "totalDistributor": '',
    "todayDistributor": '',
    "thisMonthDistributor": '',
    "totalSuperDistributor": '',
    "todaySuperDistributor": '',
    "thisMonthSuperDistributor": '',
    "totalNational": '',
    "todayNational": '',
    "thisMonthNational": '',
    "totalAdmin": '',
    "totalSales": '',
    "todaySales": '',
    "thisMonthSales": '',
  })
  const [customModal, setCustomModal] = useState(false)

  useEffect(() => {
    getKey()
    getKeysHistory()
    getDeviceList()
    getAllUsersInfo()
    getSalesList()
  }, [])

  const getKey = () => {
    var formdata = new FormData();
    formdata.append("role", getAllCookiesData()?.role);
    formdata.append("id", getAllCookiesData()?.id);

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };
    fetch(url + "/token/get-keys.php", requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status) {
          setLoading(true)
          const newArray = result?.data.map(function (row) {
            return row.Message === 'Transfer' ? row : ''
          })
          const newArray1 = result?.data.map(function (row) {
            return row.Message === 'Not Transfer' ? row : ''
          })
          var notTarnsfer = newArray1.filter(value => Object.keys(value).length !== 0);
          setNotAssign(notTarnsfer)
        }
        setLoading(false)
      })
      .catch(error => { console.log('error', error); setLoading(false) });
  }

  const [keysHisotory, setKeysHistory] = useState({
    'transferTime': '',
    'keysCount': '',
    'lastTransactionHistory': '',
  })
  const getKeysHistory = async () => {
    try {
      const res = await axios.post(url + "/token/keys.php", { "id": getAllCookiesData()?.id, "type": "keys-transfer-history" });
      if (res?.data?.status) {
        const data = res.data.data;
        var keysCount = 0;
        for (let i = 0; i < data.length; i++) {
          const element = data[i];
          keysCount += parseInt(element.keys_count);
        }

        setKeysHistory({
          ...keysHisotory,
          "transferTime": res?.data?.data?.length, "keysCount": keysCount, "lastTransactionHistory": data[0].created_date
        })
      }
    } catch (error) {
      console.error(error);
    }

  }
  let today = new Date().toISOString().slice(0, 10)

  const getDeviceList = async () => {
    const data = {
      "type": "get-all-device-info"
    }
    try {
      const res = await axios.post(url + "/v1/device.php", data);
      if (res.data.status) {
        if (AuthRole() === "distributor") {
          const total = res?.data?.data.filter((item) => {
            return item.distributor_id.toLowerCase() === getAllCookiesData()?.id.toLowerCase() ? item : ''
          });
          setUserCount(prevValues => { return { ...prevValues, ['totalDeviceCount']: total.length } })

          const today = res?.data?.data.filter((item) => {
            return item.distributor_id.toLowerCase() === getAllCookiesData()?.id.toLowerCase() ? item.Date.toLowerCase().includes(currentDateMonthYear().toLowerCase()) : ""
          });
          setUserCount(prevValues => { return { ...prevValues, ['todayDeviceCount']: today.length } })
          if (today.length > 0) setCustomModal(true)

          const thisMonth = res?.data?.data.filter((item) => {
            return item.distributor_id.toLowerCase() === getAllCookiesData()?.id.toLowerCase() ? item.Date.toLowerCase().includes(currentMonthYear().toLowerCase()) : ""
          });
          setUserCount(prevValues => { return { ...prevValues, ['thisMonthDeviceCount']: thisMonth.length } })

          return false
        }

        const total = res?.data?.data.filter((item) => {
          return item
        });
        setUserCount(prevValues => { return { ...prevValues, ['totalDeviceCount']: total.length } })

        const today = res?.data?.data.filter((item) => {
          return item.Date.toLowerCase().includes(currentDateMonthYear().toLowerCase())
        });
        setUserCount(prevValues => { return { ...prevValues, ['todayDeviceCount']: today.length } })
        if (today.length > 0) setCustomModal(true)

        const thisMonth = res?.data?.data.filter((item) => {
          return item.Date.toLowerCase().includes(currentMonthYear().toLowerCase())
        });
        setUserCount(prevValues => { return { ...prevValues, ['thisMonthDeviceCount']: thisMonth.length } })

      }
    } catch (error) {
      console.log(error)
    }
  }


  const getAllUsersInfo = async () => {
    try {
      const data = {
        "type": "get-all-users-info"
      }
      const res = await axios.post(url + "/v1/device.php", data);
      if (res?.data?.status) {
        const userList = res?.data?.data;
        userList?.map((data) => {
          if (data.role === "5") {

            if (AuthRole() === "distributor") {
              const total = userList.filter((item) => {
                return item.role === "5" && item.assigned_by === getAllCookiesData()?.id ? item : ""
              });
              setUserCount(prevValues => { return { ...prevValues, ['totalRetailer']: total.length } })

              const today = userList.filter((item) => {
                return item.role === "5" && item.assigned_by === getAllCookiesData()?.id ? DateMonthYear(item.created_at).toLowerCase().includes(currentDateMonthYear().toLowerCase()) : ""
              });
              setUserCount(prevValues => { return { ...prevValues, ['todayRetailer']: today.length } })

              const thisMonth = userList.filter((item) => {
                return item.role === "5" && item.assigned_by === getAllCookiesData()?.id ? DateMonthYear(item.created_at).toLowerCase().includes(currentMonthYear().toLowerCase()) : ""
              });
              setUserCount(prevValues => { return { ...prevValues, ['thisMonthRetailer']: thisMonth.length } })

              return false
            }

            const total = userList.filter((item) => {
              return item.role === "5" ? item : ""
            });
            setUserCount(prevValues => { return { ...prevValues, ['totalRetailer']: total.length } })

            const today = userList.filter((item) => {
              return item.role === "5" ? DateMonthYear(item.created_at).toLowerCase().includes(currentDateMonthYear().toLowerCase()) : ""
            });
            setUserCount(prevValues => { return { ...prevValues, ['todayRetailer']: today.length } })

            const thisMonth = userList.filter((item) => {
              return item.role === "5" ? DateMonthYear(item.created_at).toLowerCase().includes(currentMonthYear().toLowerCase()) : ""
            });
            setUserCount(prevValues => { return { ...prevValues, ['thisMonthRetailer']: thisMonth.length } })
          }


          if (data.role === "4") {
            const total = userList.filter((item) => {
              return item.role === "4" ? item : ""
            });
            setUserCount(prevValues => { return { ...prevValues, ['totalDistributor']: total.length } })

            const today = userList.filter((item) => {
              return item.role === "4" ? DateMonthYear(item.created_at).toLowerCase().includes(currentDateMonthYear().toLowerCase()) : ""
            });
            setUserCount(prevValues => { return { ...prevValues, ['todayDistributor']: today.length } })

            const thisMonth = userList.filter((item) => {
              return item.role === "4" ? DateMonthYear(item.created_at).toLowerCase().includes(currentMonthYear().toLowerCase()) : ""
            });
            setUserCount(prevValues => { return { ...prevValues, ['thisMonthDistributor']: thisMonth.length } })
          }

          if (data.role === "3") {
            const total = userList.filter((item) => {
              return item.role === "3" ? item : ""
            });
            setUserCount(prevValues => { return { ...prevValues, ['totalSuperDistributor']: total.length } })
            const today = userList.filter((item) => {
              return item.role === "3" ? DateMonthYear(item.created_at).toLowerCase().includes(currentDateMonthYear().toLowerCase()) : ""
            });
            setUserCount(prevValues => { return { ...prevValues, ['todaySuperDistributor']: today.length } })

            const thisMonth = userList.filter((item) => {
              return item.role === "3" ? DateMonthYear(item.created_at).toLowerCase().includes(currentMonthYear().toLowerCase()) : ""
            });
            setUserCount(prevValues => { return { ...prevValues, ['thisMonthSuperDistributor']: thisMonth.length } })
          }

          if (data.role === "2") {
            const total = userList.filter((item) => {
              return item.role === "2" ? item : ""
            });
            setUserCount(prevValues => { return { ...prevValues, ['totalNational']: total.length } })
            const today = userList.filter((item) => {
              return item.role === "2" ? DateMonthYear(item.created_at).toLowerCase().includes(currentDateMonthYear().toLowerCase()) : ""
            });
            setUserCount(prevValues => { return { ...prevValues, ['todayNational']: today.length } })

            const thisMonth = userList.filter((item) => {
              return item.role === "2" ? DateMonthYear(item.created_at).toLowerCase().includes(currentMonthYear().toLowerCase()) : ""
            });
            setUserCount(prevValues => { return { ...prevValues, ['thisMonthNational']: thisMonth.length } })
          }

          if (data.role === "1") {
            const total = userList.filter((item) => {
              return item.role === "1" ? item : ""
            });
            setUserCount(prevValues => { return { ...prevValues, ['totalAdmin']: total.length } })
          }

        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getSalesList = async () => {

    var formdata = new FormData();
    formdata.append("type", "get");
    try {
      const res = await axios.post(url + "/sales/sales.php", formdata);
      if (res.data.status) {
        const total = res?.data?.data.filter((item) => {
          return item
        });
        setUserCount(prevValues => { return { ...prevValues, ['totalSales']: total.length } })

        const today = res?.data?.data.filter((item) => {
          return DateMonthYear(item.created_at).toLowerCase().includes(currentDateMonthYear().toLowerCase())
        });
        setUserCount(prevValues => { return { ...prevValues, ['todaySales']: today.length } })

        const thisMonth = res?.data?.data.filter((item) => {
          return MonthYear(item.created_at).toLowerCase().includes(currentMonthYear().toLowerCase())
        });
        setUserCount(prevValues => { return { ...prevValues, ['thisMonthSales']: thisMonth.length } })

      }
    } catch (error) {
      console.log(error)
    }
  }

  return (

    <>
      {
        loading ?
          <>  <Loader /> </>
          :
          <>
            <div className="row py-1 px-0 mx-0" style={{ background: '#F5F7FF' }}>
              <div className="col-12 col-md-3">
                <h3 className="font-weight-bold">Welcome {getAllCookiesData()?.name}</h3>
              </div>
              <div className="col-12 col-md-6">
                {
                  getAllCookiesData()?.role === '7' || getAllCookiesData()?.role === '1' || getAllCookiesData()?.role === '4' ?
                    customModal ?
                      <marquee className="css2" direction="left" scrolldelay="100" scrollamount="18">
                        <div className='d-flex align-items-center'>
                          <img src={rightHand} className='d-block mr-2' style={{ height: '20px' }} />
                          <h4 className="modal-title" style={{ fontWeight: 'bold', textTransform: 'capitalize', color: '#bd0001' }}>Hey {getAllCookiesData()?.name} Today {userCount.todayDeviceCount} Device <img src={img} style={{ height: '30px' }} /> Added !!</h4>
                        </div>
                      </marquee>
                      :
                      <></>
                    :
                    <></>
                }
              </div>

            </div>
            <div className="row">
              <div className="col-md-6 grid-margin stretch-card">
                <div className="card tale-bg">
                  <div className="card-people mt-auto">
                    <img src={peapleImg} alt="people" />
                    <div className="weather-info">
                      <div className="d-flex">
                        <div className="ml-2">
                          <h4 className="location font-weight-normal">{today}</h4>
                          <h6 className="font-weight-normal">India</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 grid-margin transparent">
                <div className="row">

                  {/* ----- Keys Pirchase And Key Transfer---- */}
                  <div className="col-md-5 mb-4 stretch-card transparent">
                    <div className="card card-tale">
                      <div className="card-body">
                        <p className="mb-4">Available Key Balance</p>
                        <p className="fs-30 mb-0">{notAssign.length}</p>
                        {
                          getAllCookiesData()?.role === "7" ?
                            <button className='btn btn-sm btn-danger mr-2 mt-2' onClick={() => { setCallModal('add'); setModalOpen(true) }}>Purchase Key</button>
                            :
                            <></>
                        }
                        <button className='btn btn-sm btn-primary mt-2' onClick={() => { setCallModal('assign'); setModalOpen(true) }}>Transfer Key</button>
                      </div>
                    </div>
                  </div>

                  {/* ----- Keys History---- */}
                  <div className="col-md-7 mb-4 stretch-card transparent">
                    <div className="card card-dark-blue" onClick={() => navigate(`/history/${getAllCookiesData()?.id}/0`)}>
                      <div className="card-body">
                        <p className="mb-4">Keys Transaction History</p>
                        <div className="row">
                          <div className="col-12 col-lg-6">
                            <p className="fs-20 mb-2">Transfer Keys Times -- {keysHisotory.transferTime}</p>
                          </div>
                          <div className="col-12 col-lg-6">
                            <p>Transfer Keys -- {keysHisotory.keysCount}</p>
                          </div>
                        </div>
                        <p>Last Transaction Date -- {keysHisotory.lastTransactionHistory}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {
              <div className="row">
                <>
                  {
                    getAllCookiesData()?.role === '7' ?
                      <DashboardBox Count={userCount.totalAdmin} Name={"Admin"} path={"/admin"} />
                      :
                      <> </>
                  }

                  {
                    getAllCookiesData()?.role === '7' || getAllCookiesData()?.role === "1" ?
                      <DashboardBox Count={userCount.totalNational} Name={"National Distributor"} path={"/national-distributor"} todayCount={userCount.todayNational} thisMonth={userCount.thisMonthNational} />
                      :
                      <> </>
                  }
                  {
                    getAllCookiesData()?.role === '7' || getAllCookiesData()?.role === "1" ?
                      <DashboardBox Count={userCount.totalSuperDistributor} Name={"Super Distributor"} path={"/super-distributor"} todayCount={userCount.todaySuperDistributor} thisMonth={userCount.thisMonthSuperDistributor} />
                      :
                      <> </>
                  }
                  {
                    getAllCookiesData()?.role === '7' || getAllCookiesData()?.role === "1" ?
                      <DashboardBox Count={userCount.totalDistributor} Name={"Distributor"} path={"/distributor"} todayCount={userCount.todayDistributor} thisMonth={userCount.thisMonthDistributor} />
                      :
                      <> </>
                  }
                  {
                    getAllCookiesData()?.role === '7' || getAllCookiesData()?.role === "1" || getAllCookiesData()?.role === "4" ?
                      <DashboardBox Count={userCount.totalRetailer} Name={"Retailer"} path={"/retailer"} todayCount={userCount.todayRetailer} thisMonth={userCount.thisMonthRetailer} />
                      :
                      <> </>
                  }
                  {
                    getAllCookiesData()?.role === '7' || getAllCookiesData()?.role === "1" ?
                      <DashboardBox Count={userCount.totalSales} todayCount={userCount.todaySales} thisMonth={userCount.thisMonthSales} Name={"Sales"} path={"/sales"} />
                      :
                      <> </>
                  }
                  {
                    getAllCookiesData()?.role === '7' || getAllCookiesData()?.role === "1" || getAllCookiesData()?.role === "4" ?
                      <DashboardBox Count={userCount.totalDeviceCount} Name={"Device"} path={"/device"} todayCount={userCount.todayDeviceCount} thisMonth={userCount.thisMonthDeviceCount} />
                      :
                      <> </>
                  }
                </>
              </div>
            }
            {
              modalOpen ?
                <AddKeys getKey={getKey} getKeysHistory={getKeysHistory} callModal={callModal} setModalOpen={setModalOpen} notAssign={notAssign} />
                :
                <></>
            }
          </>
      }

    </>

  )
}

export default Dashboard