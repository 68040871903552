import axios from "axios";
import Cookies from "js-cookie";
import moment from "moment";
import { toast } from "react-toastify";
var CryptoJS = require("crypto-js");

export const url = process.env.REACT_APP_URL
// export const url = "http://localhost/MOONFOX-WEB-AND-API"

export const AuthRole = () => {
  const role = getAllCookiesData()?.role
  if (role === '1') {
    return 'admin'
  }
  if (role === '2') {
    return 'national distributor'
  }
  if (role === '3') {
    return 'super distributor'
  }
  if (role === '4') {
    return 'distributor'
  }
  if (role === '5') {
    return 'retaller'
  }
  if (role === '7') {
    return 'super admin'
  }
}
export const GetRoleText = (role) => {
  if (role === '1') {
    return 'admin'
  }
  if (role === '2') {
    return 'national-distributor'
  }
  if (role === '3') {
    return 'super-distributor'
  }
  if (role === '4') {
    return 'distributor'
  }
  if (role === '5') {
    return 'retaller'
  }
  if (role === '7') {
    return 'super-admin'
  }
}
export const GetAssigneRole = (role) => {
  if (role === '6') {
    return '5'
  }
  if (role === '5') {
    return '4'
  }
  if (role === '4') {
    return '3'
  }
  if (role === '3') {
    return '2'
  }
  if (role === '2') {
    return '1'
  }
  
}

export const getSalesPost = (role) => {
  if (role === '1') {
    return 'business head'
  }
  if (role === '2') {
    return 'zonal sales manager'
  }
  if (role === '3') {
    return 'state head'
  }
  if (role === '4') {
    return 'AREA SALES MANAGER'
  }
  if (role === '5') {
    return 'TSM (Territory Sales Manager)'
  }
  if (role === '6') {
    return 'TL ( Team Leader)'
  }
}

export const AssignRole = () => {
  const role = getAllCookiesData()?.role

  if (role === '1') {
    return 'National distributor'
  }
  if (role === '2') {
    return 'Super distributor'
  }
  if (role === '3') {
    return 'Distributor'
  }
  if (role === '4') {
    return 'Retaller'
  }
  if (role === '7') {
    return 'admin'
  }
}

export const RevertButtonPermission = () => {
  const role = getAllCookiesData()?.role
  if (role === '1') {
    return '2'
  }
  if (role === '2') {
    return '3'
  }
  if (role === '3') {
    return '4'
  }
  if (role === '4') {
    return '5'
  }
  if (role === '7') {
    return '1'
  }
}

export const toastifySuccess = (message) => {
  toast.success(`${message}`, {
    position: "top-right",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });
}

export const toastifyError = (message) => {
  toast.error(`${message}`, {
    position: "top-right",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });
}

export const getUserData = async (role) => {
  const userId = getAllCookiesData()?.id
  var formdata = new FormData();
  formdata.append("role", role);
  formdata.append("assign_id", userId === '107' ? 1 : userId);

  var requestOptions = {
    method: 'POST',
    body: formdata,
    redirect: 'follow'
  };
  const apiUrl = url + '/user/getUser.php'
  let response = await fetch(apiUrl, requestOptions)
  response = await response.json()

  return response;
}
export const deleteUser = async (id) => {
  const deletedBy = getAllCookiesData()?.id
  var formdata = new FormData();
  formdata.append("id", id);
  formdata.append("deletedBy", deletedBy);
  formdata.append("deleted_at", new Date());

  var requestOptions = {
    method: 'POST',
    body: formdata,
    redirect: 'follow'
  };

  const apiUrl = url + '/user/deleteUser.php'
  let response = await fetch(apiUrl, requestOptions)
  response = await response.json()

  return response;
}

export const DateMonthYear = (dateStr) => {
  return moment(dateStr).format("DD-MM-YY")
}

export const currentDateMonthYear = () => {
  return moment(new Date()).format('DD-MM-YY');
}
export const currentMonthYear = () => {
  return moment(new Date()).format('MM-YY');
}
export const MonthYear = (dateStr) => {
  return moment(dateStr).format("MM-YY")
}
export const getDateTime = (dateStr) => {
  return moment(dateStr).format("DD-MM-yyyy HH:mm")
}

export const currentYearMonthDate = () => {
  return moment(new Date()).format('yyyy-MM-DD');
}

export const calenderWise = () => {
  return moment(new Date()).format('YYYY-MM-DD');
}


export const encryptLocalStorageData = (name,data,key) => {
  var encryptData = CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();
  localStorage.setItem(name, encryptData)
}

export const dycryptLocalStorageData = (encryptData,key) => {
  var bytes  = CryptoJS.AES.decrypt(encryptData, key);
  var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return decryptedData
}

export const encryptCookiesData = (name,data,key) => {
  var encryptData = CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();
  Cookies.set(name, encryptData)
}

export const dycryptCookiesData = (encryptData,key) => {
  var bytes  = CryptoJS.AES.decrypt(encryptData, key);
  var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return decryptedData
}


export const getAllCookiesData = () => {
  if(Cookies.get('moonfox-secret')) return dycryptCookiesData(Cookies.get('moonfox-secret'), "MoonfoxCookiesKeys")
  else{
    return null
  }
}

export const EncryptID = (text) => {
  var key = CryptoJS.enc.Utf8.parse("9z$C&F)J@NcRfTjW");
  var iv = CryptoJS.enc.Base64.parse("QUJDREVGR0g=");
  var encoded = CryptoJS.enc.Utf8.parse(text);
  var bytes = CryptoJS.TripleDES.encrypt(encoded, key, {
      mode: CryptoJS.mode.CBC,
      iv: iv,
  });
  return bytes.toString()
}

export const DecryptedID = (response) => {
  var key = CryptoJS.enc.Utf8.parse("9z$C&F)J@NcRfTjW");
  var iv = CryptoJS.enc.Base64.parse("QUJDREVGR0g=");
  var bytes = CryptoJS.TripleDES.decrypt(response, key, {
      mode: CryptoJS.mode.CBC,
      iv: iv,
  });
  return bytes.toString(CryptoJS.enc.Utf8);
}

export const LogoutCmp = async (id) => {
  const data = {"type" : "logout-user","id" : id}
  await axios.post(url + '/utility.php', data);
}