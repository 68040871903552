import axios from 'axios'
import Cookies from 'js-cookie'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { EncryptID, LogoutCmp, getAllCookiesData, url } from '../../Utility/Utility'
import logo from '../Img/logo.png'
import { timeAgo } from '../Utility/Date'

const Header = () => {
    const [contactList, setContactList] = useState([])
    const [isView, setIsView] = useState()

    useEffect(() => {
        getContact()
    }, [])

    const getContact = async () => {
        try {
            var formdata = new FormData();
            formdata.append("type", "get_contact");
            const res = await axios.post(url + "/support.php", formdata);
            if (res?.data?.status) {
                let contactList = res?.data?.data;
                const getIsView = contactList.filter((item) => {
                    return item.is_view.toLowerCase() === "0";
                });
                setIsView(getIsView)
                setContactList(contactList)
            }
            else setContactList()
        } catch (error) { console.log(error) }
    }

    const view = async () => {
        try {
            var formdata = new FormData();
            formdata.append("type", "view-contact");
            const res = await axios.post(url + "/support.php", formdata);
            if (res.data.status) getContact()
        } catch (error) { console.log(error) }
    }

    const Logout = (id) => {
        Cookies.remove('moonfox-secret');
        localStorage.removeItem("my-account")
        LogoutCmp(id)
    }

    return (
        <>
            <nav className="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
                <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
                    <Link className="navbar-brand brand-logo mr-5" to={'/dashboard'}><img src={logo} style={{ borderRadius: '50%', width: '50px', height: '50px' }} className="mr-2" alt="logo" /></Link>
                    <Link className="navbar-brand brand-logo-mini" to={'/dashboard'}><img src={logo} style={{ borderRadius: '50%' }} alt="logo" /></Link>
                </div>
                <div className="navbar-menu-wrapper d-flex align-items-center justify-content-end">
                    <button className="navbar-toggler navbar-toggler align-self-center" type="button" data-toggle="minimize">
                        <i className='fa fa-bars'></i>
                    </button>

                    <ul className="navbar-nav navbar-nav-right">
                        <li className="nav-item dropdown" onClick={() => view()} >
                            <a className="nav-link count-indicator dropdown-toggle" id="notificationDropdown" href="#" data-toggle="dropdown">
                                <i className="fa fa-bell mx-0" />
                                <span className={`count ${isView?.length > 0 ? "bg-success" : "bg-danger"}`}></span>
                                <p className='new-msg'>{isView?.length > 0 ? isView?.length + " New Message" : ""}</p>
                            </a>
                            <div className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list pb-0" style={{ height: "auto", maxHeight: "340px", overflowY: "scroll" }} aria-labelledby="notificationDropdown">
                                <p className="mb-0 font-weight-normal float-left dropdown-header">Notifications</p>
                                {
                                    contactList.map((data, i) => {
                                        return <>
                                            <Link className="dropdown-item preview-item" to={`/contact-message?id=${EncryptID(data.id)}`}>
                                                <div className="preview-thumbnail">
                                                    <div className="preview-icon bg-info">
                                                        <i className="ti-user mx-0" />
                                                    </div>
                                                </div>
                                                <div className="preview-item-content">
                                                    <h6 className="preview-subject font-weight-normal">{data.email}</h6>
                                                    <p className="font-weight-light small-text mb-0 text-muted">
                                                        {timeAgo(data.created_at)}
                                                    </p>
                                                </div>
                                            </Link>
                                        </>
                                    })
                                }
                            </div>
                        </li>

                        <li className="nav-item nav-profile dropdown">
                            <a className="nav-link dropdown-toggle" href="#" data-toggle="dropdown" id="profileDropdown">
                                {/* <i className='fa fa-user' style={{background: '#ddd', padding: '4px', borderRadius: '50%' }}></i> */}
                                <span className='d-inline-block pt-1'>{getAllCookiesData()?.name}</span>
                            </a>
                            <div className="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="profileDropdown">
                                <Link className="dropdown-item" to="/my-profile">
                                    <i className="fa fa-user text-primary" />
                                    My Profile
                                </Link>
                                <Link className="dropdown-item" to="/change-password">
                                    <i className="fa fa-lock text-primary" />
                                    Change Password
                                </Link>
                                <Link className="dropdown-item" to={`/history/${getAllCookiesData()?.id}/0`}>
                                    <i className="fa fa-history text-primary" />
                                    My Transaction History
                                </Link>
                                {
                                    getAllCookiesData()?.role === "7"
                                        ?
                                        <Link className="dropdown-item" to="/utility">
                                            <i className="fa fa-table text-primary" />
                                            Utility
                                        </Link>
                                        :
                                        <></>
                                }

                                {
                                    getAllCookiesData()?.role === "7" || getAllCookiesData()?.role === "1"
                                        ?
                                        <Link className="dropdown-item" to={`/message-to-retailer`}>
                                            <i className="fa fa-envelope text-primary" />
                                            Message To Retailer
                                        </Link>
                                        :
                                        <></>
                                }

                                <Link to="/login" className="dropdown-item" onClick={() => Logout(getAllCookiesData()?.id)}>
                                    <i className="fa fa-power-off text-primary" />
                                    Logout
                                </Link>
                            </div>
                        </li>
                    </ul>
                    <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas">
                        <i className='fa fa-bars'></i>
                    </button>
                </div>
            </nav>


        </>
    )
}

export default Header