import axios from 'axios';
import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { getSalesPost, toastifyError, toastifySuccess, url } from '../../../Utility/Utility';
import chooseImage from "../../Img/chhoseImage.png"
import AddSales from './AddSales';
import ConfirmModal from '../../Utility/ConfirmModal'
import { timeAgo } from '../../Utility/Date'
import Loader from '../../Utility/Loader'
const SalesList = ({ path }) => {
    const [salesList, setSalesList] = useState([])
    const [filterData, setFilterData] = useState([])
    const [modal, setModal] = useState(false)
    const [salesID, setSalesID] = useState()
    const [editVal, setEditVal] = useState({})
    const [singaleSales, setSingalSales] = useState()
    const [deleteModal, setDeleteModal] = useState(false)
    const [updateStatus, setUpdateStatus] = useState(false)
    const [salesPost, setSalesPost] = useState([])

    const [page, setPage] = useState(false)
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        getSalesList()
        getSalesPostList()
    }, [])

    useEffect(() => {
        getSingalSales()
    }, [salesID])

    const getSalesList = async () => {
        var formdata = new FormData();
        formdata.append("type", "get");
        try {
            const res = await axios.post(url + "/sales/sales.php", formdata);
            console.log(res.data.data)
            if (res.data.status) { setSalesList(res.data.data); setFilterData(res.data.data) } else setSalesList()
        } catch (error) {
            console.log(error)
        }
    }

    const getSingalSales = async () => {
        try {
            setLoading(true)
            var formdata = new FormData();
            formdata.append("id", salesID);
            formdata.append("type", "get-singal-sales");
            const res = await axios.post(url + "/sales/sales.php", formdata);
            if (res?.data?.status) {
                setSingalSales(res?.data?.data)
                setLoading(false)
            } else setSingalSales()
        } catch (error) {
            setLoading(true)
            console.log(error)
        }
    }

    const columns = [

        {
            name: 'Name',
            selector: (row) => row.name
        },
        {
            name: 'Mobile ',
            selector: (row) => row.mobile,
            sortable: true
        },
        {
            name: 'Email ',
            selector: (row) => row.email,
            sortable: true
        },
        {
            name: 'Aadhar Number ',
            selector: (row) => row.aadhar_number,
            sortable: true
        },
        {
            name: 'Pan Number ',
            selector: (row) => row.pen_number,
            sortable: true
        },

        {
            name: 'Profile',
            selector: (row) => <><div id='sales-list-column'>{row.role === "1" ? <span style={{ color: "#16db65" }}>business head</span> : row.role === "2" ? <span style={{ color: "#faa307" }}>zonal sales manager</span> : row.role === "3" ? <span style={{ color: "#00bbf9" }}>state head</span> : row.role === "4" ? <span style={{ color: "#c9184a" }}>AREA SALES MANAGER</span> : row.role === "5" ? <span style={{ color: "#6f1d1b" }}>TSM (Territory Sales Manager)</span> : row.role === "6" ? <span style={{ color: "#c1121f" }}>TL ( Team Leader)</span> : ''}</div></>,
            sortable: true
        },

        {
            name: 'Assign In',
            selector: (row) => <>{row.assigned_by ? row.assignee : ""}</>,
            sortable: true
        },

        {
            name: 'created date',
            selector: (row) => timeAgo(new Date(row.created_at)),
            sortable: true
        },
        {
            name: <p className='text-end'>Action</p>,
            cell: row => <>
                <Link to='' onClick={() => { setModal(true); setSalesID(row.id) }} className="btn btn-warning text-white m-0 p-2"><i class="ti-eye"></i></Link>

                <li className="nav-item nav-profile list-three-dots" style={{ listStyleType: "none" }}>
                    <a className="nav-link dropdown-toggle" href="#" data-toggle="dropdown" id="profileDropdown">
                        <span className='d-inline-block pt-1'><i class="fa fa-ellipsis-v" aria-hidden="true" style={{ fontSize: '20px' }}></i></span>
                    </a>
                    <div className="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="profileDropdown">
                        <Link className="dropdown-item mt-2" to={``} onClick={() => { salesUpdateAction(row); setUpdateStatus(true) }}><i class="ti-pencil"></i> Edit</Link>

                        <Link className="dropdown-item mt-2" onClick={() => { setSalesID(row.id); setDeleteModal(true); }}> <i class="ti-trash"></i> Delete</Link>
                    </div>
                </li>

            </>
        }
    ]

    const [documentStatus, setDocumentStatus] = useState({
        img1: false,
        img2: false,
        resume: false,
        cheque: false,
    })

    useEffect(() => {
        if (documentStatus.img1 === true || documentStatus.img2 === true || documentStatus.resume === true || documentStatus.cheque === true) {
            const inputFile = document.querySelector("#picture__input");
            const pictureImage = document.querySelector(".picture__image");
            const pictureImageTxt = "Choose an image";
            pictureImage.innerHTML = pictureImageTxt;

            const img = document.createElement("img");
            img.src = chooseImage;
            img.classList.add("picture__img");
            pictureImage.innerHTML = "";
            pictureImage.appendChild(img);
            inputFile.addEventListener("change", function (e) {
                const inputTarget = e.target;
                const file = inputTarget.files[0];

                if (file) {
                    const reader = new FileReader();
                    reader.addEventListener("load", function (e) {
                        const readerTarget = e.target;
                        const img = document.createElement("img");
                        img.src = readerTarget.result;
                        img.classList.add("picture__img");

                        pictureImage.innerHTML = "";
                        pictureImage.appendChild(img);
                    });
                    reader.readAsDataURL(file);
                } else {
                    pictureImage.innerHTML = pictureImageTxt;
                }
            });
        }
    }, [singaleSales, documentStatus.img1, documentStatus.img2, documentStatus.resume, documentStatus.cheque])

    const get_Image_File = async (e, docsType) => {
        try {
            let currentFileType = e.target.files[0].type;
            let checkPng = currentFileType.indexOf("png");
            let checkJpeg = currentFileType.indexOf("jpeg");
            let checkJpg = currentFileType.indexOf("jpg");
            if (checkPng !== -1 || checkJpeg !== -1 || checkJpg !== -1) {
                var formdata = new FormData();
                formdata.append("id", singaleSales.id);
                formdata.append("type", 'update-documnts');
                formdata.append("documentType", docsType);
                formdata.append("img1", e.target.files[0]);

                const res = await axios.post(url + "/sales/sales.php", formdata);
                if (res?.data?.status) {
                    toastifySuccess(res.data.message)
                    setDocumentStatus({ ...documentStatus, "img1": false, "img2": false, 'resume': false, 'cheque': false })
                    getSingalSales()
                } else {
                    setDocumentStatus({ ...documentStatus, "img1": false, "img2": false, 'resume': false, 'cheque': false })
                    getSingalSales()
                }
            } else {
                toastifyError("Error: Invalid image file!");
            }
        } catch (error) {
            console.log(error);
        }
    }

    const deleteSales = async () => {
        try {
            var formdata = new FormData();
            formdata.append("type", "delete-sales");
            formdata.append("id", salesID);
            const res = await axios.post(url + "/sales/sales.php", formdata)
            if (res.data.status) { toastifySuccess("Sales Team Deleted !!"); getSalesList(); setDeleteModal(false); } else toastifyError("Something Went Wrong");
        } catch (error) { console.log(error) }
    }

    const salesUpdateAction = (row) => {
        setEditVal(row); setPage(true)
    }

    const closeModal = () => {
        setModal(false)
        setDocumentStatus({ ...documentStatus, "img1": false, "img2": false, 'resume': false, 'cheque': false })
    }

    const closePage = () => {
        setPage(!page)
        setUpdateStatus(false)
        setEditVal({})
    }

    const [searchVal, setSearchVal] = useState({
        'serachText': '',
        'salesPost': '',
    })
    const handleSearch = e => {
        if (searchVal.serachText !== '') {
            const result = salesList.filter((item) => {
                return item.name.toLowerCase().includes(searchVal.serachText.toLowerCase()) || item.email.toLowerCase().includes(searchVal.serachText.toLowerCase()) || item.mobile.toLowerCase().includes(searchVal.serachText.toLowerCase()) || item.pen_number.toLowerCase().includes(searchVal.serachText.toLowerCase()) || item.aadhar_number.toLowerCase().includes(searchVal.serachText.toLowerCase());
            });
            setFilterData(result)
        } else if (searchVal.salesPost !== '') {
            const result = salesList.filter((item) => {
                return parseInt(item.role) === parseInt(searchVal.salesPost);
            });
            setFilterData(result)
        } else {
            setFilterData(salesList)
        }
    }
    const resetSearch = () => {
        setFilterData(salesList)
        setSearchVal({
            ...searchVal,
            'serachText': '',
            'salesPost': '',
        })
    }

    const getSalesPostList = async () => {
        try {
            const res = await axios(url + "/sales/get-sales-post.php");
            if (res?.data?.status) setSalesPost(res?.data?.data)
        } catch (error) { console.log(error) }
    }

    return (
        <>
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div className="col-12 d-flex" style={{ justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #ddd' }}>
                            <h4 class="card-title">{path.substring(1)}</h4>
                            <h4><button className='btn btn-sm btn-primary' onClick={() => { closePage() }}>{page ? "Close" : "ADD Sales"}</button></h4>
                        </div>

                        {
                            !page ?
                                <>
                                    <div className="col-12 pt-2">
                                        <div className="row">
                                            <div className="col-12 col-sm-6 col-lg-4 mt-3">
                                                <input type="text" value={searchVal.serachText} onChange={(e) => setSearchVal({ ...searchVal, 'serachText': e.target.value })} className='form-control form-control-sm' placeholder='Search by Name, Email, Phone, Aadhar No And Pen Card No ...' />
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-4 mt-3">
                                                <select name="salesPost" style={{ textTransform: "capitalize" }} value={searchVal.salesPost} className="form-control" onChange={(e) => setSearchVal({ ...searchVal, 'salesPost': e.target.value })} >
                                                    <option>Select Profile</option>
                                                    {
                                                        salesPost?.map((val, index) => (
                                                            <option value={val.id} key={index}>{val.sales_post}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="row py-3 px-0 mt-3 mx-0 justify-content-center" style={{ background: '#F5F7FF' }}>
                                            <div className="col-lg-2 col-md-2 col-sm-3 col-4">
                                                <input type="button" value={'Search'} onClick={(e) => handleSearch()} className='form-control form-control-sm bg-primary text-white' />
                                            </div>
                                            <div className="col-lg-2 col-md-2 col-sm-3 col-4">
                                                <input type="button" value='Reset' onClick={() => { resetSearch() }} className='form-control form-control-sm bg-warning text-white' />
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <DataTable
                                            dense
                                            columns={columns}
                                            data={filterData}
                                            pagination
                                            highlightOnHover
                                        />
                                    </div>
                                </>
                                :
                                <AddSales {...{ setPage, getSalesList, editVal, updateStatus, setUpdateStatus }} />

                        }
                    </div>
                </div>
            </div>

            {
                modal ?
                    <div className="c-modal">
                        <div className="c-modal-box" role="document" style={{ width: "600px" }}>
                            <div className="c-modal-body py-1">
                                <div className="text-center">
                                    <h4>Sales Details</h4>
                                </div>
                                {
                                    loading ?
                                        <>
                                            <Loader />
                                        </>
                                        :
                                        <>
                                            <div className="box" style={{ textTransform: "uppercase" }}>
                                                <h5 className="modal-title mt-2">Name : <span className='font-weight-bold'> {singaleSales?.name} </span></h5>
                                                <h5 className="modal-title mt-2">Email : <span className='font-weight-bold'>{singaleSales?.email} </span></h5>
                                                <h5 className="modal-title mt-2">Mobile No : <span className='font-weight-bold'>{singaleSales?.mobile} </span></h5>
                                                <h5 className="modal-title mt-2">address : <span className='font-weight-bold'>{singaleSales?.address} </span></h5>
                                                <h5 className="modal-title mt-2">aadhar number : <span className='font-weight-bold'>{singaleSales?.aadhar_number} </span></h5>
                                                <h5 className="modal-title mt-2">pen number : <span className='font-weight-bold'>{singaleSales?.pen_number} </span></h5>
                                                <h5 className="modal-title mt-2">Created Date : <span className='font-weight-bold'> {singaleSales?.created_at}</span></h5>
                                                <h5 className="modal-title mt-2">role : <span className='font-weight-bold'>{getSalesPost(singaleSales?.role)} </span></h5>
                                                <div className="row mt-2 mb-2">
                                                    <div className="col-12 text-center">
                                                        <p className='py-1 font-weight-bold' style={{ background: "#999" }}>Documents</p>
                                                    </div>
                                                    <div className="col-6 col-sm-6 col-lg-3">

                                                        {
                                                            documentStatus?.img1 ?
                                                                <>
                                                                    <div className="sales-img-box">
                                                                        <label className="picture" for='picture__input' tabIndex="0">
                                                                            <span className="picture__image"></span>
                                                                        </label>
                                                                        <input type="file" name="picture__input" onChange={(e) => get_Image_File(e, 'img1')} id="picture__input" />
                                                                        <i className='fa fa-times' onClick={() => setDocumentStatus({ ...documentStatus, "img1": false })}></i>
                                                                    </div>
                                                                </>
                                                                :
                                                                <>
                                                                    <div className="sales-img-box">
                                                                        <img src={url + "/upload/sales/" + singaleSales?.img1} alt="" style={{ width: '100%', height: "130px" }} />
                                                                        <i className='fa fa-edit' onClick={() => setDocumentStatus({ ...documentStatus, "img1": true })}></i>
                                                                        <p>Image 1</p>
                                                                    </div>
                                                                </>
                                                        }
                                                    </div>
                                                    <div className="col-6 col-sm-6 col-lg-3">
                                                        {
                                                            documentStatus?.img2 ?
                                                                <>
                                                                    <div className="sales-img-box">
                                                                        <label className="picture" for='picture__input' tabIndex="0">
                                                                            <span className="picture__image"></span>
                                                                        </label>
                                                                        <input type="file" name="picture__input" onChange={(e) => get_Image_File(e, 'img2')} id="picture__input" />
                                                                        <i className='fa fa-times' onClick={() => setDocumentStatus({ ...documentStatus, "img2": false })}></i>

                                                                    </div>
                                                                </>
                                                                :
                                                                <>
                                                                    <div className="sales-img-box">
                                                                        <img src={url + "/upload/sales/" + singaleSales?.img2} alt="" style={{ width: '100%', height: "130px" }} />
                                                                        <i className='fa fa-edit' onClick={() => setDocumentStatus({ ...documentStatus, "img2": true })}></i>
                                                                        <p>Image 2</p>
                                                                    </div>
                                                                </>
                                                        }
                                                    </div>
                                                    <div className="col-6 col-sm-6 col-lg-3">
                                                        {
                                                            documentStatus?.resume ?
                                                                <>
                                                                    <div className="sales-img-box">
                                                                        <label className="picture" for='picture__input' tabIndex="0">
                                                                            <span className="picture__image"></span>
                                                                        </label>
                                                                        <input type="file" name="picture__input" onChange={(e) => get_Image_File(e, 'resume')} id="picture__input" />
                                                                        <i className='fa fa-times' onClick={() => setDocumentStatus({ ...documentStatus, "resume": false })}></i>

                                                                    </div>
                                                                </>
                                                                :
                                                                <>
                                                                    <div className="sales-img-box">
                                                                        <img src={url + "/upload/sales/" + singaleSales?.resume} alt="" style={{ width: '100%', height: "130px" }} />
                                                                        <Link to={url + "/upload/sales/" + singaleSales?.resume} target="_blank"> <i className='fa fa-eye'></i> </Link>
                                                                        <i className='fa fa-edit' onClick={() => setDocumentStatus({ ...documentStatus, "resume": true })}></i>
                                                                        <p>Resume</p>
                                                                    </div>
                                                                </>
                                                        }
                                                    </div>
                                                    <div className="col-6 col-sm-6 col-lg-3">
                                                        {
                                                            documentStatus?.cheque ?
                                                                <>
                                                                    <div className="sales-img-box">
                                                                        <label className="picture" for='picture__input' tabIndex="0">
                                                                            <span className="picture__image"></span>
                                                                        </label>
                                                                        <input type="file" name="picture__input" onChange={(e) => get_Image_File(e, 'cencel_check')} id="picture__input" />
                                                                        <i className='fa fa-times' onClick={() => setDocumentStatus({ ...documentStatus, "cheque": false })}></i>
                                                                    </div>
                                                                </>
                                                                :
                                                                <>
                                                                    <div className="sales-img-box">
                                                                        <img src={url + "/upload/sales/" + singaleSales?.cencel_check} alt="" style={{ width: '100%', height: "130px" }} />
                                                                        <Link to={url + "/upload/sales/" + singaleSales?.cencel_check} target="_blank"> <i className='fa fa-eye'></i> </Link>
                                                                        <i className='fa fa-edit' onClick={() => setDocumentStatus({ ...documentStatus, "cheque": true })}></i>
                                                                        <p>cheque</p>
                                                                    </div>
                                                                </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </>

                                }

                                <div className="btn-box text-center mt-3">
                                    <button type="button" className="btn btn-sm btn-secondary ml-2 " onClick={() => closeModal()}> Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <></>
            }


            {
                deleteModal ?
                    <ConfirmModal func={deleteSales} setDeleteModal={setDeleteModal} message={"Do you want to delete it ?"} />
                    :
                    <></>
            }
        </>
    )
}

export default SalesList