import Cookies from 'js-cookie'
import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { LogoutCmp, dycryptLocalStorageData, encryptCookiesData, getAllCookiesData, toastifyError, toastifySuccess, url } from '../../Utility/Utility'
import AddKeys from '../Pages/Keys/AddKeys'
import ConfirmModal from '../Utility/ConfirmModal'
import axios from 'axios'
const Sidebar = ({ path }) => {
    const [deleteModal, setDeleteModal] = useState(false)
    const [notAssign, setNotAssign] = useState([])
    const [modalOpen, setModalOpen] = useState(false)
    const [callModal, setCallModal] = useState('')
    const [authLoginData, setAuthLoginData] = useState()
    const navigate = useNavigate()

    useEffect(()=>{
        if(localStorage.getItem("my-account")){
            setAuthLoginData(dycryptLocalStorageData(localStorage.getItem("my-account"), "LoginAuthID"))
        }
        getKey()
    },[localStorage.getItem("my-account")])

    const logout = () => {
        Cookies.remove('moonfox-secret');
        navigate('/login')
        toastifySuccess("Logout Successfully !!")
        setDeleteModal(false)
        
    }
    useEffect(() => {
        SidebarToggle()
    }, [path])
    const SidebarToggle = () => {
        var element = document.getElementById("sidebar");
        element.classList.remove("active");
    }
    const getKey = () => {
        var formdata = new FormData();
        formdata.append("role", getAllCookiesData()?.role);
        formdata.append("id", getAllCookiesData()?.id);

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };
        fetch(url + "/token/get-keys.php", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status) {
                    const newArray1 = result?.data.map(function (row) {
                        return row.Message === 'Not Transfer' ? row : ''
                    })
                    setModalOpen(false)
                    var notTarnsfer = newArray1.filter(value => Object.keys(value).length !== 0);
                    setNotAssign(notTarnsfer)
                }
            })
            .catch(error => console.log('error', error));
    }

    const backToMyAccount = async () => {
        try {
            var formdata = new FormData();
            formdata.append("id", authLoginData);
            const result = await axios.post(url + "/user/get-single-user.php", formdata)
            if (result?.data?.status) {
                localStorage.removeItem("my-account")
                var loginInfo = new FormData()
                loginInfo.append("email", result?.data?.data?.email);
                loginInfo.append("password", result?.data?.data?.password);

                const loginRes = await axios.post(url + "/auth/login.php", loginInfo)
                if (loginRes?.data?.status) {
                    const data = {
                        "id" : result.data.data.id,
                        'role': result.data.data.role,
                        'name': result.data.data.name,
                        'assigned_by': result.data.data.assigned_by,
                    }
                    encryptCookiesData("moonfox-secret", data, "MoonfoxCookiesKeys")
                    navigate('/dashboard')
                    window.location.reload()
                } else {
                    toastifyError(loginRes?.msg)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <nav className={`sidebar sidebar-offcanvas`} id="sidebar">
                <ul className="nav">

                    {/* ----- Dashboard -----*/}
                    <li className={`nav-item ${path === '/dashboard' ? 'active' : ''}`}>
                        <Link className={`nav-link`} to={'/dashboard'}>
                            <i class="icon-grid menu-icon"></i>
                            <span className="menu-title">Dashboard</span>
                        </Link>
                    </li>

                    {
                        getAllCookiesData()?.role === "7" ?
                            (
                                <>
                                    {/* ----- Admin -----*/}
                                    <li className={`nav-item ${path === '/admin' ? 'active' : ''}`}>
                                        <Link className={`nav-link`} to={'/admin'}>
                                            <i className="icon-paper menu-icon" />
                                            <span className="menu-title">Admin</span>
                                        </Link>
                                    </li>
                                </>
                            )
                            :
                            <></>
                    }

                    {
                        getAllCookiesData()?.role === "7" || getAllCookiesData()?.role === "1" ?
                            (
                                <>
                                    {/* ----- National Distributor ----- */}
                                    <li className={`nav-item`}>
                                        <a className="nav-link" data-toggle="collapse" href="#form-elements" aria-expanded="false" aria-controls="form-elements">
                                            <i className="icon-columns menu-icon" />
                                            <span className="menu-title">National Distributor</span>
                                            <i className="menu-arrow" />
                                        </a>
                                        <div className="collapse" id="form-elements">
                                            <ul className="nav flex-column sub-menu">
                                                <li className={`nav-item ${path === '/add-national-distributor' ? 'active' : ''}`}><Link className="nav-link" to={'/add-national-distributor/0'} >Add</Link></li>
                                                <li className={`nav-item ${path === '/national-distributor' ? 'active' : ''}`}><Link className="nav-link" to={'/national-distributor'}>List</Link></li>
                                            </ul>
                                        </div>
                                    </li>
                                </>
                            )
                            :
                            <></>
                    }
                    {
                        getAllCookiesData()?.role === "7" || getAllCookiesData()?.role === "1" || getAllCookiesData()?.role === "2" ?
                            (
                                <>
                                    {/* -----Super Distributor ----- */}
                                    <li className="nav-item">
                                        <a className="nav-link" data-toggle="collapse" href="#charts" aria-expanded="false" aria-controls="charts">
                                            <i className="icon-bar-graph menu-icon" />
                                            <span className="menu-title">Super Distributor</span>
                                            <i className="menu-arrow" />
                                        </a>
                                        <div className="collapse" id="charts">
                                            <ul className="nav flex-column sub-menu">
                                                <li className={`nav-item ${path === '/add-super-distributor' ? 'active' : ''}`}><Link className="nav-link" to={'/add-super-distributor/0'} >Add</Link></li>
                                                <li className={`nav-item ${path === '/super-distributor' ? 'active' : ''}`}><Link className="nav-link" to={'/super-distributor'}>List</Link></li>
                                            </ul>
                                        </div>
                                    </li>
                                </>
                            )
                            :
                            <></>
                    }
                    {
                        getAllCookiesData()?.role === "7" || getAllCookiesData()?.role === "1" || getAllCookiesData()?.role === "2" || getAllCookiesData()?.role === "3" ?
                            (
                                <>
                                    {/* ----- Distributor ----- */}
                                    <li className="nav-item">
                                        <a className="nav-link" data-toggle="collapse" href="#tables" aria-expanded="false" aria-controls="tables">
                                            <i className="icon-grid-2 menu-icon" />
                                            <span className="menu-title">Distributor</span>
                                            <i className="menu-arrow" />
                                        </a>
                                        <div className="collapse" id="tables">
                                            <ul className="nav flex-column sub-menu">
                                                <li className={`nav-item ${path === '/add-distributor' ? 'active' : ''}`}><Link className="nav-link" to={'/add-distributor/0'} >Add</Link></li>
                                                <li className={`nav-item ${path === '/distributor' ? 'active' : ''}`}><Link className="nav-link" to={'/distributor'}>List</Link></li>
                                            </ul>
                                        </div>
                                    </li>
                                </>
                            )
                            :
                            <></>
                    }
                    {
                        getAllCookiesData()?.role === "7" || getAllCookiesData()?.role === "1" || getAllCookiesData()?.role === "2" || getAllCookiesData()?.role === "3" || getAllCookiesData()?.role === "4" ?
                            (
                                <>
                                    {/* ----- Retailer ----- */}
                                    <li className="nav-item">
                                        <a className="nav-link" data-toggle="collapse" href="#icons" aria-expanded="false" aria-controls="icons">
                                            <i className="icon-contract menu-icon" />
                                            <span className="menu-title">Retailer</span>
                                            <i className="menu-arrow" />
                                        </a>
                                        <div className="collapse" id="icons">
                                            <ul className="nav flex-column sub-menu">
                                                <li className={`nav-item ${path === '/add-retailer' ? 'active' : ''}`}><Link className="nav-link" to={'/add-retailer/0'} >Add</Link></li>
                                                <li className={`nav-item ${path === '/retailer' ? 'active' : ''}`}><Link className="nav-link" to={'/retailer'}>List</Link></li>
                                            </ul>
                                        </div>
                                    </li>
                                </>
                            )
                            :
                            <></>
                    }
                    {
                        getAllCookiesData()?.role === "7" || getAllCookiesData()?.role === "1" || getAllCookiesData()?.role === "4" ?
                            (
                                <>
                                    {/* ----- Device ----- */}
                                    <li className={`nav-item ${path === '/device' ? 'active' : ''}`}>
                                        <Link className="nav-link" to={'/device'}>
                                            <i className="icon-paper menu-icon" />
                                            <span className="menu-title">Device</span>
                                        </Link>
                                    </li>
                                </>
                            )
                            :
                            <></>
                    }

                    {
                        getAllCookiesData()?.role === "7" || getAllCookiesData()?.role === "1" ?
                            (
                                <>
                                    {/* ----- Sales Team ----- */}
                                    {/* <li className="nav-item">
                                        <a className="nav-link" data-toggle="collapse" href="#error" aria-expanded="false" aria-controls="error">
                                            <i className="icon-ban menu-icon" />
                                            <span className="menu-title">Sales Team</span>
                                            <i className="menu-arrow" />
                                        </a>
                                        <div className="collapse" id="error">
                                            <ul className="nav flex-column sub-menu">
                                                <li className={`nav-item ${path === '/sales' ? 'active' : ''}`}><Link className="nav-link" to={'/add-sales'}>Add</Link></li>
                                                <li className={`nav-item ${path === '/sales' ? 'active' : ''}`}><Link className="nav-link" to={'/sales'}>List</Link></li>
                                            </ul>
                                        </div>
                                    </li> */}
                                    <li className={`nav-item ${path === '/sales' ? 'active' : ''}`}>
                                        <Link className="nav-link" to={'/sales'}>
                                            <i className="fa fa-users" />
                                            <span className="menu-title pl-3">Sales Team</span>
                                        </Link>
                                    </li>
                                </>
                            )
                            :
                            <></>
                    }
                    {
                        getAllCookiesData()?.role === "7" || getAllCookiesData()?.role === "1" ?
                            (
                                <>
                                    {/* ----- Change Password ----- */}
                                    <li className={`nav-item ${path === '/deleted-user-list' ? 'active' : ''}`}>
                                        <Link className="nav-link" to={'/deleted-user-list'}>
                                            <i className="fa fa-user" />
                                            <span className="menu-title pl-3">Deleted User</span>
                                        </Link>
                                    </li>
                                </>
                            )
                            :
                            <></>
                    }
                    {
                        getAllCookiesData()?.role === "7" ?
                            (
                                <>
                                    {/* ----- Change Password ----- */}
                                    <li className={`nav-item ${path === '/deleted-device-list' ? 'active' : ''}`}>
                                        <Link className="nav-link" to={'/deleted-device-list'}>
                                            <i className="fa fa-mobile" />
                                            <span className="menu-title pl-3">Deleted Device</span>
                                        </Link>
                                    </li>
                                </>
                            )
                            :
                            <></>
                    }

                    {/* ----- Change Password ----- */}
                    <li className={`nav-item ${path === '/change-password' ? 'active' : ''}`}>
                        <Link className="nav-link" to={'/change-password'}>
                            <i className="fa fa-lock" />
                            <span className="menu-title pl-3">Change Password</span>
                        </Link>
                    </li>

                    {/* ----- Transfer Points ----- */}
                    <li className={`nav-item`}>
                        <div className="nav-link" onClick={() => { setCallModal('assign'); setModalOpen(true) }}>
                            <i className="fa fa-key " style={{ color: '#666' }} />
                            <span className="menu-title pl-2">Transfer Points </span>
                            <span class="badge badge-primary" style={{ fontSize: '10px' }}><i className='fa fa-key'></i> {notAssign.length}</span>
                        </div>
                    </li>

                    {/* ----- Transaction History ----- */}
                    <li className={`nav-item ${path === '/history' ? 'active' : ''}`}>
                        <Link className="nav-link" to={`/history/${getAllCookiesData()?.id}/0`}>
                            <i className="fa fa-history" />
                            <span className="menu-title pl-3">Transaction History</span>
                        </Link>
                    </li>

                    {/* ----- Logout ----- */}
                    <li className="nav-item cp" onClick={() => { setDeleteModal(true); LogoutCmp(getAllCookiesData()?.id) }}>
                        <div className="nav-link" to={'/login'}>
                            <i className="fa fa-sign-out" />
                            <span className="menu-title pl-3">Logout</span>
                        </div>
                    </li>

                    {/* ----- Back To My Account ----- */}
                    {
                        authLoginData
                            ?
                            <li className="nav-item cp" onClick={() => { backToMyAccount() }}>
                                <div className="nav-link" to={'/login'}>
                                    <i className="fa fa-sign-in" />
                                    <span className="menu-title pl-3">Back To My Account</span>
                                </div>
                            </li>
                            :
                            <></>
                    }

                </ul>
            </nav>
            {
                deleteModal ?
                    <ConfirmModal func={logout} message="Are you sure ? you want to logout ?" setDeleteModal={setDeleteModal} />
                    :
                    <></>
            }

            {
                modalOpen ?
                    <AddKeys getKey={getKey} callModal={callModal} setModalOpen={setModalOpen} notAssign={notAssign} />
                    :
                    <></>
            }
        </>
    )
}

export default Sidebar