import React, { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { CommonContextData } from '../../Context/CommonContext'

const SocialIcon = () => {
    const { getDataUtility, utilityObj } = useContext(CommonContextData);
    useEffect(() => {getDataUtility()}, [])
    return (
        <>
            {
                utilityObj.facebook ?
                    <Link to={utilityObj.facebook} target='_blank'><i className='fab fa-facebook' /></Link>
                    :
                    <></>

            }
            {
                utilityObj.instagram ?
                    <Link to={utilityObj.instagram} target='_blank'><i className='fab fa-instagram' /></Link>
                    :
                    <></>
            }
            {
                utilityObj.twitter ?
                    <Link to={utilityObj.twitter} target='_blank'><i className='fab fa-twitter' /></Link>
                    :
                    <></>
            }
            {
                utilityObj.youtube ?
                    <Link to={utilityObj.youtube} target='_blank'><i className='fab fa-youtube' /></Link>
                    :
                    <></>
            }
             {
                utilityObj.website ?
                    <Link to={utilityObj.website} target='_blank'><i className='fa fa-globe' /></Link>
                    :
                    <></>
            }
             {
                utilityObj.watsapp ?
                    <Link to={utilityObj.watsapp} target='_blank'><i className='fab fa-whatsapp' /></Link>
                    :
                    <></>
            }
        </>
    )
}

export default SocialIcon