import React, { useEffect, useState, memo, useMemo } from 'react';
import { AssignRole, getAllCookiesData, getUserData, toastifyError, toastifySuccess, url } from '../../../Utility/Utility';

function AddKey({ getKey, getKeysHistory, callModal, notAssign, setModalOpen }) {

    const [assignByList, setAssignByList] = useState([])

    const [userDetails, setUserDetails] = useState({
        'keyNo': '', "assigned": '',
    })

    useEffect(() => {
        getAllCookiesData()?.role === "7" ?
            getAdminList()
            :
            getNationalDis();
    }, [])

    const reset = () => {
        setUserDetails({
            ...userDetails,
            'keyNo': '', "assigned": '',
        })
    }

    const getNationalDis = () => {
        var formdata = new FormData();
        formdata.append("role", "2");
        formdata.append("assign_id", getAllCookiesData()?.id);

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        fetch(url + "/user/getUser.php", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status) {
                    setAssignByList(result.data)
                } else {
                    setAssignByList([])
                }
            })
            .catch(error => console.log('error', error));
    }

    const getAdminList = () => {
        getUserData('1')
            .then((data) => {
                if (data.status) {
                    setAssignByList(data.data)
                } else {
                    setAssignByList([])
                }
            });
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setUserDetails({
            ...userDetails,
            [name]: value
        })
    }

    const Add_Key = () => {
        const { keyNo } = userDetails
        var formdata = new FormData();
        formdata.append("noOfToken", keyNo);

        if (keyNo === '') {
            toastifyError("Enter the no. of keys want to purchase")
            return false
        }
        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        fetch(url + "/token/create-keys.php", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status) {
                    toastifySuccess(keyNo + ' -> Generate Key')
                    getKey();
                    getKeysHistory()
                    reset()
                    setModalOpen(false)
                } else {
                    toastifyError(' key Not Add !!')
                }
            })
            .catch(error => console.log('error', error));
    }
    const assignKey = () => {
        const { assigned, keyNo } = userDetails
        var numberRegex = /^\d+$/;

        
        if (keyNo <= 0) {
            toastifyError("Enter the no. of keys want to allocate !!")
            return false
        }
        
        if(!numberRegex.test(keyNo)){
            toastifyError("Enter The Valid No Of Keys (Only Accept Digits) !!")
            return false
        }
        if (keyNo > notAssign?.length) {
            toastifyError("You don't have sufficient key !!")
            return false
        }

        if (assigned.length <= 0) {
            toastifyError("Select Assignee !!")
            return false
        }

        var updatedList = [];
        for (let i = 0; i < keyNo; i++) {
            const element = notAssign[i].id;
            updatedList.push(element)
        }
        assignKeyApi(assigned, updatedList)
    }
    const assignKeyApi = (assigned, checked) => {
        const assign_by = getAllCookiesData()?.id
        var formdata = new FormData();
        formdata.append("assign_to", assigned);
        formdata.append("assign_by", assign_by);
        formdata.append("keys", checked);

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };
        fetch(url + "/token/assign-keys.php", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status) {
                    toastifySuccess('keys Assigned Succesffuly !!')
                    getKey();
                    getKeysHistory()
                    setModalOpen(false)
                    setUserDetails({ ...userDetails, assigned: '' })
                    reset()
                } else {
                    toastifyError('keys Not Assigned !!')
                }
            })
            .catch(error => {console.log('error', error); setModalOpen(false)});
    }
    return (
        <>
            <div class="c-modal" >
                <div className="c-modal-box">
                    <div className="c-modal-body">
                        <div className="modal-header">
                            <h4 className="sub-title">
                                {callModal === 'assign' ? "Assign Key" : " Generate Key"}
                            </h4>
                        </div>
                        <div className="form-group row pt-4">

                            {
                                callModal === 'assign' ?
                                    (
                                        <>
                                            <label className="col-sm-1 pt-2">Keys</label>
                                            <div className="col-sm-3" id='keyNoFocus'>
                                                <input type="text" maxLength={3} name='keyNo' value={userDetails.checkedVal} className="form-control form-control-sm" onChange={handleChange} />
                                            </div>
                                            <label class="col-sm-4 pt-2">
                                                Select {AssignRole()}
                                            </label>
                                            <div class="col-sm-4">
                                                <select name="assigned" className="form-control" onChange={handleChange} >
                                                    <option>Select</option>
                                                    {
                                                        assignByList?.map((val, index) => (
                                                            <option value={val.id} >{val.username} || {val.mobile} || {val.company_name}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </>
                                    )
                                    :
                                    (
                                        <>
                                            <label className="col-sm-3 col-form-label">No. of Keys  </label>
                                            <div className="col-sm-3" id='keyNoFocus'>
                                                <input type="text" name='keyNo' maxLength={3} className="form-control" onChange={handleChange} />
                                            </div>
                                        </>
                                    )
                            }
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={() => { reset(); setModalOpen(false) }}>Close</button>
                            {
                                callModal === 'assign' ?
                                    (
                                        <button type="button" className="btn btn-primary" onClick={assignKey}>Assign</button>
                                    )
                                    :
                                    (
                                        <button type="button" className="btn btn-primary" onClick={Add_Key}>Purchase</button>
                                    )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default memo(AddKey)