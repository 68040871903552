import React from 'react'

const WiP = () => {
const fullscreen = {
    position : 'fixed',
    top: '0',
    left : '0',
    width: '100%',
    height: '100vh',
    background: '#fff',
    display : 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '1990'
}
  return (
    <>
        <div className="fullscreen" style={fullscreen}>
            <div className="containerBox">
                <h5>Work in Progress</h5>
            </div>
        </div>
    </>
  )
}

export default WiP