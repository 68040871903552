import axios from 'axios'
import Cookies from 'js-cookie'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getAllCookiesData, getUserData, toastifyError, toastifySuccess, url } from '../../../Utility/Utility'

const Add = ({ role, assignee, redirect }) => {
    const authRole = getAllCookiesData()?.role
    const updateID = useParams().id
    const [addUpdateApiCallCount, setAddUpdateApiCallCount] = useState(0)
    const navigate = useNavigate()
    const [updateData, setUpdateData] = useState()
    const [stateList, setState] = useState([])
    const [cityList, setCity] = useState([])
    const [assigneeList, setAssigneeList] = useState([])
    const [showPassToggle, setShowPassToggle] = useState(false)
    const [userDetails, setUserDetails] = useState({
        'name': '',
        'username': '',
        'email': '',
        'companyName': '',
        'mobileNo': '',
        'state': '',
        'city': '',
        'gstNumber': '',
        'password': '',
        'updateId': '',
        'mpin': '',
        'assigned': getAllCookiesData()?.id
    })

    const [error, setError] = useState({
        'name': '',
        'username': '',
        'email': '',
        'companyName': '',
        'mobileNo': '',
        'password': '',
        'assigned': ''
    })

    useEffect(() => {
        getState()
        getAssigneeList()
    }, [])

    useEffect(() => {
        if (updateID != 0) {
            getUpdateUserData()
        }
    }, [updateID])

    useEffect(() => {
        if (updateData?.name) {
            setUserDetails({
                ...userDetails,
                'name': updateData?.name,
                'username': updateData?.username,
                'email': updateData?.email,
                'companyName': updateData?.company_name,
                'mobileNo': updateData?.mobile,
                'state': updateData?.state,
                'city': updateData?.city,
                'gstNumber': updateData?.gst_number,
                'password': updateData?.password,
                'updateId': updateData?.id,
                'assigned': updateData?.assigned_by,
                'mpin': updateData?.mpin,
            })

        } else {
            setUserDetails({
                ...userDetails,
                'name': '',
                'username': '',
                'email': '',
                'companyName': '',
                'mobileNo': '',
                'state': '',
                'city': '',
                'gstNumber': '',
                'password': '',
                'updateId': '',
                'mpin': ''
            })
        }

    }, [updateData])

    const getState = async () => {
        try {
            const res = await axios.get(url + "/user/getState.php");
            if (res.data.status) setState(res?.data?.data); else setState()
        } catch (error) {
            console.log(error)
        }
    }

    const getCity = async (id) => {
        setUserDetails({
            ...userDetails,
            ["state"]: id
        })

        var formdata = new FormData();
        formdata.append("id", id);

        try {
            const res = await axios.post(url + "/user/getCity.php", formdata);
            if (res.data.status) setCity(res?.data?.data); else setCity()
        } catch (error) {
            console.log(error)
        }

    }

    const getAssigneeList = () => {
        getUserData(assignee)
            .then((res) => {
                if (res.status) setAssigneeList(res?.data); else setAssigneeList()
            });
    }

    const handleChange = (e) => {
        setUserDetails({
            ...userDetails,
            [e.target.name]: e.target.value
        })
    }

    const checkValidation = () => {
        setAddUpdateApiCallCount(addUpdateApiCallCount+1)
        var mobileReg = /^((\+[1-9]{1,4}[ \-]*)|(\([0-9]{2,3}\)[ \-]*)|([0-9]{2,4})[ \-]*)*?[0-9]{3,4}?[ \-]*[0-9]{3,4}?$/;
        var emailReg =/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (userDetails.name.trim() === '') {
            setError(prevValues => { return { ...prevValues, ['name']: "Please Enter Your Name !!" } })
        }else {
            setError(prevValues => { return { ...prevValues, ['name']: true } })
        }

        // Email Validation
        if (userDetails.email.trim() === '') {
            setError(prevValues => { return { ...prevValues, ['email']: "Please Enter Your Email !!" } })
        } else if(!emailReg.test(userDetails.email)){
            setError(prevValues => { return { ...prevValues, ['email']: "Please Enter Valid Email Address !!" } })
        } else {
            setError(prevValues => { return { ...prevValues, ['email']: true } })
        }


        if (userDetails.companyName.trim() === '') {
            setError(prevValues => { return { ...prevValues, ['companyName']: "Please Enter Your Company Name !!" } })
        } else {
            setError(prevValues => { return { ...prevValues, ['companyName']: true } })
        }

        // Phone No Validation
        if (userDetails.mobileNo.trim() === '') {
            setError(prevValues => { return { ...prevValues, ['mobileNo']: "Please Enter Your Mobile No !!" } })
        } else if(!mobileReg.test(userDetails.mobileNo)){
            setError(prevValues => { return { ...prevValues, ['mobileNo']: "Please Enter Valid Mobile No !!" } })
        } else {
            setError(prevValues => { return { ...prevValues, ['mobileNo']: true } })
        }
        
        if (userDetails.username.trim() === '') {
            setError(prevValues => { return { ...prevValues, ['username']: "Please Enter Your Username !!" } })
        } else {
            setError(prevValues => { return { ...prevValues, ['username']: true } })
        }
        if (userDetails.password.trim() === '') {
            setError(prevValues => { return { ...prevValues, ['password']: "Please Enter Your Password !!" } })
        } else {
            setError(prevValues => { return { ...prevValues, ['password']: true } })
        }
    }

    const { name, username, email, password, companyName, mobileNo } = error;
    useEffect(() => {
        if (name === true && username === true && email === true && password === true && companyName === true && mobileNo === true) {
            if (updateID != 0) {
                handleUpdate()
            } else {
                handleSubmit()
            }
        }
    }, [addUpdateApiCallCount, name, username, email, password, companyName, mobileNo])
    const handleSubmit = async () => {
        try {
            const id = getAllCookiesData()?.id
            var formdata = new FormData();
            formdata.append("role", role);
            formdata.append("username", userDetails.username);
            formdata.append("name", userDetails.name);
            formdata.append("email", userDetails.email);
            formdata.append("password", userDetails.password);
            formdata.append("company_name", userDetails.companyName);
            formdata.append("mobile", userDetails.mobileNo);
            formdata.append("state", userDetails.state);
            formdata.append("city", userDetails.city);
            formdata.append("gst_number", userDetails.gstNumber);
            formdata.append("created_by", id);
            formdata.append("assigned_by", userDetails.assigned);
            formdata.append("mpin", userDetails.mpin);
            formdata.append("created_at", new Date());
            const res = await axios.post(url + "/user/addUser.php", formdata);
            if (res?.data?.status) {
                toastifySuccess(res?.data?.message)
                navigate(redirect)
            }else{
                toastifySuccess(res?.data?.message)
            }
        } catch (error) {
            console.error(error);
        }
    }

    const getUpdateUserData = async () => {
        var formdata = new FormData();
        formdata.append("id", updateID);
        try {
            const res = await axios.post(url + "/user/get-single-user.php", formdata);
            if (res.data.status) { setUpdateData(res?.data?.data); } else setUpdateData()
        } catch (error) {
            console.log(error)
        }
    }

    const handleUpdate = async (e) => {
        var formdata = new FormData();
        formdata.append("id", updateID);
        formdata.append("username", userDetails.username);
        formdata.append("name", userDetails.name);
        formdata.append("email", userDetails.email);
        formdata.append("password", userDetails.password);
        formdata.append("company_name", userDetails.companyName);
        formdata.append("mobile", userDetails.mobileNo);
        formdata.append("state", userDetails.state);
        formdata.append("city", userDetails.city);
        formdata.append("gst_number", userDetails.gstNumber);
        formdata.append("assigned_by", userDetails.assigned);
        formdata.append("mpin", userDetails.mpin);
        const res = await axios.post(url + "/user/updateUser.php", formdata)
        console.log(res)
        if (res?.data?.status) {
            toastifySuccess(res.data.message)
            navigate(redirect)
        }else{
            toastifySuccess(res.data.message)
        }
    }

    return (
        <>
            <div className="col-12 form-add-update">
                <div className="row">
                    <div className="col-sm-4">
                        <div className="form-group">
                            <label htmlFor="exampleInputName1">Enter Full Name</label>
                            <input type="text" maxLength={40} className="form-control form-control-sm"
                                name="name"
                                onChange={handleChange}
                                value={userDetails.name}
                                placeholder="Enter Full Name of Distributor" required />
                            <span className='span-err-msg'>{error.name}</span>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="form-group">
                            <label htmlFor="exampleInputName1">Enter Email ID</label>
                            <input type="text" minLength={5} maxLength={50} className="form-control form-control-sm" id="email" name="email"
                                onChange={handleChange}
                                value={userDetails.email}
                                placeholder="Enter Email ID"
                             />
                            <span className='span-err-msg'>{error.email}</span>

                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="form-group">
                            <label htmlFor="exampleInputName1">Enter Mobile Number</label>
                            <input type="text" className="form-control form-control-sm" name="mobileNo" onChange={handleChange}
                                value={userDetails.mobileNo} maxLength={10} placeholder="Enter Mobile Number" required />
                            <span className='span-err-msg'>{error.mobileNo}</span>

                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4">
                        <div className="form-group">
                            <label htmlFor="exampleInputName1">Enter Company Name</label>
                            <input type="text" className="form-control form-control-sm" maxLength={40} name="companyName" onChange={handleChange}
                                value={userDetails.companyName} placeholder="Enter Company Name" />
                            <span className='span-err-msg'>{error.companyName}</span>

                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="form-group">
                            <label htmlFor="exampleInputName1">Enter GST Number</label>
                            <input type="text" className="form-control form-control-sm" onChange={handleChange}
                                value={userDetails.gstNumber} name="gstNumber" placeholder="Enter GST Number"  />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4">
                        <div className="form-group">
                            <label htmlFor="exampleInputName1">Enter Username</label>
                            <input type="text" className="form-control form-control-sm" id="username" onChange={handleChange}
                                value={userDetails.username} name="username" placeholder="Enter Username"  />
                            <span className='span-err-msg'>{error.username}</span>

                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="form-group">
                            <label htmlFor="exampleInputName1">Enter Password</label>
                            <div className="input-group">
                                <input type={showPassToggle ? `text` : `password`} minLength={4} maxLength={14} name="password" className="form-control form-control-sm" id="password" placeholder="Enter Password" aria-label="Password" onChange={handleChange}
                                    value={userDetails.password} />
                                <div className="input-group-append">
                                    <button className="btn btn-sm btn-primary" onClick={() => setShowPassToggle(!showPassToggle)} type="button"><i id="passwordToggle" className={`fa ${showPassToggle ? 'fa-eye' : 'fa-eye-slash'}`} /></button>
                                </div>
                            </div>
                            <span className='span-err-msg'>{error.password}</span>

                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4">
                        <div className="form-group">
                            <label htmlFor="exampleInputName1">State</label>

                            <select name="" id="" value={userDetails.state} className='form-control' onChange={(e) => getCity(e.target.value)}>
                                <option value="">Select</option>
                                {
                                    stateList?.map((val, index) => (
                                        <option value={val.id} key={index} >{val.state_title}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="form-group">
                            <label htmlFor="exampleInputName1">City</label>

                            <select name="city" id="" className='form-control' onChange={handleChange}>
                                <option value="">Select</option>
                                {
                                    cityList?.map((val, index) => (
                                        <option value={val.id} key={index} >{val.name}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    {
                        authRole === '7' || authRole === '1'?
                            <div className="col-sm-4">
                                <div className="form-group">
                                    <label htmlFor="exampleInputName1">Select Assignee</label>
                                    <select name="assigned" id="" className='form-control' value={userDetails.assigned} onChange={handleChange}>
                                        <option value="">Select</option>
                                        {
                                            assigneeList?.map((val, index) => (
                                                <option value={val.id} key={index} >{val.username} || {val.mobile} || {val.company_name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            :
                            <></>
                    }
                    {
                        role === '5' ?
                            <div className="col-sm-4">
                                <div className="form-group">
                                    <div className="form-group">
                                        <label htmlFor="exampleInputName1">M-PIN</label>
                                        <input type="text" className="form-control form-control-sm" id="username" onChange={handleChange}
                                            value={userDetails.mpin} name="mpin" placeholder="Enter your M-PIN" required />
                                    </div>
                                </div>
                            </div>
                            :
                            <></>
                    }

                </div>
                <div className="row">
                    <div className="col-sm-3" />
                    <div className="col-sm-3 mt-2">
                        {
                            updateID !== "0" ?
                                <button type="button" className="formbtn btn btn-primary mr-2 btn-block" onClick={checkValidation}>Update</button>
                                :
                                <button type="button" className="formbtn btn btn-primary mr-2 btn-block" onClick={checkValidation}>Submit</button>
                        }
                    </div>
                    <div className="col-sm-3 mt-2">
                        <button className="btn btn-outline-dark btn-fw btn-block" onClick={()=>navigate(redirect)}>Cancel</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Add