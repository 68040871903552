import React from 'react'
import { useNavigate } from 'react-router-dom'

const DashboardBox = ({ Count, Name, path, todayCount, thisMonth }) => {
  const navigate = useNavigate()
  return (
    <>
      <div className="col-lg-2 col-md-4 col-12 mb-4 stretch-card transparent">
        <div className="card card-dark-blue" onClick={() => navigate(path)} style={{ cursor: "pointer" }}>
          <div className="card-body">
            <div>
              <p className="" style={{ textTransform: "uppercase" }}>{Name}</p>
              <p className='d-flex justify-content-between'>
                <span style={{ fontSize: '14px' }}>Total</span>
                <span style={{ fontSize: '20px' }}>{Count ?? 0}</span> 
              </p>
            </div>

            <div className='d-flex justify-content-between mt-3'>
              <span style={{ fontSize: '13px' }}>Today</span>
              <span>{todayCount ?? 0}</span>
            </div>

            <div className='d-flex justify-content-between mt-1'>
              <span style={{ fontSize: '13px' }}>This Month</span>
              <span>{thisMonth ?? 0}</span>
            </div>

          </div>
        </div>
      </div>
    </>
  )
}
export default DashboardBox