import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './App.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import Cookies from 'js-cookie';
import CommonContext from './components/Context/CommonContext';
const root = ReactDOM.createRoot(document.getElementById('root'));
IndexRender()
const removeCookies = () => {
  Cookies.remove('moonfox-secret')
}

// window.addEventListener("beforeunload", (ev) => {  
//     ev.preventDefault();
//     Cookies.remove('moonfox-secret')
// });

function IndexRender() {
  // let isInactive = true;
  // if(isInactive){
  root.render(
    <BrowserRouter>
      <div>
        <CommonContext>
          <App />
        </CommonContext>
      </div>
    </BrowserRouter>
  );
  // }
}