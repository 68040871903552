import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import logo from '../../Image/logo.png'
import playStore from '../../Image/playStore.png'
import SocialIcon from '../Utility/SocialIcon'
const Header = () => {
    const path = useLocation().pathname
    const [toggle , setToggle] = useState(false)
    const toggleMenu = () => {
        setToggle(!toggle)
    }
    return (
        <>
            <div className="header-fixed">
                <div className="header-top">
                    <div className="left">
                        <span className='mr-3'>Support -</span>
                        <span> <i className='fa fa-phone'></i> +91 8104333322</span>
                        <span className='mx-4'>||</span>
                        <span> <i className='fa fa-envelope'></i> moonfox@moonfox.in</span>
                    </div>
                    <div className="right">
                        <SocialIcon />
                    </div>
                </div>
                <nav className="navbar navbar-expand-lg navbar-light bg-light">
                    <div className="container">
                        <Link to={'/'} className="logo me-auto" style={{ marginRight: 'auto' }}><img src={logo} alt="logo" width={'50px'} style={{ borderRadius: "50%" }} className="img-fluid" /></Link>
                        <button className="navbar-toggler" onClick={()=>toggleMenu()} type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <i className={`fa ${toggle ? 'fa-times' : 'fa-bars'} py-1`} style={{height: '30px', width: '20px'}}></i>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent" style={{ display: toggle ? "block" : "none" }}>
                            <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                                <li className="nav-item">
                                    <Link className={`nav-link ${path === '/' ? 'active' : ''}`} aria-current="page" to={'/'}>Home</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={`nav-link ${path === '/our-app' ? 'active' : ''}`} to={'/our-app'}>Our Apps</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={`nav-link ${path === '/contact' ? 'active' : ''}`} to="/contact">Contact</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={`nav-link ${path === '/policy' ? 'active' : ''}`} to="/policy">policy</Link>
                                </li>
                            </ul>
                            <div>
                                <Link to={'/login'} className='c-btn c-btn-sm'>LOGIN</Link>
                                <Link to={'https://play.google.com/store/apps/details?id=com.arustu.moonfoxretailer'} > <img src={playStore} alt="" /></Link>
                            </div>

                        </div>
                    </div>
                </nav>
            </div>
        </>
    )
}

export default Header