import axios from 'axios';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { url } from '../../Utility/Utility';
import DataTable from 'react-data-table-component';
import {timeAgo} from '../Utility/Date';

const DeleteDeviceList = ({ path }) => {
    const [data, setData] = useState([])

    useEffect(() => {
        getList();
    }, [])

    const getList = async () => {
        try {
            const data = {
                "type" : "get-delete-device"
            }
            const res = await axios.post(url + "/v1/device.php", data)
            if (res?.data?.status) {
                setData(res?.data?.data)
            } else {
                setData()
            }
        } catch (error) {
            console.log(error)
        }
    }

    const columns = [
        {
            name: 'S.No',
            selector: (row, index) => index + 1,
            sortable: true
        },
        {
            name: 'Name',
            selector: (row) => row.FullName
        },
        {
            name: 'IMEINO ',
            selector: (row) => row.IMEINO,
            sortable: true
        },
        {
            name: 'Email ',
            selector: (row) => row.Email,
            sortable: true
        },
        {
            name: "Retailer's Username",
            selector: (row) => <>{row.RetailerID + ` ( ` + row.username + `)`}</>,
            sortable: true
        },
        {
            name: 'Mobile No ',
            selector: (row) => row.MobileNo,
            sortable: true
        },
        {
            name: 'Device Status ',
            selector: (row) => <><span className={`badge text-capitalize ${row.DeviceStatus === "Inactive" ? "badge-warning" : row.DeviceStatus === "lock" ? "badge-danger" : row.DeviceStatus === "unlock" ? "badge-info" : row.DeviceStatus === "Uninstall" ? "badge-success" : ""}`}> {row.DeviceStatus}</span> </>,
            sortable: true
        },
        {
            name: 'Add Date',
            selector: (row) => row.created_at,
            sortable: true
        },
        {
            name: 'Deleted Date ',
            selector: (row) => row.deleted_at,
            sortable: true
        },
    ]

    return (
        <>
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">{path.substring(1)}</h4>
                    <div class="row">
                        <div className="col-12 pt-3" style={{ borderTop: '1px solid #ddd' }}>
                            <div className="row">

                            </div>
                            <div className="row py-3 px-0 mt-3 mx-0 justify-content-center" style={{ background: '#F5F7FF' }}>

                            </div>
                        </div>
                        <div className="col-12">
                            <DataTable
                                dense
                                columns={columns}
                                data={data}
                                pagination
                                highlightOnHover
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DeleteDeviceList