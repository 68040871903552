import axios from 'axios'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { encryptCookiesData, toastifyError, toastifySuccess, url } from '../../Utility/Utility'
import logo from '../Img/logo.png'
const Login = () => {
    const style = {
        position: 'fixed',
        top: '0',
        left: '0',
        background: '#fff',
        width: '100%',
        height: '100vh',
        zIndex: '1900',
    }
    const [email, setEmail] = useState()
    const [password, setPassword] = useState()
    const [showPassToggle, setShowPassToggle] = useState(false)
    const [appUrlModal, setAppUrlModal] = useState(false)
    const [logLoader, setShowLoader] = useState(false)
    const navigate = useNavigate()

    const loginUser = async (e) => {
        e.preventDefault()
        try {
            var formdata = new FormData();
            formdata.append("email", email);
            formdata.append("password", password);
            const data = formdata;
            const result = await axios.post(url + "/auth/login.php", data)
            if (result?.data?.status) {
                if (result?.data?.data?.role === "5") {
                    setShowLoader(true)
                    setTimeout(() => {
                        setAppUrlModal(true)
                    }, 1000);
                } else {
                    const data = {
                        "id" : result?.data.data.id,
                        'role': result?.data.data.role,
                        'name': result?.data.data.name,
                        'assigned_by': result?.data.data.assigned_by,
                    }
                    encryptCookiesData("moonfox-secret", data, "MoonfoxCookiesKeys")
                    setShowLoader(true)
                    setTimeout(() => {
                        navigate('/dashboard')
                        toastifySuccess(result?.data?.msg)
                        window.location.reload()
                    }, 1000);
                }
            } else {
                toastifyError(result?.data.msg)
            }
        } catch (error) {console.log(error)}

    }

    return (
        <>
            <div className="content-wrapper d-flex align-items-center auth px-0" style={style}>
                <div className="container py-5 h-100">
                    <div className="row d-flex justify-content-center align-items-center h-100">
                        <div className="col-12 col-md-8 col-lg-6 col-xl-5" >
                            <div className="card shadow-2-strong" style={{ borderRadius: '1rem', background: '#caf0f8' }}>
                                <div className="card-body p-5">
                                    <form className="pt-3" onSubmit={loginUser}>

                                        <div className="logo-box text-center mb-2">
                                            <div className="brand-logo">
                                                <img src={logo} alt="logo" style={{ borderRadius: '50%', height: '70px', width: '70px' }} />
                                                <h4 className='mt-2'>Sign in to continue.</h4>
                                            </div>
                                            {
                                                logLoader ?
                                                    <>
                                                        <div className="spinner-border text-success" role="status">
                                                        </div> <br />
                                                        <span >Please Wait...</span>
                                                    </>
                                                    : ''
                                            }
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="exampleInputName1">Enter Your Email</label>
                                            <input type="text"
                                                className="form-control form-control-lg"
                                                id="exampleInputEmail1"
                                                placeholder="Enter Your Email"
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="exampleInputName1">Enter Your Passowrd</label>
                                            <div className="input-group">
                                                <input type={showPassToggle ? `text` : `password`} name="password" className="form-control form-control-lg" id="password" placeholder="Enter Password" aria-label="Password" onChange={(e) => setPassword(e.target.value)} />
                                                <div className="input-group-append">
                                                    <button className="btn btn-sm btn-primary" onClick={() => {setShowPassToggle(!showPassToggle)}} type="button"><i id="passwordToggle" className={`fa ${showPassToggle ? 'fa-eye' : 'fa-eye-slash'}`} /></button>
                                                </div>
                                            </div>
                                        </div>
                                        <button type={`${logLoader ? 'button' : 'submit'}`} disabled={`${logLoader ? 'disabled' : ''}`} className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"> {logLoader ? 'Please Wait ...' : 'SIGN IN'} </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {
                appUrlModal ?
                    <div className="app-url-modal">
                        <div className="c-modal" style={{ background: '#ddd' }}>
                            <div className="c-modal-box" role="document">
                                <div className="c-modal-body text-center py-4">
                                    <i class="fa fa-question-circle tcr" aria-hidden="true" style={{ fontSize: "40px" }}></i>
                                    <h4 className="modal-title my-4">{"Do You Want To Install App ?"}</h4>
                                    <div className="btn-box mt-3">
                                        <Link to={'https://play.google.com/store/apps/details?id=com.arustu.moonfoxretailer'} style={{ background: "#ef233c" }} className="btn btn-sm text-white">YES</Link>
                                        <button type="button" className="btn btn-sm btn-secondary ml-2 " onClick={() => {setAppUrlModal(false); setShowLoader(false)}} > No</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <></>
            }

        </>
    )
}

export default Login