import axios from 'axios';
import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import { url } from '../../../Utility/Utility';
import { timeAgo } from '../../Utility/Date'
import avatar1 from '../../Img/avatar1.jpg'
import avatar2 from '../../Img/avatar2.jpg'
import avatar3 from '../../Img/avatar3.jpg'
import avatar4 from '../../Img/avatar4.jpg'
const MessageList = ({ setPage }) => {
    const [recentMessageList, setRecentMessageList] = useState([])
    const [loading, setLoading] = useState(false)
    const columns = [
        {
            name: 'Message From ',
            selector: (row) => <>{`${row.msg_from === "1" ? "Moonfox (Admin)" : row.msg_from === "107" ? "Arustu (Super Admin)" : ""}`}</>,
            sortable: true
        },
        {
            name: 'Message',
            selector: (row) => row.msg,
            sortable: true
        },
        {
            name: 'Message To',
            selector: (row) =>

                <div className="retailer-avatar-wraper">
                    {
                        row.msg_to_text.split(",").map((element, i) => {
                            return <>
                                {
                                    i < 5 ?
                                        <>
                                            <div className="avatar-box" data-toggle="tooltip" data-placement="top" title={element}>
                                                <img src={avatar2} alt="" />
                                            </div>
                                        </>
                                        :
                                        <></>
                                }
                            </>
                        })

                    }
                    {
                        row.msg_to_text?.split(",").length > 5 ?
                            <span style={{ color: 'green' }}>+++</span>
                            :
                            <></>
                    }
                </div>
            ,
            sortable: true
        },
        {
            name: 'Date ',
            selector: (row) => timeAgo(new Date(row.msg_date)),
            sortable: true
        },
    ]

    useEffect(() => {
        getRecentMessage()
    }, [])

    const getRecentMessage = async () => {
        setLoading(true)
        try {
            var formdata = new FormData();
            formdata.append("type", "get-message");
            const res = await axios.post(url + "/user/message.php", formdata);
            if (res?.data?.status) {
                setLoading(false)
                setRecentMessageList(res?.data?.data)
            }
        } catch (error) { console.log(error) }
    }

    return (
        <>

            <div class="row">
                <div className="col-12 d-flex" style={{ justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #ddd' }}>
                    <h4 class="card-title">{'Recent Message'}</h4>
                    <div>
                        {
                            loading ?
                                <button className="btn btn-sm btn-primary mr-2" type="button" disabled>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    Loading...
                                </button>
                                :
                                <>
                                    <button className='d-inline-block btn btn-warning btn-sm mr-2' onClick={() => getRecentMessage()} data-toggle="tooltip" data-placement="bottom" title="Reload"><i class="fa fa-refresh"></i></button>
                                </>
                        }
                        <h4 className='d-inline-block'> <button className='btn btn-sm btn-primary' onClick={() => setPage(false)}>Send Message</button></h4>
                    </div>
                </div>
                <div className="col-12">
                    <DataTable
                        dense
                        columns={columns}
                        data={recentMessageList}
                        pagination
                        highlightOnHover
                    />
                </div>
            </div>
        </>

    )
}

export default MessageList