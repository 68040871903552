import Cookies from 'js-cookie'
import React from 'react'
import { useState } from 'react'
import { getAllCookiesData, toastifyError, toastifySuccess, url } from '../../Utility/Utility'
import axios from 'axios'

const ChangePassword = ({ path }) => {
    const role = getAllCookiesData()?.role
    const id = getAllCookiesData()?.id
    const [showPassToggle, setShowPassToggle] = useState(false)
    const [val, setVal] = useState({
        'role': role,
        'id': id,
        'oldPass': '',
        'newPass': '',
        'confirmPass': '',
    })
    const handleChange = (e) => {
        setVal({
            ...val,
            [e.target.name]: e.target.value,
        });
    }
    const changePassword = async () => {
        try {
            const res = await axios.post(url + "/auth/change-password.php", val);
            if (res?.data?.status) {
                toastifySuccess(res?.data?.message)
                setVal({ ...val, 'role': '', 'id': '', 'oldPass': '', 'newPass': '', 'confirmPass': '' });
            } else {
                toastifyError(res?.data?.message)
            }
        } catch (error) { console.log(error) }
    }
    const label = {
        fontSize: '12px',
        marginTop: "6px",
        textTransform: "uppercase",
        fontWeight: "bold"
    }

    return (
        <>
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">{path.substring(1)}</h4>
                    <div className="row mt-3 pt-3" style={{ borderTop: '1px solid #ccc' }}>
                        <div className="col-12 col-sm-4">
                            <label style={label}>Enter your old password :</label>
                            <input type="password" name='oldPass' value={val.oldPass} onChange={handleChange} className='form-control form-control-sm' />
                        </div>
                        <div className="col-12 col-sm-4 passwordToggleBox">
                            <label style={label}>Enter new password :</label>
                            <input type={showPassToggle ? `text` : `password`} name='newPass' onChange={handleChange} value={val.newPass} className='form-control form-control-sm' />
                            {val.newPass ? 
                                <i  onClick={() => setShowPassToggle(!showPassToggle)} className={`passwordToggle fa ${showPassToggle ? 'fa-eye' : 'fa-eye-slash'}`} />
                                :
                                <></>}
                        </div>
                        <div className="col-12 col-sm-4">
                            <label style={label} >Confirm password :</label>
                            <input type="password" name='confirmPass' onChange={handleChange} value={val.confirmPass} className='form-control form-control-sm' />
                        </div>
                        <div className="col-12 col-sm-12 mt-3 text-right">
                            <button className='btn btn-primary' onClick={() => changePassword()}>Change</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ChangePassword