import React from 'react'

const ContactDetails = () => {
    return (
        <>
            <div className="row">
                <div className="col-md-4 col-sm-6 col-12">
                    <div className="service_box">
                        <div className="service_icon">
                            <i className="fa fa-envelope" />
                        </div>
                        <h3>Email</h3>
                        <p>moon@moonfox.in</p>
                    </div>
                </div>
                <div className="col-md-4 col-sm-6 col-12">
                    <div className="service_box">
                        <div className="service_icon">
                            <i className="fa fa-globe" />
                        </div>
                        <h3>Location</h3>
                        <p>Jaipur - Rajasthan</p>
                    </div>
                </div>
                <div className="col-md-4 col-sm-6 col-12">
                    <div className="service_box">
                        <div className="service_icon">
                            <i className="fa fa-phone" />
                        </div>
                        <h3>Phone</h3>
                        <p>+91 8104333322</p>
                    </div>
                </div>
            </div>

        </>
    )
}

export default ContactDetails