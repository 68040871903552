import React from 'react'
import Add from '../Common/Main/Add'
import List from '../Common/Main/List'

const SuperDis = ({ path }) => {
    return (
        <>
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">{path.substring(1)}</h4>
                    <div class="row">
                        {path === '/super-distributor' ? <List role="3" {...{path}}/> : <Add role="3" assignee="2" redirect="/super-distributor"/>}
                    </div>
                </div>
            </div>
        </>
    )
}

export default SuperDis