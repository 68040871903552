import React, { useState, memo } from 'react';
import { toastifyError, toastifySuccess, url } from '../../../Utility/Utility';
import axios from 'axios';
import Loader from '../../Utility/Loader';

function RevertKey({ setModalOpen, revetDetails, getList }) {
    const [loading, setLoading] = useState(false)
    const [value, setValue] = useState({
        'id': revetDetails.id, "role": revetDetails.role, "revertKeyCount": '',
    })

    const reset = () => {
        setValue({
            ...value,
            'id': '', "role": '', "revertKeyCount": '',
        })
    }
    const RevertKeyFunc = async () => {
        if(value.revertKeyCount === "0"){
            toastifyError("Enter Valid Keys !!")
            return false
        }
        if( parseInt(value.revertKeyCount) > parseInt(revetDetails.keyBalance)){
            toastifyError("Insufficent Keys !!")
            return false
        }
        if(revetDetails.keyBalance <= 0){
            toastifyError("Key not available !!")
            return false
        }
        if (value.revertKeyCount.trim() === '') {
            toastifyError("Enter Revert Keys Count !!")
            return false
        }
        var formdata = new FormData();
        formdata.append("id", value.id);
        formdata.append("role", value.role);
        formdata.append("revertKeyCount", value.revertKeyCount);
        setLoading(true)
        const res = await axios.post(url + "/token/revert-keys.php", formdata)
        if (res?.data?.status) {
            toastifySuccess("Key Revert Succesfully !!")
            getList()
            setModalOpen(false)
            setLoading(false)
        } else {
            toastifyError("Something Went Wrong !!")
            setLoading(false)
        }

    }


    return (
        <>
            <div class="c-modal" >
                <div className="c-modal-box">
                    {
                        loading ?
                            <>
                                <Loader />
                                <h4 className='text-center mt-0 pt-0' style={{ color: "#bd0001" }}>Please Wait !!</h4>
                            </>
                            :
                            <div className="c-modal-body revert-modal">
                                <div className="modal-header">
                                    <h4 className="sub-title">Revert Key</h4>
                                </div>
                                <div className="form-group row pl-3">
                                    <label className="col-sm-3 col-form-label mt-4">No. of Keys  </label>
                                    <div className="col-sm-3 mt-4">
                                        <input type="text" name='revertKeyCount' onChange={(e) => setValue({ ...value, "revertKeyCount": e.target.value })} maxLength={3} className="form-control" />
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" onClick={() => { reset(); setModalOpen(false) }}>Close</button>
                                    <button type="button" className="btn btn-primary" onClick={RevertKeyFunc}>Revert</button>
                                </div>
                            </div>
                    }
                </div>
            </div>

        </>
    );
}

export default memo(RevertKey)