import React from 'react'
import Add from '../Common/Main/Add'
import List from '../Common/Main/List'

const NationalDis = ({path}) => {

    return (
        <>
             <div class="card">
                <div class="card-body">
                    <h4 class="card-title">{path.substring(1)}</h4>
                    <div class="row">
                        {path === '/national-distributor'?  <List role = "2" {...{path}}/> : <Add role="2" assignee="1" redirect="/national-distributor"/>}
                    </div>
                </div>
            </div>
        </>
    )
}

export default NationalDis