import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { GetAssigneRole, toastifyError, toastifySuccess, url } from '../../../Utility/Utility'

const AddSales = ({ setPage, getSalesList, editVal, updateStatus, setUpdateStatus }) => {
    const [salesPost, setSalesPost] = useState([])
    const [salesDrpList, setSalesDrpList] = useState([])
    const [salesVal, setSalesVal] = useState({
        'name': '', 'email': '', 'mobileNo': '', 'penCard': '', 'address': '', 'aadhar': '', 'password': '', 'salesRole': '', 'assginId': ''
    })
    const [error, setError] = useState({
        'name': '', 'email': '', 'mobileNo': '', 'penCard': '', 'address': '', 'aadhar': '', 'password': '', 'salesRole': '', 'assginId': ''
    })
    const handleChange = (e) => {
        setSalesVal({
            ...salesVal,
            [e.target.name]: e.target.value
        })
        if (e.target.name === "salesRole") {
            getSalesDrpList(e.target.value)
        }
    }
    const label = {
        fontSize: '13px',
        marginTop: "6px",
        textTransform: "capitalize"
    }

    useEffect(() => {
        if (editVal.name) {
            setSalesVal({
                ...salesVal,
                'id': editVal.id, 'name': editVal.name, 'email': editVal.email, 'mobileNo': editVal.mobile, 'address': editVal.address, 'aadhar': editVal.aadhar_number, 'password': editVal.password, 'salesRole': editVal.role, 'assginId': editVal.assginId, penCard: editVal.pen_number
            })
        } else {
            setSalesVal({
                ...salesVal,
                'name': '', 'email': '', 'mobileNo': '', 'address': '', 'aadhar': '', 'password': '', 'salesRole': '', 'assginId': '', penCard: ""
            })
        }
    }, [editVal])

    useEffect(() => {
        getSalesPostList()
    }, [])


    const getSalesPostList = async () => {
        try {
            const res = await axios(url + "/sales/get-sales-post.php");
            if (res?.data?.status) setSalesPost(res?.data?.data)
        } catch (error) { console.log(error) }
    }

    const getSalesDrpList = async (role) => {
        try {
            var formdata = new FormData();
            formdata.append("type", "get_sales_by_role_and_assigned");
            formdata.append("role", GetAssigneRole(role));
            const res = await axios.post(url + "/sales/sales.php", formdata);
            if (res?.data?.status) setSalesDrpList(res?.data?.data); else setSalesDrpList()
        } catch (error) { console.log(error) }
    }

    const checkValidation = () => {
        var mobileReg = /^((\+[1-9]{1,4}[ \-]*)|(\([0-9]{2,3}\)[ \-]*)|([0-9]{2,4})[ \-]*)*?[0-9]{3,4}?[ \-]*[0-9]{3,4}?$/;
        var emailReg = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (salesVal.name.trim() === '') {
            setError(prevValues => { return { ...prevValues, ['name']: "Please Enter Your Name !!" } })
        } else {
            setError(prevValues => { return { ...prevValues, ['name']: true } })
        }

        // Email Validation
        if (salesVal.email.trim() === '') {
            setError(prevValues => { return { ...prevValues, ['email']: "Please Enter Your Email !!" } })
        } else if (!emailReg.test(salesVal.email)) {
            setError(prevValues => { return { ...prevValues, ['email']: "Please Enter Valid Email Address !!" } })
        } else {
            setError(prevValues => { return { ...prevValues, ['email']: true } })
        }

        // Phone No Validation
        if (salesVal.mobileNo.trim() === '') {
            setError(prevValues => { return { ...prevValues, ['mobileNo']: "Please Enter Your Mobile No !!" } })
        } else if (!mobileReg.test(salesVal.mobileNo)) {
            setError(prevValues => { return { ...prevValues, ['mobileNo']: "Please Enter Valid Mobile No !!" } })
        } else {
            setError(prevValues => { return { ...prevValues, ['mobileNo']: true } })
        }

        if (salesVal.penCard.trim() === '') {
            setError(prevValues => { return { ...prevValues, ['penCard']: "Please Enter Your Pen Card Number !!" } })
        } else {
            setError(prevValues => { return { ...prevValues, ['penCard']: true } })
        }

        if (salesVal.aadhar.trim() === '') {
            setError(prevValues => { return { ...prevValues, ['aadhar']: "Please Enter Your Aadhar Number !!" } })
        } else {
            setError(prevValues => { return { ...prevValues, ['aadhar']: true } })
        }

        if (salesVal.password.trim() === '') {
            setError(prevValues => { return { ...prevValues, ['password']: "Please Enter Your Password !!" } })
        } else {
            setError(prevValues => { return { ...prevValues, ['password']: true } })
        }

        if (salesVal.address.trim() === '') {
            setError(prevValues => { return { ...prevValues, ['address']: "Please Enter Your Address !!" } })
        } else {
            setError(prevValues => { return { ...prevValues, ['address']: true } })
        }

        if (salesVal.salesRole.trim() === '') {
            setError(prevValues => { return { ...prevValues, ['salesRole']: "Please Select Sales Role !!" } })
        } else {
            setError(prevValues => { return { ...prevValues, ['salesRole']: true } })
        }

        if (salesVal.assginId.trim() === '') {
            setError(prevValues => { return { ...prevValues, ['assginId']: "Please Select Assignee !!" } })
        } else {
            setError(prevValues => { return { ...prevValues, ['assginId']: true } })
        }
    }

    const { name, email, mobileNo, password, penCard, address, aadhar, salesRole} = error;
    useEffect(() => {
        if (name === true && email === true && mobileNo === true && password === true && penCard === true && aadhar === true && address === true && salesRole === true) {
            if (editVal.name) {
                updateSales()
            } else {
                saveSales()
            }
        }
    }, [name, email, mobileNo, password, penCard, aadhar, address, salesRole])

    const saveSales = async () => {
        try {
            var formdata = new FormData();
            formdata.append("type", "add-sales");
            formdata.append("name", salesVal.name);
            formdata.append("email", salesVal.email);
            formdata.append("password", salesVal.password);
            formdata.append("mobile", salesVal.mobileNo);
            formdata.append("aadhar_number", salesVal.aadhar);
            formdata.append("pen_number", salesVal.penCard);
            formdata.append("address", salesVal.address);
            formdata.append("role", salesVal.salesRole);
            formdata.append("assigned_by", salesVal.assginId);
            formdata.append("created_at", new Date());

            const res = await axios.post(url + "/sales/sales.php", formdata);
            if (res.data.status) {
                toastifySuccess("Sales Team Saved SuccessFully !!")
                setPage(false)
                getSalesList()
            } else {
                toastifyError("Something Went Wrong !!")
            }
        } catch (error) { console.log(error) }
    }

    const updateSales = async () => {
        try {
            var formdata = new FormData();
            formdata.append("type", "update");
            formdata.append("id", salesVal.id);
            formdata.append("name", salesVal.name);
            formdata.append("email", salesVal.email);
            formdata.append("password", salesVal.password);
            formdata.append("mobile", salesVal.mobileNo);
            formdata.append("aadhar_number", salesVal.aadhar);
            formdata.append("pen_number", salesVal.penCard);
            formdata.append("address", salesVal.address);
            formdata.append("role", salesVal.salesRole);
            formdata.append("assigned_by", salesVal.assginId);
            const res = await axios.post(url + "/sales/sales.php", formdata);
            if (res.data.status) {
                toastifySuccess("Sales Team Updated SuccessFully !!")
                setPage(false)
                getSalesList()
            } else {
                toastifyError("Something Went Wrong !!")
            }
        } catch (error) { console.log(error) }
    }

    const resetForm = () => {
        setSalesVal({
            ...salesVal,
            'name': '', 'email': '', 'mobileNo': '', 'address': '', 'aadhar': '', 'password': '', 'salesRole': '', 'assginId': '', penCard: ""
        })
    }

    return (
        <>
            <div className="col-12">
                <div className="row mt-2">
                    <div className="col-12 col-sm-4" style={{}}>
                        <label style={label}>Name :</label>
                        <input type="text" name='name' maxLength={30} value={salesVal.name} onChange={handleChange} className='form-control form-control-sm' />
                        <span className='span-err-msg'>{error.name}</span>
                    </div>
                    <div className="col-12 col-sm-4">
                        <label style={label}>Email :</label>
                        <input type="text" name='email' value={salesVal.email} onChange={handleChange} className='form-control form-control-sm' />
                        <span className='span-err-msg'>{error.email}</span>
                    </div>
                    <div className="col-12 col-sm-4">
                        <label style={label}>password :</label>
                        <input type="text" maxLength={14} name='password' value={salesVal.password} onChange={handleChange} className='form-control form-control-sm' />
                        <span className='span-err-msg'>{error.password}</span>
                    </div>
                    <div className="col-12 col-sm-4">
                        <label style={label}>Mobile :</label>
                        <input type="text" maxLength={10} name='mobileNo' value={salesVal.mobileNo} onChange={handleChange} className='form-control form-control-sm' />
                        <span className='span-err-msg'>{error.mobileNo}</span>
                    </div>
                    <div className="col-12 col-sm-4">
                        <label style={label}>Pan Card :</label>
                        <input type="text" maxLength={10} name='penCard' value={salesVal.penCard} style={{ textTransform: "uppercase" }} onChange={handleChange} className='form-control form-control-sm' />
                        <span className='span-err-msg'>{error.penCard}</span>
                    </div>
                    <div className="col-12 col-sm-4">
                        <label style={label}>Aadhar No :</label>
                        <input type="text" name='aadhar' maxLength={12} onChange={handleChange} value={salesVal.aadhar} className='form-control form-control-sm' />
                        <span className='span-err-msg'>{error.aadhar}</span>
                    </div>

                    <div className="col-12 col-sm-4">
                        <label style={label}>Address :</label>
                        <input type="text" name='address' onChange={handleChange} value={salesVal.address} className='form-control form-control-sm' />
                        <span className='span-err-msg'>{error.address}</span>
                    </div>

                    <div className="col-12 col-sm-4">
                        <label style={label}>Sales Post :</label>
                        <select name="salesRole" style={{ textTransform: "capitalize" }} value={salesVal.salesRole} className="form-control" onChange={handleChange} >
                            <option>Select</option>
                            {
                                salesPost?.map((val, index) => (
                                    <option value={val.id} key={index}>{val.sales_post}</option>
                                ))
                            }
                        </select>
                        <span className='span-err-msg'>{error.salesRole}</span>
                    </div>

                    {
                        salesVal.salesRole !== "" && salesVal.salesRole !== "1" ?
                            <div className="col-12 col-sm-4">
                                <label style={label}>Select Assignee :</label>
                                <select name="assginId" style={{ textTransform: "capitalize" }} value={salesVal.assginId} className="form-control" onChange={handleChange} >
                                    <option>Select</option>
                                    {
                                        salesDrpList?.map((val, index) => (
                                            <option value={val.id} key={index}>{val.name + " || " + val.aadhar_number}</option>
                                        ))
                                    }
                                </select>
                                <span className='span-err-msg'>{error.assginId}</span>
                            </div>
                            :
                            <></>
                    }



                </div>

                <div className="row py-3 px-0 mt-3 mx-0 justify-content-center" style={{ background: '#F5F7FF' }}>
                    {
                        updateStatus ?
                            <div className="col-lg-2 col-md-2 col-sm-3 col-4">
                                <input type="button" value='Update' onClick={checkValidation} className='form-control form-control-sm bg-primary text-white' />
                            </div>
                            :
                            <div className="col-lg-2 col-md-2 col-sm-3 col-4">
                                <input type="button" value='Save' onClick={checkValidation} className='form-control form-control-sm bg-primary text-white' />
                            </div>
                    }
                    <div className="col-lg-2 col-md-2 col-sm-3 col-4">
                        <button type='button' onClick={() => resetForm()} className='form-control form-control-sm bg-warning text-white'>Clear</button>
                    </div>
                </div>

            </div>


        </>
    )
}

export default AddSales