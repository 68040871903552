import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import productImg from '../Image/productimg1.png'
import './Style/frontStyle.css';
import AppsImgBox from './Utility/AppsImgBox';
import ContactDetails from './Utility/ContactDetails';
import IntruderSelfie from '../Image/ourApps/IntruderSelfie.png'
import MovementAlert from '../Image/ourApps/MovementAlert.png'
import PocketTheft from '../Image/ourApps/PocketTheft.png'
import SimCardSecurity from '../Image/ourApps/SimCardSecurity.png'
import StopShutDown from '../Image/ourApps/StopShutDown.png'
import StoptoConnect from '../Image/ourApps/StoptoConnect.png'
import TrackyourPhone from '../Image/ourApps/TrackyourPhone.png'
import UnplugAlert from '../Image/ourApps/UnplugAlert.png'
import ScheduleScan from '../Image/AntiVirus/ScheduleScan.png'
import QuickScan from '../Image/AntiVirus/QuickScan.png'
import DevicePerformance from '../Image/AntiVirus/DevicePerformance.png'
import CustomScan from '../Image/AntiVirus/CustomScan.png'
import axios from 'axios';
import { url } from '../Utility/Utility';
import Loader from '../DashboardPannel/Utility/Loader';
import LoaderCom from '../DashboardPannel/Utility/LoaderCom';
const MoonFoxHome = () => {
    const [onloadModal, setOnloadModal] = useState(false)
    const [count, setCount] = useState({})
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        setTimeout(() => {
            setOnloadModal(true)
        }, 1000)
        getCount()
    }, [])

    useEffect(() => {
        const site_visit = 0;
        const now = new Date();
        const isExpired = now.setHours(now.getHours() + 24);
        if (typeof Storage !== "undefined") {
            if (!localStorage.setTime) {
                localStorage.setTime = isExpired;
            }
            if (!localStorage.visited) {
                localStorage.setItem('siteVisitCount', site_visit + 1);
                updateVisitorCount()
                localStorage.visited = true;
            }

            if (Number(localStorage.setTime) < new Date()) {
                localStorage.removeItem('visited');
            }
        }
    }, [])

    const getCount = async () => {
        setLoading(true)
        try {
            const data = { "type": "get-count" }
            const res = await axios.post(url + "/utility.php", data);
            if (res?.data.status) { setCount(res.data.data); setLoading(false) }
        } catch (error) { console.log(error) }
    }

    const updateVisitorCount = async () => {
        try {
            const data = { "type": "update-visitor-count", "date": new Date(), }
            await axios.post(url + "/utility.php", data);
        } catch (error) { console.log(error) }
    }



    return (
        <>
            <section id="hero" className="d-flex align-items-center">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1" data-aos="fade-up" data-aos-delay="200">
                            <h1>MOONFOX FINANCE LOCKER</h1>
                            <p style={{ textAlign: 'justify' }}>Our finance locker with multiple features provide best solutions to retailers, so no more worrying for unpaid emi’s. MoonFox contains mobile finance lockers which help retailers to handle their customers effectively and effortlessly who don’t pay emi on time. Now retailer’s don’t have to stress for emi recovery as we offer security with a complete solution to you with our trusted finance locker. Now no one will benefit inequitably from the finances. It doesn't matter if the customer changes sim cards or tries to trick you, if the emi is not paid on time then you might be able to lock the customer's device. </p>
                            <div className="d-flex justify-content-center justify-content-lg-start">
                                <Link to="/login" className="c-btn" style={{ background: "#fff", color: "#bd0001" }}>Login</Link>
                            </div>
                        </div>
                        <div className="col-lg-4 order-1 order-lg-2 text-lg-right hero-img" data-aos="zoom-in" data-aos-delay="200">
                            <div id="hero-img-box" >
                                <img src={productImg} className="img-fluid animated" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container" id='our-app'>
                    <div className='text-center'>
                        <h6>OUR APPS</h6>
                    </div>
                    <div className="row gy-5">
                        <AppsImgBox productImg={IntruderSelfie} title="Intruder Selfie" />
                        <AppsImgBox productImg={StopShutDown} title="stop shut down" />
                        <AppsImgBox productImg={StoptoConnect} title="stop to connect with PC" />
                        <AppsImgBox productImg={SimCardSecurity} title="SIM tracker" />
                        <AppsImgBox productImg={TrackyourPhone} title="track your phone" />
                        <AppsImgBox productImg={PocketTheft} title="pocket theft security" />
                        <AppsImgBox productImg={UnplugAlert} title="unplug alert " />
                        <AppsImgBox productImg={MovementAlert} title="movement alert" />
                    </div>
                </div>
            </section>

            <section>
                <div className="container">
                    <div className='text-center'>
                        <h6>Anti Virus</h6>
                    </div>
                    <div className="row gy-5">
                        <AppsImgBox productImg={QuickScan} title="Quick Scan" />
                        <AppsImgBox productImg={CustomScan} title="custom scan" />
                        <AppsImgBox productImg={ScheduleScan} title="schedule scan" />
                        <AppsImgBox productImg={DevicePerformance} title="device Performance" />
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" id='count-wraper'>
                    <div className="container">
                        <div className="row text-center">
                            <div className="col-6 col-md-3">
                                {
                                    loading ?
                                        <LoaderCom type="cylon" color="#fff" />
                                        :
                                        <div className="count-box">
                                            <h3>Visitors</h3>
                                            <p> {count.visitors} <span>+</span></p>
                                        </div>
                                }
                            </div>
                            <div className="col-6 col-md-3">
                                {
                                    loading ?
                                        <LoaderCom type="cylon" color="#fff" />
                                        :
                                        <div className="count-box">
                                            <h3>Distributors</h3>
                                            <p>{count.distributor} <span>+</span></p>
                                        </div>
                                }

                            </div>
                            <div className="col-6 col-md-3">
                                {
                                    loading ?
                                        <LoaderCom type="cylon" color="#fff" />
                                        :
                                        <div className="count-box">
                                            <h3>Retailers</h3>
                                            <p>{count.retailer} <span>+</span></p>
                                        </div>
                                }

                            </div>
                            <div className="col-6 col-md-3">
                                {
                                    loading ?
                                        <LoaderCom type="cylon" color="#fff" />
                                        :
                                        <div className="count-box">
                                            <h3>Customers</h3>
                                            <p>{count.customer} <span>+</span></p>
                                        </div>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container">
                    <ContactDetails />
                </div>
            </section>

            <>

                {/* <main id="main">
                <section id="product" className="why-us section-bg">
                    <div className="container-fluid" data-aos="fade-up">
                        <div className="row">

                            <div className="col-lg-7 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1">

                                <div className="content">
                                    <h3>MOONFOX FINANCE  <strong> LOCKER</strong></h3>
                                    <p>
                                        Specifications of Dealer/ retailer app :
                                    </p>
                                </div>

                                <div className="accordion-list">
                                    <ul>
                                        <li>
                                            <a data-bs-toggle="collapse" className="collapse" data-bs-target="#accordion-list-1"><span>01</span> Instant Lock and unlock button is provided. <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                                        </li>

                                        <li>
                                            <a data-bs-toggle="collapse" data-bs-target="#accordion-list-2" className="collapsed"><span>02</span> Use a license key to manage client data. <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                                        </li>

                                        <li>
                                            <a data-bs-toggle="collapse" data-bs-target="#accordion-list-3" className="collapsed"><span>03</span>Tracking device by mobile details.  <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                                        </li>
                                        <li>
                                            <a data-bs-toggle="collapse" data-bs-target="#accordion-list-4" className="collapsed"><span>04</span>Can also lock the camera, call and app install if required. <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                                        </li>
                                        <li>
                                            <a data-bs-toggle="collapse" data-bs-target="#accordion-list-5" className="collapsed"><span>05</span>Retailer application contains mobile specific detail <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                                        </li>
                                    </ul>
                                </div>

                            </div>

                            <div className="col-lg-5 align-items-stretch order-1 order-lg-2 img" style={{ backgroundImage: `url(${productImg})` }} data-aos="zoom-in" data-aos-delay="150">&nbsp;</div>
                        </div>

                    </div>
                </section>

                <Contact />
            </main> */}
            </>

            <div className={"modal fade" + (onloadModal ? " show d-block" : " d-none")} tabIndex="-1" role="dialog">
                <div className="c-modal" id='c-modal-front' style={{ zIndex: '1999' }}>
                    <div className="c-modal-box" role="document" style={{ position: 'relative' }}>
                        <i className='fa fa-times' onClick={() => setOnloadModal(false)} style={{ position: 'absolute', right: '10px', cursor: 'pointer', fontSize: '22px' }}></i>
                        <div className="c-modal-body text-center py-2">
                            <div className="row">
                                <div className="col-12">
                                    <img src={productImg} className='img-fluid' style={{ height: '250px ' }} />
                                </div>
                                <div className="col-12">
                                    <h4 className="onloadModalText mt-0 text-capitalize"><b>start your bussiness with <br /> moonfox</b> </h4>
                                </div>
                            </div>
                            <div className="btn-box mt-3">
                                <Link to='https://play.google.com/store/apps/details?id=com.arustu.moonfoxretailer' className='btn btn-sm btn-primary w-100'>Go To The Play Store & Download</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default MoonFoxHome