import React from 'react'
import Footer from './components/front/Common/Footer'
import Header from './components/front/Common/Header'
import './../src/components/front/Style/bootstrap.min.css'
import './../src/components/front/Style/media.css'
const Front = ({ cmp }) => {
    const Cmp = cmp
    return (
        <>
            <Header />
            <Cmp />
            <Footer />
        </>
    )
}

export default Front