import React from 'react'
import { Link } from 'react-router-dom'

const OurApp = () => {
    return (
        <section class="sec-policy">
            <div class="container pt-5">
                <div class="row mt-3">
                    <div className="col-12">
                        <h3 style={{ color: 'black' }}><b>OUR APP (MoonFox)</b></h3>
                    </div>
                    <div class="col-lg-12 mt-3">
                        < p >
                            Our finance locker with multiple features provide best solutions to retailers, so no more worrying for unpaid emi’s. MoonFox contains mobile finance lockers which help retailers to handle their customers effectively and effortlessly who don’t pay emi on time. Now retailer’s don’t have to stress for emi recovery as we offer security with a complete solution to you with our trusted finance locker. Now no one will benefit inequitably from the finances. It doesn't matter if the customer changes sim cards or tries to trick you, if the emi is not paid on time then you might be able to lock the customer's device.
                        </p >
                        <div className='mt-3'>
                            <Link to={'https://play.google.com/store/apps/details?id=com.arustu.moonfoxretailer'}>Download</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurApp