import axios from 'axios';
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { AuthRole, getAllCookiesData, url } from '../../Utility/Utility';
import logo from "../Img/logo.png"
const Profile = ({ path }) => {

    const [profileDetails, setProfileDetails] = useState({})

    useEffect(() => {
        getProfileDetails()
    }, [])
    const getProfileDetails = async () => {
        try {
            var formdata = new FormData();
            formdata.append("id", getAllCookiesData()?.id);
            const res = await axios.post(url + "/user/get-single-user.php", formdata);
            if (res.data.status) {
                setProfileDetails(res.data.data)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const style = {
        col: {
            background: "#ddd",
            padding: "8px 10px",
            margin: "7px 0px",
            display: 'flex',
            justifyContent: "space-between",
            borderRadius: '6px',
            // borderBottom: 
        },
        span: {
            fontWeight: 'bold',
            textTransform: "uppercase",
            fontSize: '13px',
            marginTop: '3px',
        },
        label: {
            fontSize: '13px',
            marginTop: "6px"
        },
        img: {
            borderRadius: '100%'
        }

    }
    return (
        <>
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">{path.substring(1)}</h4>
                    <div className="row mt-3 pt-3" style={{ borderTop: '1px solid #ccc' }}>
                        <div className="col-md-3 col-12">
                            <div className="row pt-2 justify-content-center">
                                <div className="col-6 col-sm-6 col-lg-5">
                                    <div className="img-box" style={{ background: "#fff", padding: "8px", boxShadow: "0px 1px 4px #444", borderRadius: "100%" }}>
                                        <img src={logo} style={style.img} className='img-fluid' />
                                    </div>

                                </div>
                                <div className="col-12 text-center my-3">
                                    <p className='mx-5' style={{ background: "#fff", padding: "8px", boxShadow: "0px 0px 2px red", borderRadius: "5px", fontSize: '17px', textTransform: "uppercase",color: "#fff", textShadow: "0px 1px 3px red", letterSpacing: "2px", fontWeight: 'bold' }}>{AuthRole()}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-9 col-12">
                            <div className="row">
                                <div className="col-12 col-md-6" style={{}}>
                                    <div style={style.col} className={`${profileDetails.role ? "green" : "red"}`}>
                                        <span style={style.span}>Position - </span> <span style={{ textTransform: "uppercase" }}>{AuthRole()}</span>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6" >
                                    <div style={style.col} className={`${profileDetails.name ? "green" : "red"}`}>
                                        <span style={style.span}>Name - </span> <span>{profileDetails.name ? profileDetails.name : "Not update"}</span>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div style={style.col} className={`${profileDetails.email ? "green" : "red"}`}>
                                        <span style={style.span} >email - </span> <span>{profileDetails.email ? profileDetails.email : "Not update"}</span>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div style={style.col} className={`${profileDetails.username ? "green" : "red"}`}>
                                        <span style={style.span} >username - </span> <span>{profileDetails.username ? profileDetails.username : "Not update"}</span>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div style={style.col} className={`${profileDetails.mobile ? "green" : "red"}`}>
                                        <span style={style.span} >phone no - </span> <span>{profileDetails.mobile ? profileDetails.mobile : "Not update"}</span>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div style={style.col} className={`${profileDetails.gst_number ? "green" : "red"}`}>
                                        <span style={style.span} >gst no - </span> <span>{profileDetails.gst_number ? profileDetails.gst_number : "Not update"}</span>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div style={style.col} className={`${profileDetails.company_name ? "green" : "red"}`}>
                                        <span style={style.span}>company name - </span> <span>{profileDetails.company_name ? profileDetails.company_name : "Not update"}</span>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div style={style.col} className={`${profileDetails.state ? "green" : "red"}`}>
                                        <span style={style.span}>State - </span> <span>{profileDetails.state ? profileDetails.state : "Not update"}</span>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div style={style.col} className={`${profileDetails.city ? "green" : "red"}`}>
                                        <span style={style.span}>city - </span> <span>{profileDetails.city ? profileDetails.city : "Not update"}</span>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div style={style.col} className={`${profileDetails.assigned_by ? "green" : "red"}`}>
                                        <span style={style.span}>Assignee - </span> <span>{profileDetails.assigned_by ? profileDetails.assigned_by : "Not update"}</span>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div style={style.col} className={`${profileDetails.password ? "green" : "red"}`}>
                                        <span style={style.span}>password - </span> <span>******** </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>

        </>
    )
}

export default Profile