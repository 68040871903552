import React from 'react'
import List from '../Common/Main/List'

const Admin = ({ path }) => {
    return (
        <>
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">{path.substring(1)}</h4>
                    <div class="row">
                        <List role = "1" {...{path}}/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Admin