import axios from 'axios';
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { AuthRole, currentDateMonthYear, currentMonthYear, getUserData, DateMonthYear, url, currentYearMonthDate, calenderWise, getAllCookiesData } from '../../Utility/Utility';
import { handleDownloadExcel } from '../Utility/Export';
const DeviceList = ({ path }) => {
    const authID = getAllCookiesData()?.id
    const authRole = getAllCookiesData()?.role
    const [deviceList, setDeviceList] = useState([])
    const [distributorList, setDistributorList] = useState([])
    const [retailerList, setRetailerList] = useState([])
    const [singaleDevice, setSingalDevice] = useState({})
    const [modal, setModal] = useState(false)
    const [dateRange, setDateRange] = useState('')
    const [filterData, setFilterData] = useState([])
    const [resultCount, setResultCount] = useState(1)

    const [searchVal, setSearchVal] = useState({
        'status': '',
        'distributor_id': '',
        'retailer_id': '',
        'date_from': '',
        'date_to': '',
    })

    useEffect(() => {
        getDeviceList()
        getDistributor()
        getDevice()
        if (authRole === "4") {
            getRetailerList(authID)
        }
    }, [])

    const getDeviceList = async () => {
        const data = { "type": "get-all-device-info" }
        try {
            const res = await axios.post(url + "/v1/device.php", data);
            if (res.data.status) {
                if (AuthRole() === "distributor") {
                    const result = res.data.data.filter((item) => {
                        return item.distributor_id.toLowerCase() === authID.toLowerCase();
                    });
                    setDeviceList(result);
                    setFilterData(result)
                } else {
                    setDeviceList(res.data.data);
                    setFilterData(res.data.data);
                }
            } else setDeviceList()
        } catch (error) { console.log(error) }
    }

    const getDevice = async () => {
        const data = {
            "type": "get-device",
            "id": authID,
            "role": authRole
        }
        try {
            const res = await axios.post(url + "/v1/device.php", data);
            if (res.data.status) {
                // console.log("response", res.data.data)
            }
        } catch (error) { console.log(error) }
    }

    const getDistributor = () => {
        getUserData('4')
            .then((data) => {
                if (data?.status) {
                    setDistributorList(data?.data)
                }
            });
    }

    const getRetailerList = async (id) => {
        try {
            var formdata = new FormData();
            formdata.append("id", id);

            const data = formdata;
            const result = await axios.post(url + "/user/get-users-assign.php", data)
            if (result?.data?.status) {
                setRetailerList(result?.data?.data)
            } else {
                setRetailerList([])
            }
        } catch (error) {
            console.log(error)
        }
    }

    const columns = [
        {
            name: 'S.No',
            selector: (row, index) => index + 1,
            sortable: true
        },
        {
            name: 'Name',
            selector: (row) => row.FullName
        },
        {
            name: 'Retailer',
            selector: (row) => <>{row.company_name + ` ( ` + row.name + `)`}</>
        },
        {
            name: 'IMEI NO ',
            selector: (row) => row.IMEINO,
            sortable: true
        },
        {
            name: 'Email ',
            selector: (row) => row.Email,
            sortable: true
        },
        {
            name: 'Mobile No ',
            selector: (row) => row.MobileNo,
            sortable: true
        },
        {
            name: 'Serial No ',
            selector: (row) => row.SerialNo,
            sortable: true
        },
        {
            name: 'Device Status ',
            selector: (row) => <><span className={`badge text-capitalize ${row.DeviceStatus === "Inactive" ? "badge-warning" : row.DeviceStatus === "lock" ? "badge-danger" : row.DeviceStatus === "unlock" ? "badge-info" : row.DeviceStatus === "uninstall" ? "badge-success" : ""}`}> {row.DeviceStatus}</span> </>,
            sortable: true
        },
        {
            name: 'Date ',
            selector: (row) => row.Date,
            sortable: true
        },
        {
            name: <p className='text-end'>Action</p>,
            cell: row => <>
                <Link to='#' onClick={() => { setModal(true); setSingalDevice(row) }} className="btn btn-danger text-white ml-2 p-2"><i class="ti-eye"></i></Link>
            </>
        }
    ]
    const changeArrFilterData = (filterData) => {
        const result = filterData?.map((sponsor) =>
            ({ Name: sponsor.FullName, Retailer: sponsor.name, "IMEI NO1": sponsor.IMEINO1, "IMEI NO2": sponsor.IMEINO2, Email: sponsor.Email, "Mobile No": sponsor.MobileNo, "Serial No": sponsor.SerialNo, "Device Status": sponsor.DeviceStatus, Date: sponsor.Date })
        )
        return result
    }

    const downloadExcel = () => {
        handleDownloadExcel(changeArrFilterData(filterData), "DeviceList", "Device")
    };

    const handleSearch = () => {
        setResultCount(resultCount + 1)
        if (searchVal.distributor_id !== '' && searchVal.retailer_id !== '' && searchVal.status !== '' && dateRange !== '') {
            console.log("call", dateRange, searchVal.status)
            var prev_date = new Date();
            prev_date.setDate(prev_date.getDate() - 1);
            const result = deviceList.filter((item) => {
                return item.distributor_id.toLowerCase() === searchVal.distributor_id.toLowerCase() && item.RetailerID.toLowerCase() === searchVal.retailer_id.toLowerCase() && item.DeviceStatus.toLowerCase() === searchVal.status.toLowerCase() && dateRange === 'today' ? item.Date.toLowerCase().includes(currentDateMonthYear().toLowerCase()) : dateRange === 'yesterday' ? item.Date.toLowerCase().includes(DateMonthYear(prev_date).toLowerCase()) : "";
            });
            setFilterData(result)
        }
        else if (searchVal.distributor_id !== '' && searchVal.retailer_id !== '' && dateRange === 'today') {
            const result = deviceList.filter((item) => {
                return item.distributor_id.toLowerCase() === searchVal.distributor_id.toLowerCase() && item.RetailerID.toLowerCase() === searchVal.retailer_id.toLowerCase() && item.Date.toLowerCase().includes(currentDateMonthYear().toLowerCase());
            });
            setFilterData(result)
        }
        else if (searchVal.distributor_id !== '' && searchVal.retailer_id !== '' && searchVal.status !== '') {
            const result = deviceList.filter((item) => {
                return item.distributor_id.toLowerCase() === searchVal.distributor_id.toLowerCase() && item.RetailerID.toLowerCase() === searchVal.retailer_id.toLowerCase() && item.DeviceStatus.toLowerCase() === searchVal.status.toLowerCase();
            });
            setFilterData(result)
        }
        else if (searchVal.distributor_id !== '' && searchVal.retailer_id !== '' && dateRange === 'yesterday') {
            var prev_date = new Date();
            prev_date.setDate(prev_date.getDate() - 1);
            const result = deviceList.filter((item) => {
                return item.distributor_id.toLowerCase() === searchVal.distributor_id.toLowerCase() && item.RetailerID.toLowerCase() === searchVal.retailer_id.toLowerCase() && item.Date.toLowerCase().includes(DateMonthYear(prev_date).toLowerCase());
            });
            setFilterData(result)
        }
        else if (searchVal.distributor_id !== '' && searchVal.retailer_id !== '') {
            const result = deviceList.filter((item) => {
                return item.distributor_id.toLowerCase() === searchVal.distributor_id.toLowerCase() && item.RetailerID.toLowerCase() === searchVal.retailer_id.toLowerCase();
            });
            setFilterData(result)
        }
        else if (searchVal.distributor_id !== '' && searchVal.status !== '') {
            const result = deviceList.filter((item) => {
                return item.distributor_id.toLowerCase() === searchVal.distributor_id.toLowerCase() && item.DeviceStatus.toLowerCase() === searchVal.status.toLowerCase();
            });
            setFilterData(result)
        }
        else if (searchVal.distributor_id !== '') {
            const result = deviceList.filter((item) => {
                return item.distributor_id.toLowerCase() === searchVal.distributor_id.toLowerCase();
            });
            setFilterData(result)
        }
        else if (dateRange === 'today') {
            const result = deviceList.filter((item) => {
                return item.Date.toLowerCase().includes(currentDateMonthYear().toLowerCase())
            });
            setFilterData(result)
        }
        else if (dateRange === 'yesterday') {
            var prev_date = new Date();
            prev_date.setDate(prev_date.getDate() - 1);
            const result = deviceList.filter((item) => {
                return item.Date.toLowerCase().includes(DateMonthYear(prev_date).toLowerCase())
            });
            setFilterData(result)
        }
        else if (dateRange === 'currentMonth') {
            const result = deviceList.filter((item) => {
                return item.Date.toLowerCase().includes(currentMonthYear().toLowerCase())
            });
            setFilterData(result)
        }
        else if (searchVal.status !== '') {
            const result = deviceList.filter((item) => {
                return item.DeviceStatus.toLowerCase() === searchVal.status.toLowerCase();
            });
            setFilterData(result)
        }
        else if (searchVal.date_from !== '' && searchVal.date_to !== '') {
            var formDate = moment(searchVal.date_from).format("DD-MM-YY").split('-');
            const formDateRe = [formDate[0],
            return_mm(formDate[1]),
            formDate[2]].join('-')
            var toDate = moment(searchVal.date_to).format("DD-MM-YY").split('-');
            const toDateRe = [toDate[0],
            return_mm(toDate[1]),
            toDate[2]].join('-')
            const result = deviceList.filter((d, index) => {
                var initial = d.Date.split('-');
                const re = [initial[0],
                return_mm(initial[1]),
                initial[2]].join('-')
                return new Date(re) >= new Date(formDateRe) && new Date(re) <= new Date(toDateRe).setDate(new Date(toDateRe).getDate() + 1)
            });
            setFilterData(result)
        }
        else if (searchVal.date_from !== '') {
            const result = deviceList.filter((item) => {
                return item.Date.toLowerCase().includes(DateMonthYear(searchVal.date_from).toLowerCase())
            });
            setFilterData(result)
        }
        else if (authRole === "4" && searchVal.retailer_id !== '') {
            const result = deviceList.filter((item) => {
                return item.RetailerID.toLowerCase() === searchVal.retailer_id.toLowerCase();
            });
            setFilterData(result)
        }
        else if (searchVal.reset !== '') {
            setFilterData(deviceList)
        }
        else {
            setFilterData(deviceList)
        }



    }

    const handleReset = e => {
        setSearchVal({
            ...searchVal,
            'status': '',
            'distributor_id': '',
            'retailer_id': '',
            'date_from': '',
            'date_to': '',
        })
        setDateRange('')
        setFilterData(deviceList)
        setResultCount(1)
    }

    const return_mm = (val) => (
        val === '01' ? 'Jan'
            : val === '02' ? 'Feb'
                : val === '03' ? 'Mar'
                    : val === '04' ? 'Apr'
                        : val === '02' ? 'Feb'
                            : val === '03' ? 'Mar'
                                : val === '04' ? 'Apr'
                                    : val === '05' ? 'May'
                                        : val === '06' ? 'Jun'
                                            : val === '07' ? 'Jul'
                                                : val === '08' ? 'Aug'
                                                    : val === '09' ? 'Sep'
                                                        : val === '10' ? 'Oct'
                                                            : val === '11' ? 'Nav'
                                                                : val === '12' ? 'Dec' : ''
    )

    const copyText = (text) => {
        navigator.clipboard.writeText(text)
    }
    return (
        <>
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div className="col-12 d-flex" style={{ justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #ddd' }}>
                            <h4 class="card-title">{path.substring(1)}</h4>
                        </div>
                        <div className="col-12 mt-3">
                            <div className="row">
                                {
                                    authRole === "1" || authRole === "7" ?
                                        <>
                                            <div className="col-12 col-sm-6 col-md-3 mt-2">
                                                <select value={searchVal.distributor_id} onChange={(e) => { getRetailerList(e.target.value); setSearchVal({ ...searchVal, "distributor_id": e.target.value }) }} className='form-control'>
                                                    <option value="">Select Distributor</option>
                                                    {
                                                        distributorList?.map((val, i) => {
                                                            return <>
                                                                <option value={val.id}>{val.name} || {val.company_name}</option>
                                                            </>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <div className="col-12 col-sm-6 col-md-3 mt-2">
                                                <select value={searchVal.retailer_id} className='form-control' onChange={(e) => { setSearchVal({ ...searchVal, "retailer_id": e.target.value }) }}>
                                                    <option value="">Select Retailer</option>
                                                    {
                                                        retailerList?.map((val, i) => {
                                                            return <>
                                                                <option value={val.id}>{val.name} || {val.company_name}</option>
                                                            </>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </>
                                        : <></>
                                }

                                {
                                    authRole === "4" ?
                                        <div className="col-12 col-sm-6 col-md-3 mt-2">
                                            <select value={searchVal.retailer_id} className='form-control' onChange={(e) => { setSearchVal({ ...searchVal, "retailer_id": e.target.value }) }}>
                                                <option value="">Select Retailer</option>
                                                {
                                                    retailerList?.map((val, i) => {
                                                        return <>
                                                            <option value={val.id}>{val.name} || {val.company_name}</option>
                                                        </>
                                                    })
                                                }
                                            </select>
                                        </div>
                                        :
                                        <></>
                                }

                                <div className="col-12 col-sm-6 col-md-3 mt-2">
                                    <select value={searchVal.status} onChange={(e) => { setSearchVal({ ...searchVal, "status": e.target.value }) }} className='form-control'>
                                        <option value="">Select Status</option>
                                        <option value="uninstall">UnInstall</option>
                                        <option value="inactive">InActive</option>
                                        <option value="lock">Lock</option>
                                        <option value="unlock">Unlock</option>
                                    </select>
                                </div>
                                <div className="col-12 col-sm-6 col-md-3 mt-2">
                                    <select value={dateRange} onChange={(e) => setDateRange(e.target.value)} className='form-control'>
                                        <option value="">Select Date</option>
                                        <option value="today">Today</option>
                                        <option value="yesterday">Yesterday</option>
                                        <option value="currentMonth">Current Month</option>
                                        <option value="dateRange">Custome Date Range</option>
                                    </select>
                                </div>

                                {
                                    dateRange === 'dateRange' ?
                                        <>
                                            <div className="col-12 col-sm-6 col-md-3 mt-2">
                                                <span style={{ fontSize: '13px' }} >From Date Or Date Wise</span>
                                                <input
                                                    min={"2023-03-04"}
                                                    max={currentYearMonthDate()}
                                                    type="date"
                                                    className='form-control form-control-sm'
                                                    onChange={(e) => setSearchVal({ ...searchVal, "date_from": e.target.value })} />
                                            </div>

                                            <div className="col-12 col-sm-6 col-md-3 mt-2">
                                                <span style={{ fontSize: '13px' }} > To Date</span>
                                                <input
                                                    type="date"
                                                    min={searchVal.date_from}
                                                    max={calenderWise()}
                                                    className='form-control form-control-sm'
                                                    onChange={(e) => setSearchVal({ ...searchVal, "date_to": e.target.value })}
                                                />
                                            </div>
                                        </>
                                        :
                                        <></>
                                }
                            </div>
                            <div className="row py-3 px-0 mt-3 mx-0 justify-content-center" style={{ background: '#F5F7FF' }}>
                                <div className="col-lg-2 col-md-2 col-sm-3 col-4">
                                    <input type="button" value={'Search'} onClick={() => handleSearch()} className='form-control form-control-sm bg-primary text-white' />
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-3 col-4">
                                    <input type="button" value='Reset' onClick={() => handleReset()} className='form-control form-control-sm bg-warning text-white' />
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-3 col-4">
                                    <input type="button" value={'Export to Excel'} onClick={downloadExcel} className='form-control form-control-sm bg-primary text-white' />
                                </div>
                                {
                                    resultCount !== 1 ?
                                        <div className="col-12 text-center pt-2">
                                            <strong>About {filterData.length} results found !! </strong>
                                        </div>
                                        :
                                        <></>
                                }
                            </div>
                        </div>
                        <div className="col-12 mt-3">
                            <DataTable
                                dense
                                columns={columns}
                                data={filterData}
                                pagination
                                highlightOnHover
                            />
                        </div>
                    </div>
                </div>
            </div>

            {
                modal ?
                    <div className="c-modal">
                        <div className="c-modal-box" role="document">
                            <div className="c-modal-body py-4">
                                <div className="text-center">
                                    {/* <i class="fa fa-mobile tcr" aria-hidden="true" style={{ fontSize: "40px" }}></i> */}
                                    <h4>Device Details</h4>
                                </div>
                                <div className="box" style={{ textTransform: "uppercase" }}>
                                    <h5 className="modal-title mt-2">Name : <span className='font-weight-bold'> {singaleDevice.FullName}</span></h5>
                                    <h5 className="modal-title mt-2">IMEI NO 1 : <span className='font-weight-bold imei_no_copy'> {singaleDevice.IMEINO}</span><i className='fa fa-copy  copy-icon ml-3' onClick={()=>copyText(singaleDevice.IMEINO)}></i></h5>
                                    {/* <h5 className="modal-title mt-2">IMEI NO 1 : <span className='font-weight-bold'> {singaleDevice.IMEINO1}</span></h5> */}
                                    <h5 className="modal-title mt-2">IMEI NO 2 : <span className='font-weight-bold'> {singaleDevice.IMEINO2}</span><i className='fa fa-copy  copy-icon ml-3' onClick={()=>copyText(singaleDevice.IMEINO2)}></i></h5>
                                    <h5 className="modal-title mt-2">Email : <span className='font-weight-bold'> {singaleDevice.Email}</span></h5>
                                    <h5 className="modal-title mt-2">Mobile No : <span className='font-weight-bold'> {singaleDevice.MobileNo}</span></h5>
                                    <h5 className="modal-title mt-2">serial NO : <span className='font-weight-bold'> {singaleDevice.SerialNo}</span><i className='fa fa-copy  copy-icon ml-3' onClick={()=>copyText(singaleDevice.SerialNo.toLowerCase())}></i></h5>
                                    <h5 className="modal-title mt-2" style={{ textTransform: "uppercase" }}>gsf id : <span className='font-weight-bold'> {singaleDevice.gsf_id}</span> <i className='fa fa-copy  copy-icon ml-3' onClick={()=>copyText(singaleDevice.gsf_id.toLowerCase())}></i> </h5>
                                    <h5 className="modal-title mt-2">device status : <span className='font-weight-bold'> {singaleDevice.DeviceStatus}</span></h5>
                                    {/* <h5 className="modal-title mt-2">retailer : <span className='font-weight-bold'> {singaleDevice.RetailerID}</span></h5> */}
                                    <h5 className="modal-title mt-2">Date : <span className='font-weight-bold'> {singaleDevice.Date}</span></h5>
                                    <h5 className="modal-title mt-2">Retailer's Username : <span className='font-weight-bold'> {singaleDevice.username}</span></h5>
                                    <h5 className="modal-title mt-2">Retailer's Email : <span className='font-weight-bold'> {singaleDevice.email}</span></h5>
                                </div>
                                <div className="btn-box text-center mt-3">
                                    <button type="button" className="btn btn-sm btn-secondary ml-2 " onClick={() => setModal(false)}> Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <></>
            }
        </>
    )
}

export default DeviceList